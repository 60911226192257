import { createContext } from "react";
import type { KeyValue } from "~/components/Common/types";
import { defaultKeyValueList } from "~/Constants";

export const globalEnvironmentName = "Global";

export const GlobalEnvironment: Environment = {
    id: 1111, // Todo: (sivad): update w/ proper id
    isActive: true,
    name: globalEnvironmentName,
    variableList: defaultKeyValueList,
    isSystemDefined: true,
    varialeType: "global",
};

export type EnvironmentContextType = {
    environmentName: string; // selected by user from dropdown in RequestPanel
    selectedEnvironmentIndex: number;
    environmentList: Environment[];
    setEnvironmentName: (value: React.SetStateAction<string>) => void;
    setSelectedEnvironmentIndex: (value: React.SetStateAction<number>) => void;
    setEnvironmentList: (value: Environment[]) => void;
};

export const EnvironmentContext = createContext<EnvironmentContextType>({
    environmentName: "",
    selectedEnvironmentIndex: -1,
    environmentList: [],
    setEnvironmentName: null!,
    setSelectedEnvironmentIndex: null!,
    setEnvironmentList: null!,
});

export type Environment = {
    id?: number;
    name: string;
    variableList: KeyValue[];
    isSystemDefined?: boolean; //?
    varialeType: string; //global vs environment (actually variable type)
    isActive?: boolean;
} & Audit;

// export type Variables = {
//     id: number;
//     name: string;
//     initialValue: string;
//     variableType: string;
//     scopeId: number;
//     scopeType: string;
// }

// Todo: (sivad) mark everything as optional for now
export type Audit = {
    createdBy?: string;
    createdOn?: number;
    updatedBy?: string;
    updatedOn?: number;
};

export type Workspace = {
    id: number;
    name: string;
    environments: Environment[]; //global will be always a env
};
