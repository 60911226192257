import { treeItem } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { CollectionPage } from "~/components/CollectionPage";
import { CollectionsPanel } from "~/components/CollectionsPanel";
import { RequestPanel } from "~/components/RequestPanel";
import { RequestPanelHeader } from "~/components/RequestPanelHeader";
import { defaultRequest } from "~/Constants";
import type { Collection } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
import type { Request } from "~/models/IRequest";
import { RequestContext } from "~/models/IRequest";
import { getIndexFromRepo } from "~/services/Repository/DataRepository";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";
import { createItemId, getCorrespondingDataItem, getCurrentState } from "~/utils/DataUtils/DataUtils";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";
import { SeverityLevel } from "@empowerment/telemetry";
type QueryStringParams = {
    id: string | null;
};
export function CollectionsContent(): ReactElement {
    const [collectionList, setCollectionList] = useState<Collection[]>([]);
    const [selectedRequestIndex, setSelectedRequestIndex] = useState<string>("");
    const [selectedCollectionIndex, setSelectedCollectionIndex] = useState<string>("");
    const [request, setRequest] = useState<Request>(defaultRequest);
    const [initialRequest, setInitialRequest] = useState<Request>(defaultRequest);
    const [requestName, setRequestName] = useState<string>("");
    const [collectionName, setCollectionName] = useState<string>("");
    const { authUserInitializedInTelemetry } = useAuthenticationContext();
    let queryStringCollectionIndex = useRef("");
    let queryStringRequestIndex = useRef("");
    let isQueryStringLoaded = useRef("false");
    let isuseEffectLoaded = useRef("false");
    const page = "collectionsContent";
    useEffect(() => {
        //if querystring passed get it selected
        const params = new URLSearchParams(window.location.search);
        const par: QueryStringParams = {
            id: params.get("id"),
        };
        TelemetryServiceTrackTraceInformation(`${page}.useEffect`, SeverityLevel.Information, {
            isuseEffectLoaded: isuseEffectLoaded.current,
            qsid: par.id,
        });
        // only fetch collection list if authenticatedUserContext is set in Telemetry since fetchCollectionList() logs telemetry
        // this check avoids empty user_AuthenticatedId attribute in appInsight
        if (authUserInitializedInTelemetry && isuseEffectLoaded.current === "false") {
            isuseEffectLoaded.current = "true";
            TelemetryServiceTrackTraceInformation(`${page}.useEffect.callgetIndexFromRepo`);
            getIndexFromRepo()
                .then((colList: Collection[] | undefined) => {
                    if (colList !== undefined) {
                        setCollectionList(colList);
                        if (par.id !== null && isQueryStringLoaded.current === "false") {
                            //alert("isQueryStringLoaded is false");
                            const parts = par.id.split("_");
                            if (
                                parts.length === 4 &&
                                parts[0].toLowerCase() === "file" &&
                                parts[2].toLowerCase() === "tp"
                            ) {
                                queryStringCollectionIndex.current = createItemId("collection", parts[3], ""); //`collection_${parts[3]}_TP_`;
                                queryStringRequestIndex.current = createItemId("file", parts[1], parts[3]); //`file_${parts[1]}_TP_${parts[3]}`;

                                setSelectedCollectionIndex(queryStringCollectionIndex.current);
                                setSelectedRequestIndex(queryStringRequestIndex.current);
                                isQueryStringLoaded.current = "true";
                                //alert(`In colContent selcolindex = ${selectedCollectionIndex}   QS selcolindex = ${queryStringCollectionIndex},  selectedRequestIndex = ${selectedRequestIndex} QS selectedRequestIndex = ${queryStringRequestIndex}`);
                            }
                        }
                        //TODO: unhappy
                        //TODO : not updating the response service collection here intentionally to not to overwrite teh local.
                    }
                })
                .catch((error) => {
                    console.error(error);
                    return undefined;
                });
        }
    }, [authUserInitializedInTelemetry]);

    const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex);
    const dataItem = getCorrespondingDataItem(selectedCollectionIndex, selectedRequestIndex, collectionList);

    let savedBy = "";
    let savedOn = "";
    try {
        savedBy = request.systemdata.lastSavedBy === undefined ? "" : request.systemdata.lastSavedBy;
        savedOn = request.systemdata.lastSavedOn.toLocaleLowerCase === undefined ? "" : request.systemdata.lastSavedOn;
    } catch (e) {
        console.error(e);
    }
    return (
        <CollectionContext.Provider
            value={{
                selectedCollectionIndex,
                setSelectedCollectionIndex,
                collectionList,
                setCollectionList,
                selectedRequestIndex,
                setSelectedRequestIndex,
                collectionName,
                setCollectionName,
            }}
        >
            <RequestContext.Provider
                value={{
                    request: request,
                    requestName: requestName,
                    updateRequest: setRequest,
                    updateRequestName: setRequestName,
                    initialRequest: initialRequest,
                    updateInitialRequest: setInitialRequest,
                }}
            >
                {/*TODO : how to not to pass params as null in re-render??*/}
                <CollectionsPanel
                    queryStringCollectionIndex={`${queryStringCollectionIndex.current}`}
                    queryStringRequestIndex={`${queryStringRequestIndex.current}`}
                />

                {!currentState.isAnythingSelected ||
                currentState.currentHighestSelectionType === "collection" ||
                currentState.currentHighestSelectionType === "folder" ? (
                    <CollectionPage />
                ) : (
                    <div className={styles.content}>
                        {/* MyDropdownComponent <a href="{window.location}" className={styles.link}>f</a> queryStringCollectionIndex={`${queryStringCollectionIndex}`} queryStringRequestIndex={`${queryStringRequestIndex}`}*/}
                        <div className={styles.container}>
                            <div className={styles.leftColumn}> {dataItem?.pathName}</div>
                            <div className={styles.rightColumn}>
                                By: {savedBy} On: {savedOn}
                            </div>
                        </div>
                        <RequestPanelHeader />
                        <RequestPanel key={`${selectedCollectionIndex}_${selectedRequestIndex}`} />
                    </div>
                )}
            </RequestContext.Provider>
        </CollectionContext.Provider>
    );
}
