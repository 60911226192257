import cv from "./cv";
function randomHexDigit(): string {
    return Math.floor(Math.random() * 16).toString(16);
}

export function getCV(): string {
    cv.init();
    return cv.increment()!;
}

export function getExtendedCV(): string {
    return cv.extend()!;
}

export function createGuid(): string {
    const guidPattern = "xxxxxxxx-xxxx-4xxx-Rxxx-xxxxxxxxxxxx";
    const result = guidPattern.replace(/x/g, randomHexDigit);

    // update remaining 2 bits of first digit of the clock_seq_hi_and_reserved:
    // eslint-disable-next-line no-bitwise
    return result.replace("R", (8 | Math.floor(Math.random() * 3)).toString(16));
}
