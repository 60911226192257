import type { ReactElement } from "react";
import { useContext, useCallback } from "react";
import styles from "./styles.module.scss";
import { appConfig } from "~/config/appConfig";
import { TaskMenu } from "~/enabler/TaskMenu";
import type { TaskItemType } from "~/enabler/TaskMenu/TaskMenu";
import { PageNameEnum, PageContext } from "~/models/Page";

export function LeftNavigation(): ReactElement {
    const { pageName, updatePageName } = useContext(PageContext);

    const menuItemOnClick = useCallback(
        (value: PageNameEnum) => {
            updatePageName(value);
        },
        [updatePageName]
    );

    const menuItems: TaskItemType[] = [
        {
            value: PageNameEnum.Collections,
            iconName: "projectcollection",
            selected: pageName === PageNameEnum.Collections,
            onClick: (): void => {
                menuItemOnClick(PageNameEnum.Collections);
            },
            className: pageName === PageNameEnum.Collections ? styles.selectedTaskItem : styles.taskItem,
        },
    ];

    if (appConfig().isEnvironmentsPanelEnabled === "true") {
        menuItems.push({
            value: PageNameEnum.Environments,
            iconName: "homegroup",
            selected: pageName === PageNameEnum.Environments,
            onClick: (): void => {
                menuItemOnClick(PageNameEnum.Environments);
            },
            className: pageName === PageNameEnum.Environments ? styles.selectedTaskItem : styles.taskItem,
        });
    }

    return <TaskMenu items={menuItems} className={styles.taskMenu} />;
}
