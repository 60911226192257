import type { ReactElement } from "react";
import { useState, useEffect, useContext } from "react";
import styles from "./styles.module.scss";
//eslint-disable-next-line  @typescript-eslint/no-restricted-imports
import { EnvironmentsContent } from "../EnvironmentsContent";
import { TeamTaskContent } from "../TeamTask/TeamTaskContent";
import { UnAuthorized } from "../UnAuthorized/UnAuthorized";
import { CollectionsContent } from "~/components/CollectionsContent";
import { LeftNavigation } from "~/components/LeftNavigation";
import type { Environment, Workspace } from "~/models/Environment";
import { EnvironmentContext } from "~/models/Environment";
import type { EntityRequest } from "~/models/IRequest";
import { PageContext, PageNameEnum } from "~/models/Page";
import { getUserWorkspaces } from "~/services/Repository/DataRepository";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";
import { updateEnvironmentList } from "~/utils/environmentUtils";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";
import { SeverityLevel } from "@empowerment/telemetry";

export function AppContent(): ReactElement {
    const { authUserInitializedInTelemetry, user } = useAuthenticationContext();
    const { pageName } = useContext(PageContext);
    const [environmentName, setEnvironmentName] = useState<string>("");
    const [selectedEnvironmentIndex, setSelectedEnvironmentIndex] = useState<number>(0);
    const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);
    const [environmentList, setEnvironmentList] = useState<Environment[]>([]);
    const [userAlias, setUserAlias] = useState<string | null>(null);
    const page = "appContent";

    //
    useEffect(() => {
        let emailAlias = user?.email.substring(0, user.email.indexOf("@"));
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        emailAlias = emailAlias === undefined ? "" : emailAlias;
        TelemetryServiceTrackTraceInformation(`${page}.useEffect ${emailAlias}`, SeverityLevel.Information);
        const fetchUserData = async (): Promise<void> => {
            try {
                let alias = user?.email.substring(0, user.email.indexOf("@"));
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                alias = alias === undefined ? "" : alias;
                TelemetryServiceTrackTraceInformation(
                    `${page}.useEffect.fetchUserData() ${alias}`,
                    SeverityLevel.Information
                );

                setUserAlias(alias);
                const entityRequest: EntityRequest = { id: 1, type: alias }; //TODO: (sivad) query is based on type here and id is hardcoded
                const wsList: Workspace[] | undefined = await getUserWorkspaces(entityRequest);
                if (wsList !== undefined && wsList.length > 0 && environmentList.length === 0) {
                    setEnvironmentList(wsList[0].environments);
                    setWorkspaceList(wsList);
                }
            } catch (error) {
                TelemetryServiceTrackTraceInformation(`${page}.useEffect.fetchUserData.catch()`, SeverityLevel.Error, {
                    error: error,
                    user: user,
                });
            }
        };

        if (workspaceList.length === 0 && user !== undefined) {
            fetchUserData().catch((error) => {
                TelemetryServiceTrackTraceInformation(`${page}.useEffect.fetchUserData()`, SeverityLevel.Error, {
                    error: error,
                    user: user,
                });
                return undefined;
            });
        }
    }, [authUserInitializedInTelemetry, user, workspaceList, environmentList]);

    //Updates both EnvironmentContext & Db Storage
    const updateEnvList = (envList: Environment[]): void => {
        //setEnvironmentList(envList);
        updateEnvironmentList(envList);
    };

    return (
        <div className={styles.root}>
            <EnvironmentContext.Provider
                value={{
                    environmentName,
                    selectedEnvironmentIndex,
                    environmentList,
                    setEnvironmentName,
                    setSelectedEnvironmentIndex,
                    setEnvironmentList: updateEnvList,
                }}
            >
                <LeftNavigation />
                {workspaceList.length === 0 ? (
                    <UnAuthorized userAlias={userAlias ?? ""} />
                ) : pageName === PageNameEnum.Collections ? (
                    <CollectionsContent />
                ) : pageName === PageNameEnum.Environments ? (
                    <EnvironmentsContent />
                ) : pageName === PageNameEnum.TeamTask ? (
                    <TeamTaskContent />
                ) : null}
            </EnvironmentContext.Provider>
        </div>
    );
}
