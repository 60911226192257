import { TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useContext, useMemo } from "react";
import styles from "./styles.module.scss";
import { KeyValueTable } from "~/components/Common/KeyValueTable";
import type { KeyValue } from "~/components/Common/types";
import { RequestContext } from "~/models/IRequest";
import { PageContext } from "~/models/Page";
import type { ITelemetryContext } from "~/models/TelemetryContext";

type KeyValuePanelProps = {
    panelName?: string;
};

export function KeyValuePanel(props: KeyValuePanelProps): ReactElement {
    const { request, updateRequest } = useContext(RequestContext);
    const { pageName } = useContext(PageContext);

    const { panelName } = props;

    const currentKeyValueList: KeyValue[] | undefined = useMemo(
        () =>
            panelName !== undefined
                ? panelName === "Headers"
                    ? request.headers
                    : request.params
                : Array.isArray(request.body?.values)
                ? request.body?.values
                : undefined,
        [request, panelName]
    );

    const currentPanel =
        props.panelName !== undefined ? (props.panelName === "Headers" ? "headers" : "params") : "body";
    const telemetryContext: ITelemetryContext = {
        page: pageName,
    };

    /**
     * Adds a row of key-value to the bottom
     */
    const onAdd = (): void => {
        if (panelName !== undefined) {
            updateRequest((prev) => ({
                ...prev,
                [currentPanel]: [...currentKeyValueList!, { key: "", value: "" }] as KeyValue[],
            }));
        } else {
            updateRequest((prev) => ({
                ...prev,
                body: {
                    ...prev.body!,
                    values: [...currentKeyValueList!, { key: "", value: "" }] as KeyValue[],
                },
            }));
        }
        TelemetryService.trackInfo(`New key-value row added in ${currentPanel} panel`, telemetryContext);
    };

    /**
     * Removes a row of key-value
     */
    const onRemove = (index: number): void => {
        const newKeyValueList: KeyValue[] = [...currentKeyValueList!];
        newKeyValueList.splice(index, 1);

        if (panelName !== undefined) {
            updateRequest((prev) => ({
                ...prev,
                [currentPanel]: newKeyValueList,
            }));
        } else {
            updateRequest((prev) => ({
                ...prev,
                body: {
                    ...prev.body!,
                    values: newKeyValueList,
                },
            }));
        }
        TelemetryService.trackInfo(`Key-value row removed from ${currentPanel} panel`, telemetryContext);
    };

    /**
     * Updates the 'field' with 'newText' at specified 'index'
     */
    const onTextFieldUpdate = (newText: string, index: number, field: "key" | "value"): void => {
        const newKeyValueList: KeyValue[] = [...currentKeyValueList!];
        newKeyValueList[index][field] = newText;
        if (panelName !== undefined) {
            updateRequest((prev) => ({
                ...prev,
                [currentPanel]: newKeyValueList,
            }));
        } else {
            updateRequest((prev) => ({
                ...prev,
                body: {
                    ...prev.body!,
                    values: newKeyValueList,
                },
            }));
        }
    };

    return (
        <div className={styles.content}>
            <div className={styles.request}>
                <KeyValueTable
                    panelName={props.panelName}
                    keyValueList={currentKeyValueList}
                    keyTextFieldLabel="Key"
                    valueTextFieldLabel="Value"
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onTextFieldUpdate={onTextFieldUpdate}
                />
            </div>
        </div>
    );
}
