//restore to 9:02
import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type TreeItemType from "@harmony/enablers/components/tree-item/tree-item";
import type { ReactElement } from "react";
import { useCallback, useContext, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { CollectionsPanelDialog } from "~/components/CollectionsPanel/components/CollectionsPanelDialog";
import { CollectionsPanelHeader } from "~/components/CollectionsPanel/components/CollectionsPanelHeader";
import { NavigationDialog } from "~/components/CollectionsPanel/components/NavigationDialog";
import { defaultRequestName } from "~/Constants";
import { Text } from "~/enabler/Text";
import { Tree } from "~/enabler/Tree";
import { TreeItem } from "~/enabler/TreeItem";
import type { Collection, Items, ServiceResponse } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
import type { CreateBlobRequest, FileRequest, Request } from "~/models/IRequest";
import { RequestContext } from "~/models/IRequest";
//import { Button } from "~/enabler/Button";
import { EventState, EventStatus } from "~/models/TelemetryContext";
import { getBlobFromRepo, getIndexFromRepo, upsertBlobToRepo } from "~/services/Repository/DataRepository";
import { modifyRequestInCollection } from "~/utils/collectionUtils/collectionUtils";
import {
    getFormattedCollectionKey,
    createItemId,
    recursivelyFindItemById,
    splitItemIds,
    getCurrentState,
} from "~/utils/DataUtils/DataUtils";
import { TelemetryServiceTrackEvent, TelemetryEvents } from "~/utils/telemetryUtils";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";

type CollectionActionType = "rename" | "delete" | undefined;

interface CollectionsPanelProps {
    queryStringCollectionIndex: string;
    queryStringRequestIndex: string;
}

export function CollectionsPanel({
    queryStringCollectionIndex,
    queryStringRequestIndex,
}: CollectionsPanelProps): ReactElement {
    const {
        selectedCollectionIndex,
        setSelectedCollectionIndex,
        collectionList,
        selectedRequestIndex,
        setSelectedRequestIndex,
        setCollectionName,
        setCollectionList,
    } = useContext(CollectionContext);
    const { updateRequest, request, requestName, updateInitialRequest, initialRequest, updateRequestName } =
        useContext(RequestContext);
    const [actionType, setActionType] = useState<CollectionActionType>(undefined);
    const [enableCollectionAction, setEnableCollectionAction] = useState<boolean>(selectedCollectionIndex !== "");
    const [enableFolderAction, setEnableFolderAction] = useState<boolean>(selectedCollectionIndex !== "");
    const [enableAddRequestAction, setEnableAddRequestAction] = useState<boolean>(
        selectedCollectionIndex !== "" && selectedRequestIndex === ""
    );
    const [openNavigationDialog, setOpenNavigationDialog] = useState<boolean>(false);
    const [selectionId, setSelectionId] = useState<string>("");
    const page = "collectionsPanel";

    const navigation = useCallback(
        (selectedTreeItemKey: string) => {
            TelemetryServiceTrackTraceInformation(
                `${page}.navigation ${selectedTreeItemKey}`,
                SeverityLevel.Information,
                {
                    selectedTreeItemKey: selectedTreeItemKey,
                }
            );
            const selectedTree = splitItemIds(selectedTreeItemKey); //key represents the full id and path and needs to be broken down
            const currentCollectionKey =
                selectedTree.itemType === "collection"
                    ? getFormattedCollectionKey(selectedTree.itemId)
                    : getFormattedCollectionKey(selectedTree.itemTopParentId);
            const currentCollectionId =
                selectedTree.itemType === "collection" ? selectedTree.itemId : selectedTree.itemTopParentId;

            //when search collection use id, when searching the tree use key
            const currentCollection = collectionList.find((item) => item.id === currentCollectionId);
            //alert(`in cp : currentCollectionId=${currentCollectionId}, length=${currentCollection?.items.length}`)
            if (currentCollection !== undefined) {
                setSelectedCollectionIndex(currentCollectionKey); //keep the selected collection

                if (selectedTree.itemType === "collection") {
                    //current collection and selected tree item is same
                    setCollectionName(currentCollection.name);
                    setSelectedRequestIndex(""); // so that the request content panel is refreshed out
                } else if (selectedTree.itemType === "folder") {
                    //Todo: (sivad): HOW TO RIGHTLY HANDLE THE CollectionIndex WHEN FOLDER IS SELECTED
                    //setSelectedCollectionIndex(selectedTreeItemKey);//; //keep the selected collection
                    //vs above vs empty
                    setSelectedRequestIndex(selectedTreeItemKey); // requestIndex is only to be used on file selection (new logic), folder is another form of container
                    const selectedItem = recursivelyFindItemById(selectedTree.itemId, currentCollection.items); // optimization to find it within the selected collection
                    //alert(`in cp : selectedTreeItemKey=${selectedTree.itemId}, name=${selectedItem?.name!}`)
                    setCollectionName(selectedItem?.name!); //folder name is the collection name (diff is only top parent vs child container), all add/remove operations will display this name
                } else if (selectedTree.itemType === "file") {
                    //request level
                    setSelectedRequestIndex(selectedTreeItemKey); // so that the request content panel is refreshed out
                    const selectedItem = recursivelyFindItemById(selectedTree.itemId, currentCollection.items); // optimization to find it within the selected collection
                    if (selectedItem !== undefined) {
                        if (selectedItem.request === undefined) {
                            //asyncGetBlobFromRepo(selectedTree.itemId);
                            TelemetryServiceTrackTraceInformation(
                                `${page}.navigation.callgetBlobFromRepo `,
                                SeverityLevel.Information,
                                { selectedTreeItemKey: selectedTreeItemKey, selrequest: selectedItem.request }
                            );
                            const fileName = `${selectedTree.itemId}.json`;
                            //Todo: (sivad) : handle unhappy path
                            const getBlobRequest: FileRequest = { fileName: fileName };

                            getBlobFromRepo(getBlobRequest)
                                .then((blobRequest: Request | undefined) => {
                                    if (blobRequest !== undefined) {
                                        updateRequest(blobRequest);
                                        updateInitialRequest(structuredClone(blobRequest));
                                        updateRequestName(selectedItem.name);
                                        //Todo: (sivad): unhappy
                                        //Todo: (sivad) : not updating the response service collection here intentionally to not to overwrite teh local.
                                    }
                                })
                                .catch((e) => {
                                    const error = e as Error;
                                    console.error("Error in getBlobFromRepo:", error.message);
                                });

                            /*  try {
                                const fileName : string = `${selectedTree.itemId}.json`;
                                //Todo: (sivad) : handle unhappy path
                                const getBlobRequest: FileRequest = { fileName: fileName };
                                const request = await getBlobFromRepo(getBlobRequest);
                                console.log('getBlobContent - ${request}');
                                if (request !== undefined) {updateRequest(request);}
                            } catch (e) {
                                const error = e as Error;
                                console.error('Error fetching app data:', error.message);
                            } */

                            //Todo: (sivad):
                            //updateRequest(selectedItem.request!);
                            //updateInitialRequest(structuredClone(selectedItem.value));
                            //updateRequestName(selectedItem.name);
                        }
                    }
                } else {
                    setSelectedRequestIndex("");
                }
            } else {
                alert(`Alpha Mode : in CP : currentCollection is undefined!`);
            }

            setSelectionId("");
        },
        [
            collectionList,
            setCollectionName,
            setSelectedCollectionIndex,
            setSelectedRequestIndex,
            updateRequest,
            updateInitialRequest,
            updateRequestName,
        ]
    );

    useEffect(() => {
        TelemetryServiceTrackTraceInformation(`${page}.useEffect`, SeverityLevel.Information);
        //TODO: will this cause issue in subsequent user selected nav click?
        if (queryStringCollectionIndex !== "") setSelectedCollectionIndex(queryStringCollectionIndex);
        if (queryStringRequestIndex !== "") setSelectedRequestIndex(queryStringRequestIndex);
        //alert("In colPanel : selectedCollectionIndex: "+selectedCollectionIndex + " qscolindex: " + queryStringCollectionIndex + "                     selectedRequestIndex: "+selectedRequestIndex+" qsreqindex: " + queryStringRequestIndex)
        //reset it so that its not used after the first load
        //queryStringCollectionIndex = "";
        //queryStringRequestIndex = "";
        //alert("In colPanel : selectedCollectionIndex: "+selectedCollectionIndex + " qscolindex: " + queryStringCollectionIndex + "                     selectedRequestIndex: "+selectedRequestIndex+" qsreqindex: " + queryStringRequestIndex)

        const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex);
        //if (selectedCollectionIndex === "" && selectedRequestIndex === "")  //user hasn't selected anything
        if (!currentState.isAnythingSelected) {
            //user hasn't selected anything
            setEnableCollectionAction(false);
            setEnableFolderAction(false);
            setEnableAddRequestAction(false);
        }
        //else if (selectedCollectionIndex !== "" && selectedRequestIndex === "") //only collection is selected
        else if (currentState.currentHighestSelectionType === "collection") {
            //only collection is selected
            setEnableCollectionAction(true);
            setEnableFolderAction(true);
            setEnableAddRequestAction(false);
        }
        //else if (selectedCollectionIndex !== "" && selectedRequestIndex !== "" && splitItemIds(selectedRequestIndex).itemType === "folder") //collection is selected and folder child is selected
        else if (currentState.currentHighestSelectionType === "folder") {
            //collection is selected and folder child is selected
            const selectedItemKey = splitItemIds(selectedRequestIndex);
            const selectedItem = recursivelyFindItemById(
                selectedItemKey.itemId,
                collectionList,
                selectedItemKey.itemTopParentId
            );
            if (selectedItem !== undefined && selectedItem.items !== undefined) {
                if (selectedItem.items.length > 0 && selectedItem.items.some((item) => item.type === "folder")) {
                    //then can add only more folders
                    setEnableCollectionAction(true);
                    setEnableFolderAction(true);
                    setEnableAddRequestAction(false);
                } else if (selectedItem.items.length > 0 && selectedItem.items.some((item) => item.type === "file")) {
                    //then can add only more files
                    setEnableCollectionAction(true);
                    setEnableFolderAction(false);
                    setEnableAddRequestAction(true);
                } else if (selectedItem.items.length === 0) {
                    //folder is empty and can choose folder or file additions
                    setEnableCollectionAction(true);
                    setEnableFolderAction(true);
                    setEnableAddRequestAction(true);
                }
            }
        }
        //else if (selectedRequestIndex !== "" && splitItemIds(selectedRequestIndex).itemType !== "folder") // file/request level selected
        else if (currentState.currentHighestSelectionType === "file") {
            // file/request level selected
            setEnableCollectionAction(false);
            setEnableFolderAction(false);
            setEnableAddRequestAction(false);
        } else {
            //any other unknown?
            alert(`Alpha Mode - in Col panel - 4=${selectedCollectionIndex},${selectedRequestIndex}`);
        }

        if (selectedRequestIndex !== "") {
            //alert(`before nav : selectedReqindex=${selectedRequestIndex}   qsreqindex=${queryStringRequestIndex}`);
            navigation(selectedRequestIndex);
        }
        //Todo: (sivad): why is this needed?
        //setSelectedCollectionIndex(selectedCollectionIndex);
    }, [
        selectedCollectionIndex,
        selectedRequestIndex,
        collectionList,
        setSelectedCollectionIndex,
        setEnableCollectionAction,
        setEnableFolderAction,
        setEnableAddRequestAction,
        navigation,
        queryStringCollectionIndex,
        queryStringRequestIndex,
        setSelectedRequestIndex,
    ]);

    const onSelectionChange = useCallback(
        (selection: TreeItemType[]) => {
            const selectedTreeItemKey = selection[0].id;
            const selectedTree = splitItemIds(selectedTreeItemKey);
            setSelectionId(selectedTreeItemKey);
            if (selectedTree.itemType === "collection" || selectedTree.itemType === "folder") {
                navigation(selectedTreeItemKey);
            } else if (selectedTree.itemType === "file") {
                //Todo: (sivad): Handle this
                // only open dialog when user actually made changes to request
                if (JSON.stringify(request) !== JSON.stringify(initialRequest)) {
                    TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanel.UnsavedChangesDialogOpened);
                    setOpenNavigationDialog(true);
                } else {
                    navigation(selectedTreeItemKey);
                }
            }
        },
        [initialRequest, navigation, request, setOpenNavigationDialog]
    );

    const renameCollectionOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.RenameCollectionButtonClicked);
        setActionType("rename");
    }, []);

    const deleteCollectionOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.DeleteCollectionButtonClicked);
        setActionType("delete");
    }, []);

    const closeDialog = useCallback(() => {
        if (actionType === "delete") {
            TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.DeleteCollectionDialogClosed);
            setActionType(undefined);
        } else if (actionType === "rename") {
            TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.RenameCollectionDialogClosed);
            setActionType(undefined);
        }
    }, [actionType]);

    const closeNavigationDialog = useCallback(() => {
        setOpenNavigationDialog(false);
    }, []);

    const continueWithoutSavingOnClick = useCallback(
        (ignoreTrackEvent?: boolean) => {
            if (ignoreTrackEvent !== true) {
                TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanel.ContinueWithoutSavingButtonClicked);
            }
            navigation(selectionId);
            closeNavigationDialog();
        },
        [navigation, closeNavigationDialog, selectionId]
    );

    //const saveAndContinueOnClick_old = useCallback(() => {
    //     TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanel.SaveAndContinueButtonClicked);
    //     if (selectedRequestIndex === "") {
    //         // create a new collection to save auto created request
    //         modifyRequestInCollection("", structuredClone(request), defaultRequestName, "add");
    //     } else {
    //         modifyRequestInCollection(
    //             selectedCollectionIndex,
    //             structuredClone(request),
    //             requestName,
    //             "update",
    //             selectedRequestIndex
    //         );
    //     }

    //     getIndexFromRepo()
    //         .then((colList: Collection[] | undefined) => {
    //             if (colList !== undefined) {
    //                 setCollectionList(colList);
    //                 //Todo: (sivad): unhappy
    //                 //Todo: (sivad) : not updating the response service collection here intentionally to not to overwrite teh local.
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             return undefined;
    //         });
    //     /*         (async () => {
    //         const collectionList = await getIndexFromRepo()
    //         setCollectionList(collectionList ?? []);
    //     })(); */

    //     continueWithoutSavingOnClick(true);
    // }, [
    //     continueWithoutSavingOnClick,
    //     request,
    //     requestName,
    //     selectedCollectionIndex,
    //     selectedRequestIndex,
    //     setCollectionList,
    // ]);

    //TODO: (sivad): same code as RequestPanelHeader.onDirectlySaveRequest
    const saveAndContinueOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestPanelHeader.SaveRequestButtonClicked, {
            collectionIndex: selectedCollectionIndex,
            requestIndex: selectedRequestIndex,
            eventState: EventState.Initiated,
        });
        const saveRequestStartTime = Date.now();
        let eventStatus;
        try {
            setActionType(undefined);
            const selectedItemId = splitItemIds(selectedRequestIndex).itemId;
            const createBlobRequest: CreateBlobRequest = {
                fileName: `${selectedItemId}.json`,
                content: `${JSON.stringify(structuredClone(request))}`,
            };

            upsertBlobToRepo(createBlobRequest)
                .then((serviceResponse: ServiceResponse | undefined) => {
                    if (serviceResponse !== undefined) {
                        //Todo: (sivad): guess nothing much to do other than error checks
                        console.log(
                            `in collectionspanel-saveRequest success ${serviceResponse.id}, ${serviceResponse.result}`
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (e) {
            const error = e as Error;
            //TelemetryService.trackException(error, exceptionTelemetryContext, {
            //    Duration: Date.now() - saveRequestStartTime,
            //});
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.RequestPanelHeader.SaveRequestButtonClicked,
            {
                collectionIndex: selectedCollectionIndex,
                requestIndex: selectedRequestIndex,
                eventState: EventState.Completed,
                eventStatus: eventStatus,
            },
            { Duration: Date.now() - saveRequestStartTime }
        );
        continueWithoutSavingOnClick(true);
    }, [
        continueWithoutSavingOnClick,
        request,
        //requestName,
        selectedCollectionIndex,
        selectedRequestIndex,
        //setCollectionList,
    ]);

    const cancelOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanel.CancelButtonClicked);
        closeNavigationDialog();
    }, [closeNavigationDialog]);

    /*     const asyncGetBlobFromRepo = async (fileId: string) => {
        try {
            const fileName = `${fileId}.json`;
            //Todo: (sivad) : handle unhappy path
            const getBlobRequest: FileRequest = { fileName: fileName };
            const request = await getBlobFromRepo(getBlobRequest);
            console.log('getBlobContent - ${request}');
            if (request !== undefined) {updateRequest(request);}
        } catch (e) {
            const error = e as Error;
            console.error('Error fetching app data:', error.message);
        }
    }; */

    function mapDataToTreeItem(data: Items[] | undefined, itemTopParentId: string): JSX.Element[] {
        const result: JSX.Element[] = [];

        data?.forEach((parentTreeItems) => {
            const treeItemId = createItemId(parentTreeItems.type, parentTreeItems.id, itemTopParentId);
            const treeItems = parentTreeItems.items;
            const treeItemSelected = selectedRequestIndex === treeItemId;

            const treeItemElement = (
                <TreeItem
                    id={treeItemId}
                    key={treeItemId}
                    title={parentTreeItems.id}
                    selected={treeItemSelected}
                    className={treeItemSelected ? `he-tree-item ${styles.selectedTreeItem}` : "he-tree-item"}
                >
                    <Text tag="span" appearance="paragraph" className={styles.treeItemSpan}>
                        {" "}
                        {parentTreeItems.name}
                    </Text>

                    {mapDataToTreeItem(treeItems, itemTopParentId)}
                </TreeItem>
            );
            result.push(treeItemElement);
        });

        return result;
    }

    return (
        <>
            <div className={styles.root}>
                <CollectionsPanelHeader
                    enableCollectionAction={enableCollectionAction}
                    enableFolderAction={enableFolderAction}
                    renameCollectionOnClick={renameCollectionOnClick}
                    deleteCollectionOnClick={deleteCollectionOnClick}
                    enableAddRequestAction={enableAddRequestAction}
                />

                <Tree selection="single" onSelectionChange={onSelectionChange}>
                    {collectionList.map((collection) => {
                        let collectionId = "";
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        if (collection.id !== undefined) {
                            collectionId = collection.id;
                        }
                        const collectionKey = createItemId(collection.type, collection.id, "");
                        const isCollectionSelected =
                            collectionKey === selectedCollectionIndex && selectedRequestIndex === "";
                        const isCollectionExpanded =
                            collectionKey === selectedCollectionIndex && selectedRequestIndex !== "";
                        return (
                            <TreeItem
                                id={collectionKey}
                                key={collectionKey}
                                title={collection.name}
                                selected={isCollectionSelected}
                                expanded={isCollectionExpanded}
                                className={
                                    isCollectionSelected ? `he-tree-item ${styles.selectedTreeItem}` : "he-tree-item"
                                }
                            >
                                <Text tag="span" appearance="paragraph" className={styles.treeItemSpan}>
                                    {" "}
                                    {collection.name}
                                </Text>
                                {collection.items.map((treeItems) => {
                                    const treeItemKey = createItemId(treeItems.type, treeItems.id, collectionId);
                                    const treeItemSelected =
                                        selectedCollectionIndex === collectionKey &&
                                        selectedRequestIndex === treeItemKey;
                                    return (
                                        <TreeItem
                                            id={treeItemKey}
                                            key={treeItemKey}
                                            title={treeItems.id}
                                            selected={treeItemSelected}
                                            className={
                                                treeItemSelected
                                                    ? `he-tree-item ${styles.selectedTreeItem}`
                                                    : "he-tree-item"
                                            }
                                        >
                                            <Text tag="span" appearance="paragraph" className={styles.treeItemSpan}>
                                                {" "}
                                                {treeItems.name}
                                            </Text>
                                            {mapDataToTreeItem(treeItems.items, collection.id)}
                                        </TreeItem>
                                    );
                                })}
                            </TreeItem>
                        );
                    })}
                </Tree>
            </div>

            {actionType !== undefined && (
                <CollectionsPanelDialog
                    actionType={actionType}
                    closeDialog={closeDialog}
                    setActionType={setActionType}
                />
            )}

            {openNavigationDialog && (
                <NavigationDialog
                    cancelOnClick={cancelOnClick}
                    saveAndContinueOnClick={saveAndContinueOnClick}
                    continueWithoutSavingOnClick={continueWithoutSavingOnClick}
                    closeDialog={closeNavigationDialog}
                />
            )}
        </>
    );
}
