import { treeItem } from "@harmony/enablers/react";
import type { ReactElement, ReactNode } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyTreeItem = scopeForReact(treeItem);

type TreeItemProps = {
    children: ReactNode[] | ReactNode;
    lazy?: boolean;
    selected?: boolean;
    onHeLazyLoad?: () => void;
    className?: string;
    expanded?: boolean | undefined;
    onHeExpand?: ((event: CustomEvent<unknown>) => void) | undefined;
    onHeCollapse?: ((event: CustomEvent<unknown>) => void) | undefined;
    loading?: boolean;
} & BaseEnablerAttributes;

function TreeItemComponent(props: TreeItemProps): ReactElement {
    const { children, className, ...rest } = { ...props };
    return (
        <HarmonyTreeItem className={className} {...rest}>
            {children}
        </HarmonyTreeItem>
    );
}

export const TreeItem = typedMemo(TreeItemComponent);
