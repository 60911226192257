import type { ReactElement } from "react";
import { useContext, useCallback } from "react";
import styles from "./styles.module.scss";
import { defaultRequest, defaultRequestName } from "~/Constants";
import { Button } from "~/enabler/Button";
import { Text } from "~/enabler/Text";
import { stringConstants } from "~/locale/stringConstants";
import type { Collection } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
import { RequestContext } from "~/models/IRequest";
import { getIndexFromRepo } from "~/services/Repository/DataRepository";
import { modifyRequestInCollection } from "~/utils/collectionUtils";
import { getItemIdFromKey, recursivelyFindItemById, splitItemIds } from "~/utils/DataUtils/DataUtils";
import { isDefined } from "~/utils/isDefined";

export function CollectionPage(): ReactElement {
    const {
        selectedCollectionIndex,
        collectionList,
        setCollectionName,
        setSelectedRequestIndex,
        setCollectionList,
        selectedRequestIndex,
    } = useContext(CollectionContext);
    const { updateRequest, updateRequestName, updateInitialRequest } = useContext(RequestContext);

    //const selectedCollection = collectionList[selectedCollectionIndex];
    //const selectedCollection = collectionList.find(item => item.id === selectedCollectionIndex);
    const selectedCollection = collectionList.find((item) => item.id === getItemIdFromKey(selectedCollectionIndex));
    const addARequestOnClick = useCallback(() => {
        const result = modifyRequestInCollection(
            selectedCollectionIndex,
            structuredClone(defaultRequest),
            defaultRequestName,
            "add"
        );

        // Todo: (sivad) handle addNewRequest failure
        if (isDefined(result)) {
            updateRequest(structuredClone(defaultRequest));
            updateInitialRequest(structuredClone(defaultRequest));
            updateRequestName(defaultRequestName);
            if (result !== undefined) {
                setCollectionName(result.collectionName);
            }
            setSelectedRequestIndex(result.requestIndex);

            getIndexFromRepo()
                .then((colList: Collection[] | undefined) => {
                    if (colList !== undefined) {
                        setCollectionList(colList);
                        //Todo: (sivad): unhappy
                        //Todo: (sivad) : not updating the response service collection here intentionally to not to overwrite teh local.
                    }
                })
                .catch((error) => {
                    console.error(error);
                    return undefined;
                });
            /*             (async () => {
                            const colList = await getIndexFromRepo()
                            setCollectionList(colList ?? []);
                        })(); */
        }
    }, [
        selectedCollectionIndex,
        setCollectionList,
        setCollectionName,
        setSelectedRequestIndex,
        updateInitialRequest,
        updateRequest,
        updateRequestName,
    ]);

    const requestButtonOnClick = useCallback(
        (requestIndex: string) => {
            const selCol = collectionList.find((item) => item.id === getItemIdFromKey(selectedCollectionIndex));
            const selectedTreeItem = recursivelyFindItemById(getItemIdFromKey(requestIndex), selCol?.items);
            setSelectedRequestIndex(requestIndex);
            if (selectedTreeItem !== undefined) {
                //const selectedRequest = collectionList[selectedCollectionIndex].items[requestIndex];
                //Todo: (sivad): sivad to be able to update
                if (selectedTreeItem.type === "file" && selectedTreeItem.request !== undefined) {
                    updateRequest(structuredClone(selectedTreeItem.request));
                    updateInitialRequest(structuredClone(selectedTreeItem.request));
                }
                updateRequestName(selectedTreeItem.name);
            }
        },
        [
            collectionList,
            selectedCollectionIndex,
            setSelectedRequestIndex,
            updateInitialRequest,
            updateRequest,
            updateRequestName,
        ]
    );

    let mainContent = (
        <div>
            <Text tag="span" appearance="paragraph">
                {stringConstants.components.App.CollectionPage.EmptyCollectionMessage}
            </Text>
            <Button appearance="link" onClick={addARequestOnClick}>
                {stringConstants.components.App.CollectionPage.AddARequest}
            </Button>
            <Text tag="span" appearance="paragraph">
                {stringConstants.components.App.CollectionPage.AddARequestSuffix}
            </Text>
        </div>
    );

    const selectedTree = splitItemIds(selectedRequestIndex);
    const currentCollection = collectionList.find((item) => item.id === selectedTree.itemTopParentId);
    const selectedItem = recursivelyFindItemById(selectedTree.itemId, currentCollection?.items); // optimization to find it within the selected collection

    mainContent = <></>;

    if (selectedCollection !== undefined && (selectedRequestIndex === undefined || selectedTree.itemType === "file")) {
        mainContent = (
            <div className={styles.content}>
                {selectedCollection.items.map((requestTreeItem) => (
                    <Button
                        // key={`collectionIndex_${selectedCollectionIndex}_requestIndex_${requestTreeItem.id.}`}
                        key={requestTreeItem.id}
                        appearance="link"
                        onClick={(): void => {
                            requestButtonOnClick(requestTreeItem.id);
                        }}
                    >
                        {requestTreeItem.name}
                    </Button>
                ))}
            </div>
        );
    } else if (selectedItem !== undefined && selectedItem.type === "folder") {
        mainContent = (
            <>
                <div>
                    <Text tag="span" appearance="paragraph">
                        Id : {selectedTree.itemId}
                    </Text>
                </div>
                <div>
                    <Text tag="span" appearance="paragraph">
                        Name : {selectedItem.name}
                    </Text>
                </div>
                <div>
                    <Text tag="span" appearance="paragraph">
                        Type : {selectedTree.itemType}
                    </Text>
                </div>
                <div>
                    <Text tag="span" appearance="paragraph">
                        Path : {selectedItem.pathName}
                    </Text>
                </div>
                <div>
                    <Text tag="span" appearance="paragraph">
                        Folder Scripts :
                    </Text>
                    <Button key="1" onClick={(): void => {}} disabled={true}>
                        Execute
                    </Button>
                </div>
            </>
        );
    }

    return (
        <div className={styles.root}>
            <Text tag="h4" appearance="heading-4" className={styles.heading}>
                {selectedCollection?.name}
            </Text>
            {mainContent}
        </div>
    );
}
