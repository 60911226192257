import type { PageNameTypes } from "~/models/Page";

export enum EventState {
    Initiated = "Initiated",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum EventStatus {
    Success = "Success",
    Failed = "Failed",
}

export type ITelemetryContext = {
    page?: PageNameTypes;
    environmentName?: string;
    eventState?: EventState; // include this attribute & set it to 'Completed' to include in Grafana
    eventStatus?: EventStatus; // include this attribute to include in Grafana
    [key: string]: unknown;
};

export type IServiceTreeContext = {
    Organization: string;
    ServiceGroup: string;
    TeamGroup: string;
    Service: string;
    EnvironmentName: string;
};
