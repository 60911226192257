import type { RequestType } from "@empowerment/authenticationservice";
import { AuthenticationService } from "@empowerment/authenticationservice";
//import { TelemetryService } from "@empowerment/telemetry";

import { getHTTPResponse } from "./HttpClientUtil";
// eslint-disable-next-line  @typescript-eslint/no-restricted-imports
import type { HTTPResponse } from "../../models/IResponse";
import { appConfig } from "~/config/appConfig";
import { createGuid, getCV } from "~/utils/headerUtils";
import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import { getFormatDateTimeWithMilliseconds } from "~/utils/telemetryUtils";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";

export const executeHTTPRequest = async (
    requestType: RequestType,
    rawUrl: string,
    cv: string | undefined,
    correlationId: string | undefined,
    body: string | undefined
): Promise<HTTPResponse> => {
    const page = "HTTPClient";
    if (cv === undefined) {
        cv = getCV();
    } //Todo: (sivad): remove hard coding
    if (correlationId === undefined) {
        correlationId = createGuid();
    }

    //TODO:(sivad) - remove hardcoding
    //admin audience
    const adminAudienceScope = "3cbaf10d-ea88-4173-9ea6-94226c6e11b4/user_impersonation"; // "/.default"
    let tokenScope = appConfig().apixApiScope;
    if (body !== undefined && body.toLowerCase().includes("/admin/")) {
        tokenScope = adminAudienceScope;
    }
    let token;
    try {
        token = await AuthenticationService.acquireTokenForScope(tokenScope);
    } catch (e) {
        const error = e as Error;
        console.log(`getting token error${error.message}`);
        TelemetryService.trackException(error, {
            page: "executeHTTPRequest",
            url: rawUrl,
            MSCV: cv,
            correlationId: correlationId,
        });
        return {
            statusCode: 500,
            responseBody: error.message,
        };
    }

    let headers = {
        "MS-CV": cv,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    // Pass x-ms-correlation-id only when its defined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (correlationId !== undefined) {
        headers = { ...headers, ...{ "x-ms-correlation-id": correlationId } };
    }

    //Todo: (sivad):
    const fetchTelemetryContext = {
        page: page,
        MSCV: cv,
        correlationId: correlationId,
        method: requestType,
        url: rawUrl,
        headers: JSON.stringify(headers),
        body: body,
    };
    const acquireTokenStartTime = Date.now();

    const fetchStartTime = Date.now();
    try {
        const response = await fetch(`${rawUrl}`, {
            headers: headers,
            method: `${requestType}`,
            body: body,
        });
        //Todo: (sivad):
        //if (!httpResponse.ok) {
        //throw new Error('Failed to fetch data');
        //}

        const parseResponseStartTime = Date.now();
        const duration = parseResponseStartTime - fetchStartTime; // time it takes to make fetch() call to Service
        //Todo: (sivad) capture unhandled failure
        //Todo: (sivad): Sivad
        //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        TelemetryServiceTrackTraceInformation(
            `${page}.Request ${cv} ${requestType} ${rawUrl}`,
            SeverityLevel.Information,
            fetchTelemetryContext
        );

        let parsedResponse: HTTPResponse = await getHTTPResponse(response, fetchTelemetryContext, duration);
        //let removedbody = body?.replace(/(bearer).*?("]")/, '$1 $2')
        //alert(removedbody);
        //let foo = "bearer lajsdfljsaldfj]";
        //   let newBody = foo.replace(/(bearer).*?(])/, '$1 $2');
        //   alert(newBody);

        const res = `${JSON.stringify(parsedResponse)}`;
        TelemetryServiceTrackTraceInformation(`${page}.Response ${cv}`, SeverityLevel.Information, { response: res });

        parsedResponse = {
            ...parsedResponse,
            requestStartTimes: {
                sendRequestStartTime: -1,
                acquireTokenStartTime: acquireTokenStartTime,
                fetchStartTime: fetchStartTime,
                parseResponseStartTime: parseResponseStartTime,
                renderResponseStartTime: -1,
            },
        };
        return parsedResponse;
    } catch (e) {
        const parseResponseStartTime = Date.now();
        const error = e as Error;
        TelemetryService.trackException(error, {
            page: "executeHTTPRequest",
            url: rawUrl,
            MSCV: cv,
            correlationId: correlationId,
        });
        return {
            statusCode: 500,
            errorType: "Unknown",
            responseBody: error.message,
            requestStartTimes: {
                sendRequestStartTime: -1,
                acquireTokenStartTime: acquireTokenStartTime,
                fetchStartTime: fetchStartTime,
                parseResponseStartTime: parseResponseStartTime,
                renderResponseStartTime: -1,
            },
        };
    }
};
