import { useState, useEffect } from "react";
import type { SetStateAction, ReactElement } from "react";
import styles from "./styles.module.scss";
import { useTeamTaskAppContext } from "../TeamTaskAppContext";
import type { TeamTaskAppData } from "~/models/TeamTask/TeamTaskAppData";
import {
    getTeamTaskAppDataFromRepo,
    setEditedItemToRepo,
} from "~/services/DbRepository/TeamTask/TeamTaskDBRespository";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";

export function ListPanel(): ReactElement {
    //TODO : rename this to appDataList
    const { teamTaskAppDataList, setSelectedItem, setTeamTaskAppDataList } = useTeamTaskAppContext();
    const [newItemValue, setNewItemValue] = useState("");
    const [getTagValue, setGetTagValue] = useState("");

    const [selectedOption, setSelectedOption] = useState("private");
    const { user } = useAuthenticationContext();
    let alias = user?.email;
    alias = user?.email.substring(0, user.email.indexOf("@"));

    //when adding new format is like "2021-06-30"
    const today: Date = new Date();
    const nextWeek: Date = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    const convertToYYYYMMDD = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    //when displaying format is like "Wed, Jun 30, 2021"
    function formatDate(inputDate: string): string {
        const dateParts = inputDate.split("-");
        const formattedDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));

        const formatter = new Intl.DateTimeFormat("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
            year: "numeric",
        });

        return formatter.format(formattedDate);
    }

    const handleItemClick = (selectedItem: TeamTaskAppData): void => {
        setSelectedItem(selectedItem);
    };
    const handleRadioChange = async (event: { target: { value: SetStateAction<string> } }): Promise<void> => {
        setSelectedOption(event.target.value);
        if (event.target.value === "private") {
            setGetTagValue("");
            await handleGet(event.target.value);
        }
    };
    const handleCheckboxChange = async (selectedItem: TeamTaskAppData): Promise<void> => {
        // Update the status property when the checkbox is checked or unchecked
        const updatedAppDataList = teamTaskAppDataList.map((item: TeamTaskAppData) =>
            item.id === selectedItem.id ? { ...item, iscomplete: !item.iscomplete } : item
        );

        const updatedDataItem = updatedAppDataList.find((item: TeamTaskAppData) => item.id === selectedItem.id);
        if (updatedDataItem !== undefined) {
            updatedDataItem.action = "update";
            await setEditedItemToRepo(JSON.stringify(updatedDataItem));
        }

        setTeamTaskAppDataList(updatedAppDataList);
    };
    const handleAdd = async (): Promise<void> => {
        if (newItemValue.trim() !== "") {
            const newTeamTaskAppData: TeamTaskAppData = {
                id: 0,
                title: newItemValue,
                notes: "",
                assignedto: alias === undefined ? "" : alias,
                accessto: "",
                duedate: convertToYYYYMMDD(nextWeek),
                publictag: "",
                action: "insert",
                createdby: alias === undefined ? "" : alias,
                modifiedby: alias === undefined ? "" : alias,
                iscomplete: false,
                createddate: undefined,
                modifieddate: undefined,
            };
            const insertedItem = await setEditedItemToRepo(JSON.stringify(newTeamTaskAppData));

            if (insertedItem !== undefined) {
                setTeamTaskAppDataList([...teamTaskAppDataList, insertedItem[0]]);
            }
            setNewItemValue("");
        }
    };
    const handleGet = async (selOptionInput: string): Promise<void> => {
        try {
            let body = "";
            const tagBody: TeamTaskAppData = {
                id: 0,
                title: "",
                notes: "",
                assignedto: "",
                accessto: "",
                duedate: nextWeek.toDateString(),
                publictag: getTagValue.trim(),
                action: "",
                createdby: "",
                iscomplete: true,
                modifiedby: "",
                createddate: undefined,
                modifieddate: undefined,
            };

            if (selOptionInput === "public") {
                body = JSON.stringify(tagBody);
            }

            const teamTaskAppDataListFromRepo = await getTeamTaskAppDataFromRepo(body);
            if (teamTaskAppDataListFromRepo !== undefined) {
                setTeamTaskAppDataList(teamTaskAppDataListFromRepo);
            }
            console.log("App data fetched:", teamTaskAppDataListFromRepo);
        } catch (e) {
            const error = e as Error;
            console.error("Error fetching app data:", error.message);
            // Handle the error as needed
        }
    };
    useEffect(() => {}, [teamTaskAppDataList]);

    return (
        <div className={styles.content}>
            <div className={styles.getOptions}>
                <div>
                    <label>
                        <input
                            type="radio"
                            value="private"
                            checked={selectedOption === "private"}
                            //eslint-disable-next-line  @typescript-eslint/no-misused-promises
                            onChange={handleRadioChange}
                        />
                        My Team Task
                    </label>
                </div>
                <div className={styles.getOption2}>
                    <label>
                        <input
                            type="radio"
                            value="public"
                            checked={selectedOption === "public"}
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onChange={handleRadioChange}
                        />
                        Public Tag Tasks
                    </label>
                    {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises */}
                    <input
                        type="text"
                        id="tag"
                        name="tag"
                        placeholder="public tag name"
                        value={getTagValue}
                        onChange={(e): void => {
                            setGetTagValue(e.target.value);
                        }}
                        disabled={selectedOption === "private"}
                    />
                    {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises */}
                    <button onClick={(): Promise<void> => handleGet("public")} disabled={selectedOption === "private"}>
                        Get
                    </button>
                </div>
            </div>
            <div>
                <br />
                <br />
            </div>
            <div className={styles.add}>
                {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises */}
                <input
                    type="text"
                    placeholder="add new task"
                    value={newItemValue}
                    onChange={(e): void => {
                        setNewItemValue(e.target.value);
                    }}
                    size={70}
                />
                {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises */}
                <button className={styles.button} onClick={(): Promise<void> => handleAdd()}>
                    Add
                </button>
            </div>

            <div className={styles.list}>
                <ul>
                    {teamTaskAppDataList.map((item: TeamTaskAppData) => (
                        <div key={item.id}>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                            <li
                                key={item.id}
                                onClick={(): void => {
                                    handleItemClick(item);
                                }}
                            >
                                <div className={styles.row}>
                                    {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises */}
                                    <input
                                        title=""
                                        type="checkbox"
                                        checked={item.iscomplete}
                                        // eslint-disable-next-line  @typescript-eslint/no-misused-promises
                                        onChange={(): Promise<void> => handleCheckboxChange(item)}
                                    />
                                    {"  "}
                                    {item.title}{" "}
                                    <div className={styles.details}>
                                        {item.assignedto}
                                        {","}
                                        {item.accessto}
                                        {","}
                                        {item.createdby}
                                        {","}
                                    </div>
                                    <div className={styles.details}>{formatDate(item.duedate)} </div>
                                    <div className={styles.details}>{item.publictag} </div>
                                    <div className={styles.details}>{item.iscomplete ? "Completed" : "Active"}</div>
                                </div>
                            </li>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}
