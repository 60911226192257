import type { TelemetryEvent } from "@empowerment/telemetry";
import { TelemetryService } from "@empowerment/telemetry";
import type { ReactElement, SetStateAction } from "react";
import { useState, useEffect, useMemo } from "react";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { TeamTaskAppProvider } from "./components/TeamTask/TeamTaskAppContext";
import { TeamTaskContent } from "./components/TeamTask/TeamTaskContent";
import { AppContent } from "~/components/AppContent";
import { TopNavHeader } from "~/components/TopNavHeader";
import type { PageNameTypes } from "~/models/Page";
import { PageNameEnum, PageContext } from "~/models/Page";
import { EventState, EventStatus } from "~/models/TelemetryContext";
import { AuthenticationContextProvider } from "~/services/AuthenticationProvider";
import { TelemetryEvents, TelemetryServiceTrackEvent } from "~/utils/telemetryUtils";

export function App(): ReactElement {
    const pageLoadStartTime = useMemo(() => Date.now(), []);
    const [pageName, setPageName] = useState<PageNameTypes>(
        window.location.pathname === "/environments"
            ? PageNameEnum.Environments
            : window.location.pathname === "/teamtask"
            ? PageNameEnum.TeamTask
            : PageNameEnum.Collections
    );
    const [authUserInitializedInTelemetry, setAuthUserInitializedInTelemetry] = useState<boolean>(false);
    const telemetryContext = useMemo(() => ({ page: pageName }), [pageName]);
    const debugMode = useMemo(() => process.env.ENVIRONMENT !== "prod", []);

    // initial load
    useEffect(() => {
        // this check avoids empty user_AuthenticatedId attribute in appInsight
        if (authUserInitializedInTelemetry) {
            // NOTE: telemetry in this useEffect() will be logged twice in development due to React strict mode (https://reactjs.org/docs/strict-mode.html)
            const duration = Date.now() - pageLoadStartTime;
            // Since we can only use TelemetryServiceTrackEvent() after bootTelemetry(), there's no eventState: Initiated log for this event
            const initialPageName =
                window.location.pathname === "/environments"
                    ? PageNameEnum.Environments
                    : window.location.pathname === "/teamtask"
                    ? PageNameEnum.TeamTask
                    : PageNameEnum.Collections;
            TelemetryServiceTrackEvent(
                TelemetryEvents.ApixPortal.Loaded,
                { page: initialPageName, eventState: EventState.Completed, eventStatus: EventStatus.Success }, // Todo: (sivad): Log telemetry for failure case
                { Duration: duration }
            );
            // log initial page is loaded
            if (initialPageName === PageNameEnum.Collections) {
                TelemetryServiceTrackEvent(TelemetryEvents.ApixPortal.CollectionsPageLoaded);
            } else {
                TelemetryServiceTrackEvent(TelemetryEvents.ApixPortal.EnvironmentsPageLoaded);
            }
        }
    }, [pageLoadStartTime, authUserInitializedInTelemetry]);

    const updatePageName = (page: SetStateAction<PageNameTypes>): void => {
        let pageLoadedEvent: TelemetryEvent;
        let navigationLink: string;
        if (page === PageNameEnum.Collections) {
            pageLoadedEvent = TelemetryEvents.ApixPortal.CollectionsPageLoaded;
            navigationLink = `${window.location.origin}/collections`;
        } else if (page === PageNameEnum.Environments) {
            pageLoadedEvent = TelemetryEvents.ApixPortal.EnvironmentsPageLoaded;
            navigationLink = `${window.location.origin}/environments`;
        } else {
            pageLoadedEvent = TelemetryEvents.ApixPortal.EnvironmentsPageLoaded;
            navigationLink = `${window.location.origin}/teamtask`;
        }
        //alert("page:" + page + "navigationLink:" + navigationLink   )
        // log whenever page is switched
        TelemetryServiceTrackEvent(pageLoadedEvent);
        setPageName(page);
        window.history.replaceState(window.history.state, "", navigationLink);
    };

    return (
        <ErrorBoundary
            onException={TelemetryService.trackException}
            telemetryContext={telemetryContext}
            debugMode={debugMode}
        >
            <AuthenticationContextProvider
                authUserInitializedInTelemetry={authUserInitializedInTelemetry}
                setAuthUserInitializedInTelemetry={setAuthUserInitializedInTelemetry}
            >
                <TopNavHeader />
                {/* leave PageContext.Provider here because telemetryContext needs to read page name */}
                <PageContext.Provider
                    value={{
                        pageName: pageName,
                        updatePageName: updatePageName,
                    }}
                >
                    {pageName === PageNameEnum.TeamTask ? (
                        <TeamTaskAppProvider>
                            <TeamTaskContent />
                        </TeamTaskAppProvider>
                    ) : (
                        <AppContent />
                    )}
                </PageContext.Provider>
            </AuthenticationContextProvider>
        </ErrorBoundary>
    );
}
