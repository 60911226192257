import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useContext, useCallback, useState, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import { Button } from "~/enabler/Button";
import type { MenuItemType } from "~/enabler/DropDown";
import { DropDown } from "~/enabler/DropDown";
import { Select } from "~/enabler/Select";
import type { SelectOptionConfig } from "~/enabler/Select/Select";
import { TextField } from "~/enabler/TextField";
import { stringConstants } from "~/locale/stringConstants";
import { CollectionContext } from "~/models/Collection";
import { EnvironmentContext } from "~/models/Environment";
import type { HttpVerbTypes, RequestStartTimes, CorrelationAttributes } from "~/models/IRequest";
import { methodList, RequestContext } from "~/models/IRequest";
import type { ResponseType } from "~/models/IResponse";
import { PageContext } from "~/models/Page";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";
import { createItemId, getCorrespondingDataItem, splitItemIds } from "~/utils/DataUtils/DataUtils";
import { sendTargetRequest } from "~/utils/requestUtils";
import { TelemetryEvents, TelemetryServiceTrackEvent } from "~/utils/telemetryUtils";
import { fetchRecentlyUsedUrls } from "~/utils/userPreferencesUtils";
import { Icon } from "~/enabler/Icon";
import { TelemetryServiceTrackTraceInformation } from "~/utils/telemetryUtils/telemetryUtils";

type RequestInfoProps = {
    setResponse: React.Dispatch<React.SetStateAction<ResponseType | undefined>>;
    setShowProgress: React.Dispatch<React.SetStateAction<boolean>>;
    setCorrelationAttributes: React.Dispatch<React.SetStateAction<CorrelationAttributes>>;
    setTargetRequestDispatched: React.Dispatch<React.SetStateAction<boolean>>;
    setRequestStartTimes: React.Dispatch<React.SetStateAction<RequestStartTimes>>;
    showProgress: boolean;
    selectedRequestIndex: string;
};

const NO_ENVIRONMENT = "No Environment";
const page = "RequestInfo";
export function RequestInfo(props: RequestInfoProps): ReactElement {
    const {
        setResponse,
        setShowProgress,
        setCorrelationAttributes,
        setTargetRequestDispatched,
        setRequestStartTimes,
        showProgress,
        selectedRequestIndex,
    } = props;
    const { selectedCollectionIndex, collectionList } = useContext(CollectionContext); //setCollectionList
    const { request, updateRequest } = useContext(RequestContext);
    const { pageName } = useContext(PageContext);
    const { environmentName, environmentList, setEnvironmentName, setEnvironmentList } = useContext(EnvironmentContext);

    const [recentlyUsedUrls, setRecentlyUsedUrls] = useState<SelectOptionConfig[]>([]);
    const [selectedEnvironmentName, setSelectedEnvironmentName] = useState<string>(
        environmentName === "" ? NO_ENVIRONMENT : environmentName
    );
    const { authUserInitializedInTelemetry } = useAuthenticationContext();

    const environmentDropDownList: MenuItemType<string>[] = useMemo(() => {
        // Should not show "Global" environment as an option, include "No Environment" instead
        const envList = environmentList.map((environment) => ({ value: environment.name }));
        return [{ value: "No Environment" }, ...envList];
    }, [environmentList]);
    const selectedItemKey = splitItemIds(selectedRequestIndex);

    useEffect(() => {
        // only fetch urls if authenticatedUserContext is set in Telemetry since fetchRecentlyUsedUrls() logs telemetry
        // this check avoids empty user_AuthenticatedId attribute in appInsight
        if (authUserInitializedInTelemetry) {
            const urls = fetchRecentlyUsedUrls() ?? [];
            const parsedSelectOptions: SelectOptionConfig[] = urls.map((url) => ({ value: url }));
            setRecentlyUsedUrls(parsedSelectOptions);
            const envDeleted =
                environmentList.find((environment) => environment.name === selectedEnvironmentName) === undefined;
            // this checks if the selected environment name has been deleted. If so, display 'No Environment'
            if (selectedEnvironmentName !== NO_ENVIRONMENT && envDeleted) {
                setSelectedEnvironmentName(NO_ENVIRONMENT);
            }
        }
    }, [authUserInitializedInTelemetry, environmentList, selectedEnvironmentName, setEnvironmentList]);

    const onHTTPVerbChange = useCallback(
        (value: string) => {
            TelemetryServiceTrackEvent(TelemetryEvents.RequestInfo.HttpVerbUpdated);
            updateRequest((prev) => ({ ...prev, method: value as HttpVerbTypes }));
        },
        [updateRequest]
    );

    const onEnvironmentChange = useCallback(
        (value: string) => {
            TelemetryServiceTrackEvent(TelemetryEvents.RequestInfo.EnvironmentUpdated);
            setEnvironmentName(value);
            setSelectedEnvironmentName(value);
        },
        [setEnvironmentName]
    );

    const onBlur = useCallback(
        (value: unknown) => {
            const newUrl = value as string;
            // do not update request or requestName if url is not updated
            if (request.url !== newUrl) {
                updateRequest((prev) => ({ ...prev, url: newUrl }));
                TelemetryService.trackTrace("User changes request url", SeverityLevel.Information);
            }
        },
        [updateRequest, request.url]
    );

    const onSendButtonClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestInfo.SendRequestButtonClicked);
        setShowProgress(true);
        const sendRequestStartTime = Date.now();
        TelemetryServiceTrackTraceInformation(
            `${page}.onSendButtonClick.callSendTargetRequest`,
            SeverityLevel.Information
        );
        return sendTargetRequest(
            request,
            updateRequest,
            setResponse,
            setShowProgress,
            setRecentlyUsedUrls,
            setCorrelationAttributes,
            setTargetRequestDispatched,
            setRequestStartTimes,
            sendRequestStartTime,
            pageName,
            environmentList,
            environmentName
        ); // requestStartTimes are updated here
    }, [
        request,
        updateRequest,
        setResponse,
        setShowProgress,
        setRecentlyUsedUrls,
        setCorrelationAttributes,
        setTargetRequestDispatched,
        setRequestStartTimes,
        pageName,
        environmentList,
        environmentName,
    ]);

    const dataItem = getCorrespondingDataItem(selectedCollectionIndex, selectedRequestIndex, collectionList);
    let isLocked = false;
    try {
        isLocked = dataItem?.category.toLowerCase() === "locked" ? true : false;
    } catch (e) {
        console.log("Error in RequestInfo.tsx: ", e);
    }

    const copyToClipboard = (): void => {
        const fileId = createItemId("file", selectedItemKey.itemId, selectedItemKey.itemTopParentId);
        const deepLink = `${window.location.origin}${window.location.pathname}?id=${fileId}`;
        navigator.clipboard
            .writeText(deepLink)
            .then(() => {
                alert(`Deep Link ${deepLink} copied to clipboard`);
            })
            .catch((err) => {
                //console.error('Failed to copy text: ', err);
            });
    };

    return (
        <div className={styles.queryDiv}>
            <div className={styles.dropDownContent}>
                <div className={styles.labelDiv}>
                    Request ID {selectedItemKey.itemId}
                    <Button appearance="link" className="panelButton" onClick={copyToClipboard}>
                        {" "}
                        <Icon name="link" slot="start" />
                    </Button>
                </div>
            </div>
            <div className={styles.dropDownContent}>
                <div className={styles.methodContent}>
                    <div className={styles.dropDownLabelDiv}>Http Verb</div>
                    <DropDown
                        dropdownButtonStyles={styles.dropDownStyles}
                        message={request.method}
                        items={methodList}
                        caret={true}
                        menuItemOnClick={onHTTPVerbChange}
                        name="HttpVerbDropDown"
                    />
                </div>

                <div className={styles.environmentContent}>
                    <div className={styles.dropDownLabelDiv}>Environment</div>
                    <DropDown
                        dropdownButtonStyles={styles.dropDownStyles}
                        message={selectedEnvironmentName}
                        caret={true}
                        items={environmentDropDownList}
                        menuItemOnClick={onEnvironmentChange}
                        name="EnvironmentDropDown"
                    />
                </div>
            </div>
            <div id="url div" className={styles.urlContent}>
                <div className={styles.urlDiv}>
                    <div className={styles.urlText}>URL</div>
                    {recentlyUsedUrls.length === 0 ? (
                        <TextField
                            className={styles.textField}
                            placeHolder={stringConstants.components.App.RequestInfo.UrlPlaceholderText}
                            value={request.url}
                            onChange={onBlur}
                            required={true}
                        />
                    ) : (
                        <Select
                            label={stringConstants.components.App.RequestInfo.UrlLabel}
                            hideLabel={true}
                            className={styles.select}
                            autocomplete="both"
                            freeform={true}
                            optionValues={recentlyUsedUrls}
                            placeholder={stringConstants.components.App.RequestInfo.UrlPlaceholderText}
                            value={request.url}
                            required={true}
                            onBlur={onBlur}
                            onSelected={onBlur} // onSelected needs to do the same thing as onBlur
                            hideEmptyState={true}
                        />
                    )}
                </div>
                <Button
                    className={styles.sendButtonStyles}
                    name="SendButton"
                    appearance="primary"
                    onClick={onSendButtonClick}
                    disabled={isLocked} //{showProgress}
                >
                    Send
                </Button>
            </div>
        </div>
    );
}

//TODO : validate
// const onSendButtonClickValidate = useCallback(() => {
//     //validate for Prod Live
//     const topParentDataItem = collectionList.find(
//         (item) => item.id === splitItemIds(selectedCollectionIndex).itemId
//     );
//     const testHeader = request.headers?.find((header) => header.key.toLocaleLowerCase() === "x-ms-test");
//     // alert(
//     //     topParentDataItem?.category.toLocaleLowerCase() +
//     //         " " +
//     //         environmentName.toLocaleLowerCase() +
//     //         " " +
//     //         request.method.toLocaleLowerCase() +
//     //         " " +
//     //         testHeader?.value.toLocaleLowerCase()
//     // );
//     if (
//         (testHeader === null || testHeader === undefined) &&
//         environmentName.toLocaleLowerCase() === "prod" &&
//         request.method.toLocaleLowerCase() !== "get" &&
//         topParentDataItem?.category.toLocaleLowerCase() !== "prodlive" &&
//         topParentDataItem?.category.toLocaleLowerCase() !== ""
//     ) {
//         alert(
//             "Prod live data updates are to be saved & executed only in Prod Live collection and needs to be locked after execution."
//         );
//         return;
//     } else {
//         return onSendButtonClick();
//     }
// }, []);
