/**
 * Describes the telemetry names of events by class.
 */
export const TelemetryEvents = {
    ApixPortal: {
        /**
         * Initial load of Apix Portal
         */
        Loaded: {
            eventName: "Apix Portal Loaded",
        },
        /**
         * Workspace page loaded
         */
        WorkspacePageLoaded: {
            eventName: "Workspace Page Loaded",
        },
        /**
         * Saved page loaded
         */
        SavedPageLoaded: {
            eventName: "Saved Page Loaded",
        },
        /**
         * Collections page loaded
         */
        CollectionsPageLoaded: {
            eventName: "Collections Page Loaded",
        },
        /**
         * Environments page loaded
         */
        EnvironmentsPageLoaded: {
            eventName: "Environments Page Loaded",
        },
    },
    DispatchRequest: {
        /**
         * Dispatch request from APIX Portal initiated
         */
        Initiated: {
            eventName: "Dispatch Request Initiated",
        },
        /**
         * Dispatch request from APIX Portal completed
         */
        Completed: {
            eventName: "Dispatch Request Completed",
        },
    },
    LeftNavigation: {
        /**
         * Switch page on LeftNavigation
         */
        PageLoad: {
            eventName: "Page Loaded",
        },
    },
    CollectionsPanel: {
        UnsavedChangesDialogOpened: {
            eventName: "Unsaved Changes Dialog Opened",
        },
        UnsavedChangesDialogClosed: {
            eventName: "Unsaved Changes Dialog Closed",
        },
        /**
         * Click continue without saving button on UnsavedChanges dialog
         */
        ContinueWithoutSavingButtonClicked: {
            eventName: "Continue Without Saving Button Clicked",
        },
        /**
         * Click save and continue button on UnsavedChanges dialog
         */
        SaveAndContinueButtonClicked: {
            eventName: "Save And Continue Button Clicked",
        },
        /**
         * Click cancel button on UnsavedChanges dialog
         */
        CancelButtonClicked: {
            eventName: "Cancel Button Clicked",
        },
    },
    RequestInfo: {
        /**
         * Update Http Verb through dropdown
         */
        HttpVerbUpdated: {
            eventName: "Http Verb Updated",
        },
        /**
         * Update url in field
         */
        UrlFieldUpdated: {
            eventName: "Url Field Updated",
        },
        /**
         * Update Environment through dropdown
         */
        EnvironmentUpdated: {
            eventName: "Environment Updated",
        },
        /**
         * Click Send request button
         */
        SendRequestButtonClicked: {
            eventName: "Send Request Button Clicked",
        },
    },
    CollectionsPanelHeader: {
        /**
         * Click add new collection button
         */
        AddNewCollectionButtonClicked: {
            eventName: "Add New Collection Button Clicked",
        },
        /**
         * Click add new request button
         */
        AddNewRequestButtonClicked: {
            eventName: "Add New Request Button Clicked",
        },
        /**
         * Click delete collection button
         */
        DeleteCollectionButtonClicked: {
            eventName: "Delete Collection Button Clicked",
        },
        /**
         * Click rename collection button
         */
        RenameCollectionButtonClicked: {
            eventName: "Rename Collection Button Clicked",
        },
        /**
         * Click save button on rename collection dialog
         */
        SaveButtonOnRenameCollectionDialogClicked: {
            eventName: "Save Button On Rename Collection Dialog Clicked",
        },
        /**
         * Click delete button on delete collection dialog
         */
        DeleteButtonOnDeleteCollectionDialogClicked: {
            eventName: "Delete Button On Delete Collection Dialog Clicked",
        },
        /**
         * Close rename collection dialog
         */
        RenameCollectionDialogClosed: {
            eventName: "Rename Collection Dialog Closed",
        },
        /**
         * Close delete collection dialog
         */
        DeleteCollectionDialogClosed: {
            eventName: "Delete Collection Dialog Closed",
        },
    },
    EnvironmentsPanelHeader: {
        /**
         * Rename Environment dialog opened
         */
        RenameEnvironmentDialogOpened: {
            eventName: "Rename Environment Dialog Opened",
        },
        /**
         * Delete Environment dialog opened
         */
        DeleteEnvironmentDialogOpened: {
            eventName: "Delete Environment Dialog Opened",
        },
        /**
         * Close rename environment dialog
         */
        RenameEnvironmentDialogClosed: {
            eventName: "Rename Environment Dialog Closed",
        },
        /**
         * Close delete environment dialog
         */
        DeleteEnvironmentDialogClosed: {
            eventName: "Delete Environment Dialog Closed",
        },
        /**
         * Click add new environment button
         */
        AddNewEnvironmentButtonClicked: {
            eventName: "Add New Environment Button Clicked",
        },
        /**
         * Click rename environment button
         */
        RenameEnvironmentButtonClicked: {
            eventName: "Rename Environment Button Clicked",
        },
        /**
         * Click delete environment button
         */
        DeleteEnvironmentButtonClicked: {
            eventName: "Delete Environment Button Clicked",
        },
    },
    RequestPanel: {
        /**
         * Click Send request button
         */
        SendRequestButtonClicked: {
            eventName: "Send Request Button Clicked",
        },
    },
    RequestPanelHeader: {
        /**
         * Save dialog opened
         */
        SaveRequestDialogOpened: {
            eventName: "Save Request Dialog Opened",
        },
        /**
         * Rename dialog opened
         */
        RenameRequestDialogOpened: {
            eventName: "Rename Request Dialog Opened",
        },
        /**
         * Delete dialog opened
         */
        DeleteRequestDialogOpened: {
            eventName: "Delete Request Dialog Opened",
        },
        /**
         * Reset dialog opened
         */
        ResetRequestDialogOpened: {
            eventName: "Reset Request Dialog Opened",
        },
        /**
         * Closes save request dialog
         */
        SaveRequestDialogClosed: {
            eventName: "Save Request Dialog Closed",
        },
        /**
         * Closes rename request dialog
         */
        RenameRequestDialogClosed: {
            eventName: "Rename Request Dialog Closed",
        },
        /**
         * Closes delete request dialog
         */
        DeleteRequestDialogClosed: {
            eventName: "Delete Request Dialog Closed",
        },
        /**
         * Closes delete request dialog
         */
        ResetRequestDialogClosed: {
            eventName: "Reset Request Dialog Closed",
        },
        /**
         * Click save request button
         */
        SaveRequestButtonClicked: {
            eventName: "Save Request Button Clicked",
        },
        /**
         * Click rename request button
         */
        RenameRequestButtonClicked: {
            eventName: "Rename Request Button Clicked",
        },
        /**
         * Click delete request button
         */
        DeleteRequestButtonClicked: {
            eventName: "Delete Request Button Clicked",
        },
        /**
         * Click reset request button
         */
        ResetRequestButtonClicked: {
            eventName: "Reset Request Button Clicked",
        },
    },
    Response: {
        /**
         * Success response from request processed
         */
        SuccessResponseProcessed: {
            eventName: "Success Response Processed",
        },
        /**
         * Error response from request processed
         */
        ErrorResponseProcessed: {
            eventName: "Error Response Processed",
        },
    },
    GetItemFromRequestStorage: {
        /**
         * Get item from request storage initiated
         */
        Initiated: {
            eventName: "Get Item from Request Storage Initiated",
        },
        /**
         * Successful get item from request storage
         */
        Success: {
            eventName: "Get Item from Request Storage Success",
        },
        /**
         * Error get item from request storage
         */
        Error: {
            eventName: "Get Item from Request Storage Error",
        },
    },
    SetItemIntoRequestStorage: {
        /**
         * Set item into request storage initiated
         */
        Initiated: {
            eventName: "Set Item from Request Storage Initiated",
        },
        /**
         * Successful set item into request storage
         */
        Success: {
            eventName: "Set Item from Request Storage Success",
        },
        /**
         * Error set item into request storage
         */
        Error: {
            eventName: "Set Item from Request Storage Error",
        },
    },
    RemoveItemFromRequestStorage: {
        /**
         * Remove item from request storage initiated
         */
        Initiated: {
            eventName: "Remove Item from Request Storage Initiated",
        },
        /**
         * Successful remove item from request storage
         */
        Success: {
            eventName: "Remove Item from Request Storage Success",
        },
        /**
         * Error remove item from request storage
         */
        Error: {
            eventName: "Remove Item from Request Storage Error",
        },
    },
    FetchApixRequest: {
        /**
         * Fetch request to APIX Service initiated
         */
        Initiated: {
            eventName: "Fetch Request to APIX Service Initiated",
        },
        /**
         * Successful fetch request to APIX Service
         */
        Success: {
            eventName: "Fetch Request to APIX Service Success",
        },
        /**
         * Error fetch request to APIX Service
         */
        Error: {
            eventName: "Fetch Request to APIX Service Error",
        },
    },
    TargetServiceRequest: {
        /**
         * Successful fetch request to L2O Service
         */
        Success: {
            eventName: "Fetch Request to L2O Service Success",
        },
        /**
         * Error fetch request to L2O Service
         */
        Error: {
            eventName: "Fetch Request to L2O Service Error",
        },
    },
};
