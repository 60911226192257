import { createContext } from "react";
import type { KeyValue } from "~/components/Common/types";
import { defaultRequest, defaultSystemData } from "~/Constants";
import type { MenuItemType } from "~/enabler/DropDown";

export type RequestContextType = {
    request: Request; // actual request value based on input value
    requestName: string;
    initialRequest: Request; // initial value when user lands on existing request
    updateRequest: (value: React.SetStateAction<Request>) => void;
    updateRequestName: (value: React.SetStateAction<string>) => void;
    updateInitialRequest: (value: React.SetStateAction<Request>) => void;
};

export const RequestContext = createContext<RequestContextType>({
    requestName: "",
    request: defaultRequest,
    initialRequest: defaultRequest,
    updateRequest: null!,
    updateRequestName: null!,
    updateInitialRequest: null!,
});

export type Request = {
    method: HttpVerbTypes;
    url: string;
    body?: RequestBody;
    headers?: KeyValue[];
    params?: KeyValue[];
    systemdata: SystemData;
};

export type SystemData = {
    lastSavedBy: string;
    lastSavedOn: string;
    lastExecutedOn?: string;
    lastExecutedEnv?: string;
};

export type TargetRequest = {
    // HTTP verb for the Request
    method: HttpVerbTypes;
    // URL pertaining to target request.
    url: string;
    // Body content of target request.
    body?: string;
    // List of Http Headers to be attached to target request.
    headers?: HeaderItem[];
};

export type CreateBlobRequest = {
    // guid.ext formate of the filename
    fileName: string;
    // content of the file to be stored
    content: string;
};

export type RenameFileInIndexRequest = {
    //just the guid without the .extension of the file
    fileId: string;
    //new file name
    newFileName: string;
};

export type FileRequest = {
    fileName: string;
};

//request to get entities like env, workspace
export type EntityRequest = {
    id: number;
    type: string;
};

//request to get entities like env, workspace
export type WebInvokeRequest = {
    workspaceId: number;
    environmentId: number;
    targetRequest: TargetRequest;
};

export type RequestBody = {
    type: RequestBodyTypes;
    format: FormatTypes;
    values: KeyValue[] | string; //add more types to handle other BodyTypes
};

export type HttpVerbTypes =
    | "GET"
    | "POST"
    | "PUT"
    | "PATCH"
    | "DELETE"
    | "COPY"
    | "HEAD"
    | "OPTIONS"
    | "LINK"
    | "UNLINK"
    | "PURGE"
    | "LOCK"
    | "UNLOCK"
    | "PROPFIND"
    | "VIEW";

export type FormatTypes = "JSON" | "HTML" | "JavaScript" | "Text" | "XML" | "";

export type RequestBodyTypes = "raw" | "form-data" | "none" | "x-www-form-urlencoded" | "binary" | "GraphQL";

export const methodList: MenuItemType<HttpVerbTypes>[] = [
    { value: "GET" },
    { value: "POST" },
    { value: "PUT" },
    { value: "PATCH" },
    { value: "DELETE" },
    { value: "COPY" },
    { value: "HEAD" },
    { value: "OPTIONS" },
    { value: "LINK" },
    { value: "UNLINK" },
    { value: "PURGE" },
    { value: "LOCK" },
    { value: "UNLOCK" },
    { value: "PROPFIND" },
    { value: "VIEW" },
];

export type HeaderItem = {
    // Header belonging to the Target Request's Url and Target Response
    key: string;
    // Collection of Values for the respective Header
    value: string[];
};

export enum BodyRadioEnum {
    Raw = "raw",
    FormData = "form-data",
}

export type RequestStartTimes = {
    sendRequestStartTime: number;
    acquireTokenStartTime: number;
    fetchStartTime: number;
    parseResponseStartTime: number;
    renderResponseStartTime: number;
};

export type CorrelationAttributes = {
    MSCV: string;
    correlationId: string | undefined;
};
