/* istanbul ignore file */
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { NoApp } from "./components/NoApp";
import { appConfig } from "./config/appConfig";
import { initializeStorage } from "~/services/DbRepository";
import { bootTelemetry } from "~/utils/telemetryUtils";

const BootAppWithConfigs = (): void => {
    const AppConfig = import(/* webpackChunkName: "appConfig" */ "./config/bootAppConfig");
    AppConfig.then((appConfigs) => {
        if (process.env.NODE_ENV !== "development") {
            appConfigs.bootAppConfig();
        }
        bootTelemetry();
        initializeStorage();
        BootApp();
    }).catch((error) => {
        console.log("Failed to initialize app configs");
        console.error(error);
    });
};

const BootApp = (): void => {
    const rootContainer = document.createElement("div");
    rootContainer.style.cssText = "position:absolute;top:0%;left:0%;height:4%;width:100%";
    document.body.style.overflow = "hidden";
    document.body.appendChild(rootContainer);
    const root = createRoot(rootContainer);
    const renderApp = appConfig().isAppEnabled === "true";
    root.render(<StrictMode>{renderApp ? <App /> : <NoApp />}</StrictMode>);
};

BootAppWithConfigs();
