import type { ReactElement } from "react";
import styles from "./styles.module.scss";
import { Button } from "~/enabler/Button";
import { Dialog } from "~/enabler/Dialog";
import { stringConstants } from "~/locale/stringConstants";
type NavigationDialogProps = {
    closeDialog: () => void;
    saveAndContinueOnClick: () => void;
    continueWithoutSavingOnClick: () => void;
    cancelOnClick: () => void;
};

export function NavigationDialog(props: NavigationDialogProps): ReactElement {
    const { closeDialog, saveAndContinueOnClick, continueWithoutSavingOnClick, cancelOnClick } = props;
    return (
        <Dialog
            heading={stringConstants.components.App.CollectionPanel.UnsavedChanges}
            open={true}
            onHide={closeDialog}
        >
            <p>{stringConstants.components.App.CollectionPanel.DialogMessage}</p>
            <div className={styles.buttonGroupRoot}>
                <Button slot="footer" appearance="primary" onClick={saveAndContinueOnClick}>
                    {stringConstants.components.App.CollectionPanel.SaveAndContinue}
                </Button>
                <Button slot="footer" onClick={continueWithoutSavingOnClick}>
                    {stringConstants.components.App.CollectionPanel.ContinueWithoutSaving}
                </Button>
                <Button slot="footer" onClick={cancelOnClick}>
                    {stringConstants.components.Common.Cancel}
                </Button>
            </div>
        </Dialog>
    );
}
