import type { ReactElement } from "react";
import { useContext, useCallback } from "react";
import styles from "./styles.module.scss";
import { Button } from "~/enabler/Button";
import { Icon } from "~/enabler/Icon";
import { stringConstants } from "~/locale/stringConstants";
import { EnvironmentContext, GlobalEnvironment } from "~/models/Environment";
import { addNewEnvironment, fetchEnvironmentList } from "~/utils/environmentUtils/environmentUtils";
import { TelemetryEvents, TelemetryServiceTrackEvent } from "~/utils/telemetryUtils";

type EnvironmentPanelHeaderProps = {
    renameEnvironmentOnClick: () => void;
    deleteEnvironmentOnClick: () => void;
    enableEnvironmentActions: boolean;
};

export function EnvironmentsPanelHeader(props: EnvironmentPanelHeaderProps): ReactElement {
    const { renameEnvironmentOnClick, deleteEnvironmentOnClick, enableEnvironmentActions } = props;
    const { setSelectedEnvironmentIndex, setEnvironmentList } = useContext(EnvironmentContext);

    const addNewEnvironmentOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.EnvironmentsPanelHeader.AddNewEnvironmentButtonClicked);
        addNewEnvironment();
        // reload environmentList
        const result = fetchEnvironmentList() ?? [GlobalEnvironment];
        setEnvironmentList(result);
        setSelectedEnvironmentIndex(result.length - 1);
    }, [setEnvironmentList, setSelectedEnvironmentIndex]);

    return (
        <div className={styles.root}>
            <div>
                <Button
                    id={stringConstants.components.Common.Environment.NewEnvironment}
                    appearance="command"
                    onClick={addNewEnvironmentOnClick}
                    title={stringConstants.components.Common.Environment.NewEnvironment}
                >
                    <Icon
                        name="newfolder"
                        slot="start"
                        label={stringConstants.components.Common.Environment.NewEnvironment}
                    />
                </Button>
                <Button
                    id={stringConstants.components.Common.Environment.RenameEnvironment}
                    appearance="command"
                    disabled={!enableEnvironmentActions} // Only enable it when user selects a collection
                    onClick={renameEnvironmentOnClick}
                    title={stringConstants.components.Common.Environment.RenameEnvironment}
                >
                    <Icon
                        name="edit"
                        slot="start"
                        label={stringConstants.components.Common.Environment.RenameEnvironment}
                    />
                </Button>
                <Button
                    id={stringConstants.components.Common.Environment.DeleteEnvironment}
                    appearance="command"
                    disabled={!enableEnvironmentActions} // Only enable it when user selects a collection
                    onClick={deleteEnvironmentOnClick}
                    title={stringConstants.components.Common.Environment.DeleteEnvironment}
                >
                    <Icon
                        name="delete"
                        slot="start"
                        label={stringConstants.components.Common.Environment.DeleteEnvironment}
                    />
                </Button>
            </div>
        </div>
    );
}
