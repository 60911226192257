import { AuthenticationService, RequestType } from "@empowerment/authenticationservice";
import { appConfig } from "~/config/appConfig";

export async function getUserPhoto(): Promise<string> {
    const accessToken = await AuthenticationService.acquireTokenForScope(appConfig().graphApiScope);

    const rawPhoto = await fetch(`${appConfig().graphApiBase}me/photo/$value`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        method: RequestType.GET,
    });
    const photoBlobUrl = URL.createObjectURL(await rawPhoto.blob());
    return photoBlobUrl;
}
