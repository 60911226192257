import { tab, tabPanel, tabs } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { Fragment } from "react";
import styles from "./styles.module.scss";
import { scopeForReact } from "~/enabler/scope";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyTabs = scopeForReact(tabs);
const HarmonyTab = scopeForReact(tab);
const HarmonyTabPanel = scopeForReact(tabPanel);

export type TabSections = {
    id: string;
    panelName: string;
    value: ReactElement | string | number;
};

type TabsProps = {
    tabs: TabSections[];
};

function TabsComponent(props: TabsProps): ReactElement {
    return (
        <HarmonyTabs className={styles.tabs}>
            {props.tabs.map((pTab) => (
                <Fragment key={pTab.panelName}>
                    <HarmonyTab id={pTab.id}>{pTab.panelName}</HarmonyTab>
                    <HarmonyTabPanel className={styles.tabPanel}>{pTab.value}</HarmonyTabPanel>
                </Fragment>
            ))}
        </HarmonyTabs>
    );
}

export const Tabs = typedMemo(TabsComponent);
