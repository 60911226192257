import { RequestType } from "@empowerment/authenticationservice";
import type { TeamTaskAppData } from "../../../models/TeamTask/TeamTaskAppData";
import { executeHTTPRequest } from "../../../services/HttpClient/HttpClient";
import { appConfig } from "~/config/appConfig";

export const getTeamTaskAppDataFromRepo = async (
    tagBodyJson: string | undefined
): Promise<TeamTaskAppData[] | undefined> => {
    let teamTaskappDataList: TeamTaskAppData[] = [];
    try {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const url = tagBodyJson
            ? `${appConfig().apixApiBase}/V1/task/GetByTag`
            : `${appConfig().apixApiBase}/V1/task/GetByUser`;
        const httpResponse = executeHTTPRequest(RequestType.POST, url, undefined, undefined, tagBodyJson);

        //TODO: check unhappy
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        teamTaskappDataList = Object.values(jsonBodyObject);

        /*TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {        appData: JSON.stringify(appDataList),    }); */
        //setData(appDataList);
    } catch (e) {
        const error = e as Error;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,
        console.error(`error=${error.message}, ${error.name}, ${error.cause}, ${error.stack}`);
    }
    return teamTaskappDataList;
};

export const setEditedItemToRepo = async (itemJson: string): Promise<TeamTaskAppData[] | undefined> => {
    let teamTaskappData: TeamTaskAppData[] = [];
    try {
        const url = `${appConfig().apixApiBase}/V1/task/create`;
        const httpResponse = executeHTTPRequest(RequestType.POST, url, undefined, undefined, itemJson); //create and update
        console.log(`itemjson=${itemJson}`);
        console.log(httpResponse);
        //TODO: check unhappy
        const jsonData = JSON.stringify((await httpResponse).responseBody);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const jsonObject = JSON.parse(jsonData);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unnecessary-type-assertion
        teamTaskappData = Object.values(jsonBodyObject) as TeamTaskAppData[];
    } catch (e) {
        const error = e as Error;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions,
        console.error(`error=${error.message}, ${error.name}, ${error.cause}, ${error.stack}`);
    }
    return teamTaskappData;
};
