import { progressRing } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyProgressRing = scopeForReact(progressRing);

type ProgressRingProps = {
    label?: string; // custom label rendered under the progress ring
    indeterminate?: boolean;
} & BaseEnablerAttributes;

function ProgressRingComponent(props: ProgressRingProps): ReactElement {
    const { label, indeterminate, ...rest } = props;
    return <HarmonyProgressRing label={label} indeterminate={indeterminate} {...rest} />;
}

export const ProgressRing = typedMemo(ProgressRingComponent);
