import { stringConstants } from "./locale/stringConstants";
import type { Collection, Items } from "./models/Collection";
import type { Request, SystemData } from "./models/IRequest";
import type { Environment } from "~/models/Environment";

export const defaultRequest: Request = {
    method: "POST",
    url: "",
    body: { type: "raw", format: "JSON", values: "" },
    headers: [
        {
            key: stringConstants.components.App.RequestPanel.MSCV,
            value: "{{$CV}}",
            disabled: false,
            informationText: stringConstants.components.App.RequestPanel.mscvInfoText,
            required: false,
        },
        {
            key: stringConstants.components.App.RequestPanel.correlationId,
            value: "{{$GUID}}",
            disabled: false,
            informationText: stringConstants.components.App.RequestPanel.correlationIdInfoText,
        },
        { key: "", value: "" },
    ],
    params: Array.from({ length: 3 }).map(() => ({ key: "", value: "" })),
    systemdata: {
        lastSavedBy: "",
        lastSavedOn: "",
        lastExecutedOn: "",
        lastExecutedEnv: "",
    },
};

export const defaultKeyValueList = [
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
];

export const defaultEnvironmentName = "New Environment";

export const defaultEnvironment: Environment = {
    name: defaultEnvironmentName,
    variableList: defaultKeyValueList,
    varialeType: "global",
};

export const defaultCollectionName = "New Collection";

export const defaultFolderName = "New Folder";

export const defaultRequestName = "New Request";

export const defaultCollection: Collection = {
    name: defaultCollectionName,
    items: [],
    id: "",
    type: "",
    pathId: "",
    pathName: "",
    category: "",
};

export const defaultSystemData: SystemData = {
    lastSavedBy: "",
    lastSavedOn: "",
    lastExecutedOn: "",
    lastExecutedEnv: "",
};

export const defaultFolder: Items = {
    name: defaultFolderName,
    items: [],
    id: "",
    type: "",
    pathId: "",
    pathName: "",
    action: "",
    parentId: "",
    category: "",
};

//Todo: (sivad) : to enums?
export const defaultFileRequestType = "file";
export const defaultFolderRequestType = "folder";
export type ItemType = "collection" | "file" | "folder";
