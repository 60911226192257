import { dialog } from "@harmony/enablers/react";
import type { ReactElement, ReactNode } from "react";
import styles from "./styles.module.scss";
import { eventOnlyOnCurrentTarget } from "~/enabler/eventOnlyOnCurrentTarget";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyDialog = scopeForReact(dialog);

type DialogProps = {
    children: ReactNode;
    heading?: string;
    open?: boolean;
    noHeader?: boolean;
    // Emitted when the dialog closes.
    onHide?: () => void;
    // Emitted after the dialog closes and all transitions are complete.
    onAfterHide?: () => void;
} & BaseEnablerAttributes;

function DialogComponent(props: DialogProps): ReactElement {
    const { children, onHide, onAfterHide, ...rest } = props;
    return (
        <HarmonyDialog
            className={styles.dialogHeader}
            onHeHide={eventOnlyOnCurrentTarget(onHide)}
            onHeAfterHide={eventOnlyOnCurrentTarget(onAfterHide)}
            {...rest}
        >
            {children}
        </HarmonyDialog>
    );
}

export const Dialog = typedMemo(DialogComponent);
