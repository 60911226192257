import { createContext } from "react";

export enum PageNameEnum {
    Collections = "Collections",
    Environments = "Environments",
    TeamTask = "TeamTask",
}

export type PageNameTypes = PageNameEnum.Collections | PageNameEnum.Environments | PageNameEnum.TeamTask;

export type PageContextType = {
    pageName: PageNameTypes;
    updatePageName: (value: React.SetStateAction<PageNameTypes>) => void;
};

export const PageContext = createContext<PageContextType>({
    pageName: PageNameEnum.Collections,
    updatePageName: null!,
});
