import type { KeyValue } from "~/components/Common/types";
import { stringConstants } from "~/locale/stringConstants";
import type { Request, CorrelationAttributes } from "~/models/IRequest";
import type { ResponseType, SuccessResponse, TargetResponse } from "~/models/IResponse";
import { TelemetryServiceTrackTraceInformation } from "../telemetryUtils/telemetryUtils";
import { SeverityLevel } from "@empowerment/telemetry";

/* export const parseApiXServiceResponse = async (
    response: Response,
    fetchTelemetryContext: { [key: string]: unknown },
    duration: number // time it takes to make fetch() call to APIX Service
): Promise<ResponseType> => {
    if (response.status >= 200 && response.status < 300) {
        TelemetryServiceTrackEvent(
            TelemetryEvents.FetchApixRequest.Success,
            {
                ...fetchTelemetryContext,
                statusCode: response.status,
            },
            { Duration: duration }
        );
        // get l2o service response from ApiX service successfully
        const data = (await response.json()) as SuccessResponse;
        // Todo: (sivad) check exception for response.json()
        TelemetryServiceTrackEvent(
            TelemetryEvents.TargetServiceRequest.Success,
            {
                ...fetchTelemetryContext,
                httpStatusCode: data.targetResponse.httpStatusCode,
            },
            { Duration: duration }
        );
        return {
            statusCode: response.status,
            responseBody: {
                httpStatusCode: data.targetResponse.httpStatusCode,
                body: data.targetResponse.body,
                reasonPhrase: data.targetResponse.reasonPhrase,
            },
        };
    }

    // error case
    // case 1 - handled exception from ApiX Service, ApiX Service throws validation exception
    if (response.headers.get("Content-Type")?.includes("application/json")!) {
        const data = (await response.json()) as InvalidRequestFailureResponse;
        const handledErrorType = parseErrorType(response.status, true);

        TelemetryServiceTrackEvent(
            TelemetryEvents.FetchApixRequest.Error,
            {
                ...fetchTelemetryContext,
                statusCode: response.status,
                errorType: handledErrorType,
            },
            { Duration: duration }
        );

        return {
            statusCode: response.status,
            errorType: handledErrorType,
            responseBody: {
                Code: data.Code,
                Message: data.Message,
                Target: data.Target,
                Details: data.Details,
            },
        };
    }

    // case 2 - If we can't parse the error body, then its an unhandled exception from ApiX service
    const unhandledErrorType = parseErrorType(response.status, false);
    TelemetryServiceTrackEvent(
        TelemetryEvents.FetchApixRequest.Error,
        {
            ...fetchTelemetryContext,
            statusCode: response.status,
            errorType: unhandledErrorType,
        },
        { Duration: duration }
    );

    return {
        statusCode: response.status,
        errorType: unhandledErrorType,
        responseBody: response.statusText,
    };
}; */

/* //Todo: (sivad): sivad
export const parseApiXServiceResponse_New = async (
    response: Response,
    fetchTelemetryContext: { [key: string]: unknown },
    duration: number // time it takes to make fetch() call to APIX Service
): Promise<ResponseType> => {
    if (response.status >= 200 && response.status < 300) {
        TelemetryServiceTrackEvent(
            TelemetryEvents.FetchApixRequest.Success,
            {
                ...fetchTelemetryContext,
                statusCode: response.status,
            },
            { Duration: duration }
        );
        // get l2o service response from ApiX service successfully
        const data = (await response.json()) as SuccessResponse;
        // Todo: (sivad) check exception for response.json()
        TelemetryServiceTrackEvent(
            TelemetryEvents.TargetServiceRequest.Success,
            {
                ...fetchTelemetryContext,
                httpStatusCode: response.status,
            },
            { Duration: duration }
        );
        return {
            statusCode: response.status,
            responseBody: {
                httpStatusCode: response.status,
                body: data,
                reasonPhrase: "",
            },
        };
    }

    // error case
    // case 1 - handled exception from ApiX Service, ApiX Service throws validation exception
    if (response.headers.get("Content-Type")?.includes("application/json")!) {
        const data = (await response.json()) as InvalidRequestFailureResponse;
        const handledErrorType = parseErrorType(response.status, true);

        TelemetryServiceTrackEvent(
            TelemetryEvents.FetchApixRequest.Error,
            {
                ...fetchTelemetryContext,
                statusCode: response.status,
                errorType: handledErrorType,
            },
            { Duration: duration }
        );

        return {
            statusCode: response.status,
            errorType: handledErrorType,
            responseBody: {
                Code: data.Code,
                Message: data.Message,
                Target: data.Target,
                Details: data.Details,
            },
        };
    }

    // case 2 - If we can't parse the error body, then its an unhandled exception from ApiX service
    const unhandledErrorType = parseErrorType(response.status, false);
    TelemetryServiceTrackEvent(
        TelemetryEvents.FetchApixRequest.Error,
        {
            ...fetchTelemetryContext,
            statusCode: response.status,
            errorType: unhandledErrorType,
        },
        { Duration: duration }
    );

    return {
        statusCode: response.status,
        errorType: unhandledErrorType,
        responseBody: response.statusText,
    };
}; */

/* export async function MakeFetchRequest(requestObj: TargetRequest,
    rawUrl: string,
    cv: string,
    correlationId: string | undefined,
    pageName: PageNameEnum): Promise<MakeFetchResponse> {
    const fetchTelemetryContext = {
        page: pageName,
        url: rawUrl,
        MSCV: cv,
        correlationId: correlationId,
    };
    const acquireTokenStartTime = Date.now();

    let apixToken;
    try {
        apixToken = await AuthenticationService.acquireTokenForScope(appConfig().apixApiScope);
    } catch (e) {
        const error = e as Error;
        TelemetryService.trackException(error, fetchTelemetryContext, { Duration: Date.now() - acquireTokenStartTime });
        return {
            statusCode: 500,
            responseBody: error.message,
        };
    }

    TelemetryServiceTrackEvent(TelemetryEvents.FetchApixRequest.Initiated, {
        ...fetchTelemetryContext,
    });

    let headers = {
        "MS-CV": cv,
        Authorization: `Bearer ${apixToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    // Pass x-ms-correlation-id only when its defined
    if (correlationId !== undefined) {
        headers = { ...headers, ...{ "x-ms-correlation-id": correlationId } };
    }

    const fetchStartTime = Date.now();

    try {
        const response = await fetch(`https://localhost:44393/v1/webrequest/invoke`, { //${appConfig().apixApiBase}
            headers: headers,
            method: RequestType.POST,
            body: JSON.stringify({ targetRequest: requestObj }),
        });

        const parseResponseStartTime = Date.now();
        const duration = parseResponseStartTime - fetchStartTime; // time it takes to make fetch() call to APIX Service


        // Todo: (sivad) capture unhandled failure
        let parsedResponse: MakeFetchResponse = await parseApiXServiceResponse(
            response,
            fetchTelemetryContext,
            duration
        );
        parsedResponse = {
            ...parsedResponse,
            requestStartTimes: {
                sendRequestStartTime: -1,
                acquireTokenStartTime: acquireTokenStartTime,
                fetchStartTime: fetchStartTime,
                parseResponseStartTime: parseResponseStartTime,
                renderResponseStartTime: -1,
            },
        };
        return parsedResponse;
    } catch (e) {
        const parseResponseStartTime = Date.now();
        const error = e as Error;
        TelemetryService.trackException(error, fetchTelemetryContext, { Duration: Date.now() - fetchStartTime });
        return {
            statusCode: 500,
            errorType: "Unknown",
            responseBody: error.message,
            requestStartTimes: {
                sendRequestStartTime: -1,
                acquireTokenStartTime: acquireTokenStartTime,
                fetchStartTime: fetchStartTime,
                parseResponseStartTime: parseResponseStartTime,
                renderResponseStartTime: -1,
            },
        };
    }
} */

function findKeyInHeaders(request: Request, searchKey: string): boolean {
    if (request.headers !== null) {
        for (const header of request.headers ?? []) {
            if (header.key.toLowerCase() === searchKey) {
                return true;
            }
        }
    }
    return false;
}
const page = "responseUtils";
export const parseResponseMessage = (
    request: Request,
    response?: ResponseType,
    correlationAttributes?: CorrelationAttributes
): { visibleResponseBody: string; visibleResponseStatus?: string } => {
    let targetSuccessResponseBody: string;
    const fullResponseStatusCode = response?.statusCode;
    let targetSuccessResponseHttpStatus = "";
    let targetSuccessResponseHttpStatusCode = "";
    let targetSuccessResponseHttpReasonPharse = "";
    let mscv = "";
    if (correlationAttributes?.MSCV === undefined) {
        mscv = fetchValueFromKVPair(request.headers!, "MS-CV");
    } else {
        mscv = correlationAttributes.MSCV;
    }

    // Default: No status code
    if (fullResponseStatusCode === undefined) {
        return {
            visibleResponseBody: "",
        };
        // Success Case
    } else if (fullResponseStatusCode >= 200 && fullResponseStatusCode < 300) {
        //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const responseBody = (response?.responseBody as TargetResponse).body;
        TelemetryServiceTrackTraceInformation(`${page}.parseResponseMessage ${mscv}`, SeverityLevel.Information, {
            rawResponse: `${JSON.stringify(responseBody)}`,
        });
        const responseBodyStr = JSON.stringify(responseBody, null, 2);
        //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const responseBodyJson = JSON.parse(responseBodyStr);
        //console.log("responseBodyJson: ", responseBodyJson);
        //eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        const targetResponseBody = responseBodyJson.targetResponse.body;
        //console.log("targetResponseBody: ", targetResponseBody);
        //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
        let targetResponseBodyJson: unknown = "";
        let formattedJsonString: string = "";
        try {
            //let targetResponseBodyJson: string = ""; // Explicitly type targetResponseBodyJson as a string
            //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
            targetResponseBodyJson = JSON.parse(targetResponseBody);
            formattedJsonString = JSON.stringify(targetResponseBodyJson, null, 2);
        } catch (error) {
            targetResponseBodyJson = responseBody;
            formattedJsonString = JSON.stringify(responseBody);
            TelemetryServiceTrackTraceInformation(
                `${page}.parseResponseMessage ${mscv} - unable to parse json`,
                SeverityLevel.Error,
                { formattedJsonString: formattedJsonString, error: error }
            );
        }
        TelemetryServiceTrackTraceInformation(`${page}.parseResponseMessage  ${mscv}`, SeverityLevel.Information, {
            formattedJsonString: formattedJsonString,
        });

        if (responseBody === undefined) {
            targetSuccessResponseBody = `API Response had no info. Web Level Info: ${JSON.stringify(
                response?.responseBody
            )}`;
        } else {
            let targetSuccessResponse = responseBody as SuccessResponse;
            targetSuccessResponse.targetResponse.body = formattedJsonString;
            targetSuccessResponseBody = formattedJsonString;
            //targetSuccessResponse.targetResponse.body === "null"  ? ""  : JSON.stringify(targetSuccessResponse.targetResponse.body, null, 2);
            targetSuccessResponseHttpStatusCode = targetSuccessResponse.targetResponse.httpStatusCode.toString();
            targetSuccessResponseHttpReasonPharse = targetSuccessResponse.targetResponse.reasonPhrase ?? "";
            targetSuccessResponseHttpStatus = `Status: ${targetSuccessResponseHttpStatusCode} ${targetSuccessResponseHttpReasonPharse}`;
        }

        //easter egg to to switch to debug mode
        const isFullResponse = findKeyInHeaders(request, "withfullresponse");
        if (isFullResponse) {
            let targetSuccessResponse = responseBody as SuccessResponse;
            targetSuccessResponse.targetResponse.body = formattedJsonString;
            targetSuccessResponseBody = JSON.stringify(responseBody, null, 2);
            //targetSuccessResponseBody = JSON.stringify(responseBody, null, 2);
            targetSuccessResponseHttpStatus = `Status: ${(response?.responseBody as TargetResponse).httpStatusCode} ${
                (response?.responseBody as TargetResponse).reasonPhrase ?? ""
            }`;
        }
        return {
            visibleResponseBody: targetSuccessResponseBody,
            visibleResponseStatus: targetSuccessResponseHttpStatus,
        };
    }
    // Error Case
    // case 1 - when responseBody is of type string, it's 4xx/unhandled exception case > show unknown error
    // case 2 - when responseBody is of type JSON, it's a validation exception from ApiX
    let errorText = "";
    if (typeof response?.responseBody === "string") {
        errorText = ` (ErrorCode: ${response.responseBody})`;
    } else if (response?.responseBody !== undefined && "Message" in response.responseBody) {
        return {
            visibleResponseBody: response.responseBody.Message,
        };
    }

    const unknownErrorText = `${stringConstants.components.App.ErrorMessage.UnknownError} (MS-CV: ${mscv})${errorText}`;
    return {
        visibleResponseBody: unknownErrorText,
    };
};

export const fetchValueFromKVPair = (array: KeyValue[], key: string): string => {
    let retValue = "";
    array.forEach((kv) => {
        if (kv.key === key) {
            retValue = kv.value;
        }
    });
    return retValue;
};

/**
 * Determines error type
 * @param status error code
 * @param isHandledException if its a handled exception
 * @returns error type
 */
/* function parseErrorType(status: number, isHandledException: boolean): ErrorType {
    // We want to report AuthenticationError & Unknown as failed cases
    // ApiX ValidationError is a success case as it could be invalid URL etc.
    if (status === 401) {
        return "AuthenticationError";
    } else if (isHandledException) {
        return "ValidationError";
    }
    return "Unknown";
} */
