import { TelemetryService } from "@empowerment/telemetry";
import type { TextField as TextFieldClass } from "@harmony/enablers/components/text-field/text-field";
import type { ReactElement } from "react";
import { useContext, useMemo } from "react";
import styles from "./styles.module.scss";
import { KeyValueTable } from "~/components/Common/KeyValueTable";
import type { KeyValue } from "~/components/Common/types";
import type { Environment } from "~/models/Environment";
import { EnvironmentContext } from "~/models/Environment";
import { PageContext } from "~/models/Page";
import type { ITelemetryContext } from "~/models/TelemetryContext";

export function VariablePanel(): ReactElement {
    const { environmentList, selectedEnvironmentIndex, setEnvironmentList } = useContext(EnvironmentContext);
    const { pageName } = useContext(PageContext);

    const keyValueList: KeyValue[] | undefined = useMemo(
        () => environmentList[selectedEnvironmentIndex].variableList,
        [environmentList, selectedEnvironmentIndex]
    );
    const selectedEnvironmentName = useMemo(
        () => environmentList[selectedEnvironmentIndex].name,
        [environmentList, selectedEnvironmentIndex]
    );

    const telemetryContext: ITelemetryContext = {
        page: pageName,
    };

    const keyValueMap: Map<string, string> = useMemo(() => {
        const map = new Map<string, string>();
        environmentList[selectedEnvironmentIndex].variableList.forEach((keyValuePair) => {
            const key = keyValuePair.key;
            const value = keyValuePair.value;
            if (key !== "" && !map.has(key)) {
                map.set(key, value);
            }
        });
        return map;
    }, [environmentList, selectedEnvironmentIndex]);

    /**
     * Adds a row of key-value to the bottom
     */
    const onAdd = (): void => {
        const newEnvironmentList: Environment[] = [...environmentList];
        newEnvironmentList[selectedEnvironmentIndex].variableList.push({ key: "", value: "" });
        setEnvironmentList(newEnvironmentList);
        TelemetryService.trackInfo(
            `New key-value row added in Environment: ${selectedEnvironmentName} panel`,
            telemetryContext
        );
    };

    /**
     * Removes a row of key-value
     */
    const onRemove = (index: number): void => {
        const newEnvironmentList: Environment[] = [...environmentList];
        newEnvironmentList[selectedEnvironmentIndex].variableList.splice(index, 1);
        setEnvironmentList(newEnvironmentList);
        TelemetryService.trackInfo(
            `Key-value row removed from Environment: ${selectedEnvironmentName} panel`,
            telemetryContext
        );
    };

    /**
     * Updates the 'field' with 'newText' at specified 'index'
     */
    const onTextFieldUpdate = (newText: string, index: number, field: "key" | "value"): void => {
        const newEnvironmentList: Environment[] = [...environmentList];
        newEnvironmentList[selectedEnvironmentIndex].variableList[index][field] = newText;
        setEnvironmentList(newEnvironmentList);
    };

    /**
     * Validates keys do not have duplicates
     */
    const keyTextFieldValidation = (target: TextFieldClass): void => {
        const keyText = target.value;
        const keyExists = keyValueMap.has(keyText);
        if (keyExists) {
            // Duplicate variable
            target.setCustomValidity("Variable already exists");
        } else {
            // The control is valid, remove the error message
            target.setCustomValidity("");
        }
    };

    return (
        <div className={styles.root}>
            <KeyValueTable
                panelName="Variable"
                keyValueList={keyValueList}
                keyTextFieldLabel="Name"
                valueTextFieldLabel="Value"
                onAdd={onAdd}
                onRemove={onRemove}
                onTextFieldUpdate={onTextFieldUpdate}
                keyTextFieldValidation={keyTextFieldValidation}
            />
        </div>
    );
}
