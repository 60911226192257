import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import { dbStorage } from "~/services/DbRepository";
import { isDefined } from "~/utils/isDefined";

const USER_RECENTLY_USED_URLS = "apix_user_recently_used_urls";
const MAX_COUNT = 15;

export const addRecentlyUsedUrl = (url: string): string[] => {
    try {
        const urls = fetchRecentlyUsedUrls();
        if (!isDefined(urls)) {
            throw new Error("Not able to fetch user recently used urls");
        }
        //Todo: (sivad) we can add more complex logic later, like predefine most used url
        // check whether urls already has url value, only store new value
        if (!urls.includes(url)) {
            // only save last 15 url user has used
            if (urls.length >= MAX_COUNT) {
                //FIFO
                urls.shift();
            }
            urls.push(url);
            dbStorage.setItem<string[]>(USER_RECENTLY_USED_URLS, urls);
        }
        TelemetryService.trackTrace("Add recently used url into storage successfully", SeverityLevel.Information);
        return urls;
    } catch (error) {
        TelemetryService.trackTrace("Failed to add recently used url into storage", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
        return fetchRecentlyUsedUrls() ?? [];
    }
};

export const fetchRecentlyUsedUrls = (): string[] | null => {
    try {
        const result = dbStorage.getItem<string[]>(USER_RECENTLY_USED_URLS) ?? [];
        TelemetryService.trackTrace("Fetch recently used urls from storage successfully", SeverityLevel.Information);
        return result;
    } catch (error) {
        // Return null if fetch list run into error
        TelemetryService.trackTrace("Failed to fetch recently used urls from storage", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
        return null;
    }
};
