import { divider } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyDivider = scopeForReact(divider);

type DividerProps = {
    dividerStyles?: string;
    /** If omitted, aria-hidden will be set */
    orientation?: "horizontal" | "vertical";
    presentation?: boolean;
} & BaseEnablerAttributes;

function DividerComponent(props: DividerProps): ReactElement {
    const { orientation, presentation, dividerStyles, ...rest } = { ...props };
    return (
        <HarmonyDivider
            className={dividerStyles}
            presentation={presentation}
            orientation={orientation}
            {...rest}
        ></HarmonyDivider>
    );
}

export const Divider = typedMemo(DividerComponent);
