import type { ReactElement } from "react";
import { useEffect, useMemo, useContext } from "react";
import style from "./styles.module.scss";
import { RESPONSE_ROW_SIZE } from "~/components/Response/Constants";
import { Text } from "~/enabler/Text";
import { TextArea } from "~/enabler/TextArea";
import type { Request, RequestStartTimes, CorrelationAttributes } from "~/models/IRequest";
import type { ResponseType } from "~/models/IResponse";
import { PageContext } from "~/models/Page";
import { EventState, EventStatus } from "~/models/TelemetryContext";
import { isBusinessException } from "~/utils/errorUtils";
import { parseResponseMessage } from "~/utils/responseUtils/responseUtils";
import { TelemetryEvents, TelemetryServiceTrackEvent } from "~/utils/telemetryUtils";

type ResponseProps = {
    response?: ResponseType;
    request: Request;
    requestStartTimes: RequestStartTimes;
    correlationAttributes: CorrelationAttributes;
    targetRequestDispatched: boolean;
    setTargetRequestDispatched: React.Dispatch<React.SetStateAction<boolean>>;
};

export function Response(props: ResponseProps): ReactElement {
    const { MSCV, correlationId } = useMemo(() => props.correlationAttributes, [props.correlationAttributes]);
    const { visibleResponseBody: responseBody, visibleResponseStatus: responseStatus } = parseResponseMessage(
        props.request,
        props.response,
        props.correlationAttributes
    );

    const { pageName } = useContext(PageContext);

    const statusCode = useMemo(() => props.response?.statusCode, [props.response]);
    const errorType = useMemo(() => props.response?.errorType, [props.response]);
    const {
        sendRequestStartTime,
        acquireTokenStartTime,
        fetchStartTime,
        parseResponseStartTime,
        renderResponseStartTime,
    } = useMemo(() => props.requestStartTimes, [props.requestStartTimes]);

    const telemetryContext = useMemo(
        () => ({ page: pageName, url: props.request.url, MSCV: MSCV, correlationId: correlationId }),
        [pageName, props.request.url, MSCV, correlationId]
    );
    const requestDurations = useMemo(() => {
        const sendRequestEndTime = Date.now();
        return {
            dispatchDuration: acquireTokenStartTime - sendRequestStartTime, // time taken from clicking the "Send" button till acquireToken
            acquireTokenDuration: fetchStartTime - acquireTokenStartTime, // time it takes to acquireToken
            fetchDuration: parseResponseStartTime - fetchStartTime, // after getting token, time it takes  to make fetch() & get response
            parseResponseDuration: renderResponseStartTime - parseResponseStartTime, // after getting response, time it takes to prase the response
            renderResponseDuration: sendRequestEndTime - renderResponseStartTime, // after parsing the response, time it takes to render the response on UI
            Duration: sendRequestEndTime - sendRequestStartTime, // total duration from clicking "Send" button till response is rendered on UI
        };
    }, [sendRequestStartTime, acquireTokenStartTime, fetchStartTime, parseResponseStartTime, renderResponseStartTime]);

    const { requestStartTimes, targetRequestDispatched, setTargetRequestDispatched } = props;

    useEffect(() => {
        // only log these telemetry if Response is rendered as a result of dispatching targetRequest, not due to update in prop or context (e.g., pageName)
        if (statusCode !== undefined && targetRequestDispatched) {
            let eventStatus;
            if (statusCode >= 200 && statusCode < 300) {
                eventStatus = EventStatus.Success;
                TelemetryServiceTrackEvent(TelemetryEvents.Response.SuccessResponseProcessed, telemetryContext);
            } else {
                eventStatus = EventStatus.Failed;
                if (errorType !== undefined && isBusinessException(errorType)) {
                    eventStatus = EventStatus.Success; // do not count business exception as failure
                }
                TelemetryServiceTrackEvent(TelemetryEvents.Response.ErrorResponseProcessed, telemetryContext);
            }
            TelemetryServiceTrackEvent(
                TelemetryEvents.DispatchRequest.Completed,
                { ...telemetryContext, eventState: EventState.Completed, eventStatus: eventStatus },
                requestDurations
            );
            setTargetRequestDispatched(false);
        }
    }, [
        statusCode,
        errorType,
        telemetryContext,
        requestDurations,
        requestStartTimes,
        targetRequestDispatched,
        setTargetRequestDispatched,
    ]);

    // default / success case
    if (statusCode === undefined || (statusCode >= 200 && statusCode < 300)) {
        return (
            <div className={style.root}>
                <TextArea
                    className={style.textArea}
                    label="Response"
                    rows={RESPONSE_ROW_SIZE}
                    readonly={false}
                    value={responseBody}
                    helpText={responseStatus}
                    resize="auto"
                />
            </div>
        );
    }
    // error case
    return (
        <div className={style.errorRoot}>
            <Text tag="span" appearance="heading-6">
                Error
            </Text>
            <Text tag="span" appearance="paragraph">
                {responseBody}
            </Text>
        </div>
    );
}
