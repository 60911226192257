import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useContext, useCallback, useState, useEffect } from "react";
import { EnvironmentsPanelDialog } from "./components/EnvironmentsPanelDialog";
import { EnvironmentsPanelHeader } from "./components/EnvironmentsPanelHeader";
import styles from "./styles.module.scss";
import { TaskMenu } from "~/enabler/TaskMenu";
import type { TaskItemType } from "~/enabler/TaskMenu/TaskMenu";
import { EnvironmentContext } from "~/models/Environment";
import { TelemetryEvents, TelemetryServiceTrackEvent, TelemetryTaskFlows } from "~/utils/telemetryUtils";

const ENVIRONMENT_PREFIX = "environment_";

type EnvironmentActionType = "rename" | "delete" | undefined;

export function EnvironmentsPanel(): ReactElement {
    const { selectedEnvironmentIndex, environmentList, setSelectedEnvironmentIndex } = useContext(EnvironmentContext);

    const [actionType, setActionType] = useState<EnvironmentActionType>(undefined);
    const [enableEnvironmentActions, setEnableEnvironmentActions] = useState<boolean>(false);

    useEffect(() => {
        setEnableEnvironmentActions(selectedEnvironmentIndex > 0);
    }, [selectedEnvironmentIndex]);

    const menuItemOnClick = useCallback(
        (value: number) => {
            TelemetryService.trackTrace(
                `User selects ${value === 0 ? "Global" : "saved"} environment tab`,
                SeverityLevel.Information
            );
            //load environment variables here

            setSelectedEnvironmentIndex(value);
        },
        [setSelectedEnvironmentIndex]
    );

    const renameEnvironmentOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(
            TelemetryEvents.EnvironmentsPanelHeader.RenameEnvironmentDialogOpened,
            undefined,
            undefined,
            TelemetryTaskFlows.RenameEnvironment.FirstStep
        );
        setActionType("rename");
    }, []);

    const deleteEnvironmentOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(
            TelemetryEvents.EnvironmentsPanelHeader.DeleteEnvironmentDialogOpened,
            undefined,
            undefined,
            TelemetryTaskFlows.DeleteEnvironment.FirstStep
        );
        setActionType("delete");
    }, []);

    const closeDialog = useCallback(() => {
        if (actionType === "delete") {
            TelemetryServiceTrackEvent(TelemetryEvents.EnvironmentsPanelHeader.DeleteEnvironmentDialogClosed);
            setActionType(undefined);
        } else if (actionType === "rename") {
            TelemetryServiceTrackEvent(TelemetryEvents.EnvironmentsPanelHeader.RenameEnvironmentDialogClosed);
            setActionType(undefined);
        }
    }, [actionType]);

    const menuItems: TaskItemType[] = environmentList.map((environment, index) => ({
        value: environment.name,
        key: `${ENVIRONMENT_PREFIX}${index}_${environment.name}`,
        selected: selectedEnvironmentIndex === index,
        onClick: (): void => {
            menuItemOnClick(index);
        },
        className: selectedEnvironmentIndex === index ? styles.selectedTaskItem : styles.taskItem,
    }));

    return (
        <div className={styles.root}>
            <EnvironmentsPanelHeader
                renameEnvironmentOnClick={renameEnvironmentOnClick}
                deleteEnvironmentOnClick={deleteEnvironmentOnClick}
                enableEnvironmentActions={enableEnvironmentActions}
            />
            <TaskMenu items={menuItems} className={styles.taskMenu} />
            {actionType !== undefined && (
                <EnvironmentsPanelDialog
                    actionType={actionType}
                    closeDialog={closeDialog}
                    setActionType={setActionType}
                />
            )}
        </div>
    );
}
