import type { TelemetryEvent } from "@empowerment/telemetry";
import { TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useMemo, useState, useCallback, useContext } from "react";
import styles from "./styles.module.scss";
import { RequestPanelHeaderDialog } from "~/components/RequestPanelHeaderDialog";
import { defaultSystemData } from "~/Constants";
import { Button } from "~/enabler/Button";
import { Icon } from "~/enabler/Icon";
import { stringConstants } from "~/locale/stringConstants";
import type { ServiceResponse } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
import type { CreateBlobRequest, SystemData } from "~/models/IRequest";
import { RequestContext } from "~/models/IRequest";
import { PageContext } from "~/models/Page";
import { EventState, EventStatus } from "~/models/TelemetryContext";
import { upsertBlobToRepo } from "~/services/Repository/DataRepository";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";
import { getCorrespondingDataItem, splitItemIds } from "~/utils/DataUtils/DataUtils";
import { TelemetryEvents, TelemetryServiceTrackEvent, TelemetryTaskFlows } from "~/utils/telemetryUtils";
export type ButtonActionType = "save" | "rename" | "delete" | "reset" | "saveas";

export function RequestPanelHeader(): ReactElement {
    const { selectedCollectionIndex, selectedRequestIndex, collectionList } = useContext(CollectionContext); //setCollectionList
    const { request } = useContext(RequestContext); //, updateInitialRequest, requestName , updateRequest
    const { pageName } = useContext(PageContext);
    const { user } = useAuthenticationContext();
    const [actionType, setActionType] = useState<ButtonActionType | undefined>(undefined);

    const exceptionTelemetryContext = useMemo(
        () => ({
            selectedCollectionIndex: selectedCollectionIndex,
            selectedRequestIndex: selectedRequestIndex,
            page: pageName,
            url: request.url,
            MSCV: request.headers?.find((elem) => elem.key === stringConstants.components.App.RequestPanel.MSCV)?.value,
            correlationId: request.headers?.find(
                (elem) => elem.key === stringConstants.components.App.RequestPanel.correlationId
            )?.value,
        }),
        [selectedCollectionIndex, selectedRequestIndex, pageName, request.url, request.headers]
    );

    const closeDialog = useCallback(() => {
        let closeDialogEvent: TelemetryEvent | undefined;
        switch (actionType) {
            case "save":
                closeDialogEvent = TelemetryEvents.RequestPanelHeader.SaveRequestDialogClosed;
                break;
            case "rename":
                closeDialogEvent = TelemetryEvents.RequestPanelHeader.RenameRequestDialogClosed;
                break;
            case "delete":
                closeDialogEvent = TelemetryEvents.RequestPanelHeader.DeleteRequestDialogClosed;
                break;
            default:
                // "reset" case
                closeDialogEvent = TelemetryEvents.RequestPanelHeader.ResetRequestDialogClosed;
        }
        TelemetryServiceTrackEvent(closeDialogEvent);
        setActionType(undefined);
    }, [actionType]);

    const openDialog = useCallback((buttonActionType: ButtonActionType) => {
        switch (buttonActionType) {
            case "saveas":
                TelemetryServiceTrackEvent(
                    TelemetryEvents.RequestPanelHeader.SaveRequestDialogOpened,
                    undefined,
                    undefined,
                    TelemetryTaskFlows.SaveRequest.FirstStep
                );
                setActionType("saveas");
                break;
            case "save":
                TelemetryServiceTrackEvent(
                    TelemetryEvents.RequestPanelHeader.SaveRequestDialogOpened,
                    undefined,
                    undefined,
                    TelemetryTaskFlows.SaveRequest.FirstStep
                );
                setActionType("save");
                break;
            case "rename":
                TelemetryServiceTrackEvent(
                    TelemetryEvents.RequestPanelHeader.RenameRequestDialogOpened,
                    undefined,
                    undefined,
                    TelemetryTaskFlows.RenameRequest.FirstStep
                );
                setActionType("rename");
                break;
            case "delete":
                TelemetryServiceTrackEvent(
                    TelemetryEvents.RequestPanelHeader.DeleteRequestDialogOpened,
                    undefined,
                    undefined,
                    TelemetryTaskFlows.DeleteRequest.FirstStep
                );
                setActionType("delete");
                break;
            default:
                TelemetryServiceTrackEvent(
                    TelemetryEvents.RequestPanelHeader.ResetRequestDialogOpened,
                    undefined,
                    undefined,
                    TelemetryTaskFlows.ResetRequest.FirstStep
                );
                setActionType("reset");
                break;
        }
    }, []);

    //TODO (sivad): same in collecton panel when navigating the tree and alert for save dialog.
    const onDirectlySaveRequest = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestPanelHeader.SaveRequestButtonClicked, {
            collectionIndex: selectedCollectionIndex,
            requestIndex: selectedRequestIndex,
            page: pageName,
            eventState: EventState.Initiated,
        });
        const saveRequestStartTime = Date.now();
        let eventStatus;
        try {
            setActionType(undefined);
            if (request.systemdata === undefined) {
                request.systemdata = defaultSystemData;
            }
            let alias = user?.email;
            alias = user?.email.substring(0, user.email.indexOf("@"));
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            alias = alias === undefined ? "" : alias;
            request.systemdata.lastSavedBy = alias === null ? "" : alias;
            const now: Date = new Date();
            request.systemdata.lastSavedOn = now.toISOString();

            const selectedItemId = splitItemIds(selectedRequestIndex).itemId;
            const createBlobRequest: CreateBlobRequest = {
                fileName: `${selectedItemId}.json`,
                content: `${JSON.stringify(structuredClone(request))}`,
            };

            upsertBlobToRepo(createBlobRequest)
                .then((serviceResponse: ServiceResponse | undefined) => {
                    if (serviceResponse !== undefined) {
                        //Todo: (sivad): guess nothing much to do other than error checks
                        console.log(`in RPH-saveRequest success ${serviceResponse.id}, ${serviceResponse.result}`);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - saveRequestStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.RequestPanelHeader.SaveRequestButtonClicked,
            {
                collectionIndex: selectedCollectionIndex,
                requestIndex: selectedRequestIndex,
                page: pageName,
                eventState: EventState.Completed,
                eventStatus: eventStatus,
            },
            { Duration: Date.now() - saveRequestStartTime }
        );
    }, [
        selectedCollectionIndex,
        selectedRequestIndex,
        pageName,
        request,
        // requestName,
        //setCollectionList,
        //updateInitialRequest,
        exceptionTelemetryContext,
        user,
    ]);

    const openSaveAsRequestDialog = useCallback(() => {
        openDialog("saveas");
    }, [openDialog]);

    const openSaveRequestDialog = useCallback(() => {
        openDialog("save");
    }, [openDialog]);

    const openRenameRequestDialog = useCallback(() => {
        openDialog("rename");
    }, [openDialog]);

    const openDeleteRequestDialog = useCallback(() => {
        openDialog("delete");
    }, [openDialog]);

    const openResetRequestDialog = useCallback(() => {
        openDialog("reset");
    }, [openDialog]);

    const dataItem = getCorrespondingDataItem(selectedCollectionIndex, selectedRequestIndex, collectionList);
    let isLocked = false;
    try {
        isLocked = dataItem?.category.toLowerCase() === "locked" ? true : false;
    } catch (e) {
        console.log("Error in RequestPanelHeader.tsx: ", e);
    }

    return (
        <>
            <div className={styles.root}>
                <Button
                    id={stringConstants.components.Common.Request.SaveRequest}
                    appearance="command"
                    // directly save request if user selects a request
                    onClick={selectedRequestIndex === "" ? openSaveRequestDialog : onDirectlySaveRequest}
                    disabled={isLocked}
                >
                    <Icon name="save" slot="start" />
                    {stringConstants.components.Common.Request.SaveRequest}
                </Button>
                <Button
                    id={stringConstants.components.Common.Request.SaveAsRequest}
                    appearance="command"
                    onClick={openSaveAsRequestDialog}
                >
                    <Icon name="save" slot="start" />
                    {stringConstants.components.Common.Request.SaveAsRequest}
                </Button>
                {/* Only show rename and delete button if user selects a request */}
                {selectedRequestIndex !== "" && splitItemIds(selectedRequestIndex).itemType !== "folder" && (
                    <>
                        <Button
                            id={stringConstants.components.Common.Request.RenameRequest}
                            appearance="command"
                            onClick={openRenameRequestDialog}
                        >
                            <Icon name="edit" slot="start" />
                            {stringConstants.components.Common.Request.RenameRequest}
                        </Button>
                        <Button
                            id={stringConstants.components.Common.Request.DeleteRequest}
                            appearance="command"
                            onClick={openDeleteRequestDialog}
                            disabled={isLocked}
                        >
                            <Icon name="delete" slot="start" />
                            {stringConstants.components.Common.Request.DeleteRequest}
                        </Button>
                    </>
                )}
                <Button
                    id={stringConstants.components.Common.Request.ResetRequest}
                    appearance="command"
                    onClick={openResetRequestDialog}
                    disabled={isLocked}
                >
                    <Icon name="sync" slot="start" />
                    {stringConstants.components.Common.Request.ResetRequest}
                </Button>
            </div>
            {actionType !== undefined && (
                <RequestPanelHeaderDialog
                    exceptionTelemetryContext={exceptionTelemetryContext}
                    actionType={actionType}
                    closeDialog={closeDialog}
                    setActionType={setActionType}
                />
            )}
        </>
    );
}
