// Suffixes of task flow
const FirstStep = ": First Step";
const LastStep = ": Last Step";

/**
 * Describes the first & last step of the task flows available in PMOW for Task Success HEART metric
 */
export const TelemetryTaskFlows = {
    /**
     * Save Request flow
     */
    SaveRequest: {
        FirstStep: `Save Request${FirstStep}`,
        LastStep: `Save Request${LastStep}`,
    },
    /**
     * Rename Request flow
     */
    RenameRequest: {
        FirstStep: `Rename Request${FirstStep}`,
        LastStep: `Rename Request${LastStep}`,
    },
    /**
     * Delete Request flow
     */
    DeleteRequest: {
        FirstStep: `Delete Request${FirstStep}`,
        LastStep: `Delete Request${LastStep}`,
    },
    /**
     * Reset Request flow
     */
    ResetRequest: {
        FirstStep: `Reset Request${FirstStep}`,
        LastStep: `Reset Request${LastStep}`,
    },
    /**
     * Save Environment flow
     */
    AddEnvironment: {
        FirstStep: `Save Environment${FirstStep}`,
        LastStep: `Save Environment${LastStep}`,
    },
    /**
     * Rename Environment flow
     */
    RenameEnvironment: {
        FirstStep: `Rename Environment${FirstStep}`,
        LastStep: `Rename Environment${LastStep}`,
    },
    /**
     * Delete Environment flow
     */
    DeleteEnvironment: {
        FirstStep: `Delete Environment${FirstStep}`,
        LastStep: `Delete Environment${LastStep}`,
    },
};
