import type { ResponseType, ErrorType, InvalidRequestFailureResponse, SuccessResponse } from "~/models/IResponse";

export const getHTTPResponse = async (
    response: Response,
    fetchTelemetryContext: { [key: string]: unknown },
    duration: number // time it takes to make fetch() call to Service
): Promise<ResponseType> => {
    if (response.status >= 200 && response.status < 300) {
        const data = (await response.json()) as SuccessResponse;
        //remove secrets
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.targetRequest !== undefined) {
            let authHeader = data.targetRequest.headers?.find((item) => item.key === "authorization");
            if (authHeader !== undefined) {
                authHeader.value = ["bearer removedToken"];
            }
            authHeader = data.targetResponse.headers?.find((item) => item.key === "authorization");
            //if (authHeader !== undefined) {authHeader.value = ["bearer removedToken"];}
        }
        //console.log(`response code=${response.status}, data=${dataJson}`);
        //Todo: (sivad): there is a error that is showing up after this? as 500 status (Scenario : item save)
        //Todo: (sivad) check exception for response.json()
        return {
            statusCode: response.status,
            duration: duration,
            responseBody: {
                httpStatusCode: response.status,
                body: data,
                reasonPhrase: "",
            },
        };
    }

    // error case
    // case 1 - handled exception from  Service,  Service throws validation exception
    if (response.headers.get("Content-Type")?.includes("application/json")!) {
        const data = (await response.json()) as InvalidRequestFailureResponse;
        const handledErrorType = parseErrorType(response.status, true);
        return {
            statusCode: response.status,
            duration: duration,
            errorType: handledErrorType,
            responseBody: {
                Code: data.Code,
                Message: data.Message,
                Target: data.Target,
                Details: data.Details,
            },
        };
    }

    // case 2 - If we can't parse the error body, then its an unhandled exception from  service
    const unhandledErrorType = parseErrorType(response.status, false);
    return {
        statusCode: response.status,
        duration: duration,
        errorType: unhandledErrorType,
        responseBody: response.statusText,
    };
};

function parseErrorType(status: number, isHandledException: boolean): ErrorType {
    // We want to report AuthenticationError & Unknown as failed cases
    // ValidationError is a success case as it could be invalid URL etc.
    if (status === 401) {
        return "AuthenticationError";
    } else if (isHandledException) {
        return "ValidationError";
    }
    return "Unknown";
}
