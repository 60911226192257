import type TreeItem from "@harmony/enablers/components/tree-item/tree-item";
import { tree } from "@harmony/enablers/react";
import type { ReactElement, ReactNode } from "react";
import { useCallback } from "react";
import { scopeForReact } from "~/enabler/scope";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyTree = scopeForReact(tree);

type TreeProps = {
    onSelectionChange: (selection: TreeItem[]) => void;
    selection: tree["selection"];
    children?: JSX.Element[] | ReactNode;
    styles?: string;
};

function TreeComponent(props: TreeProps): ReactElement {
    const { children, selection, styles, onSelectionChange } = { ...props };

    const onTreeItemSelectionChange = useCallback(
        (
            event: CustomEvent<{
                selection: TreeItem[];
            }>
        ) => {
            onSelectionChange(event.detail.selection);
        },
        [onSelectionChange]
    );

    return (
        <HarmonyTree selection={selection} className={styles} onHeSelectionChange={onTreeItemSelectionChange}>
            {children}
        </HarmonyTree>
    );
}

export const Tree = typedMemo(TreeComponent);
