import type { ReactElement } from "react";
import styles from "./styles.module.scss";
import { EditPanel } from "../EditPanel";
import { ListPanel } from "../ListPanel";

export function TeamTaskContent(): ReactElement {
    return (
        <div className={styles.root}>
            <div />
            <ListPanel />
            <EditPanel />
        </div>
    );
}
