import type { IconNames } from "@harmony/enablers/components/icon/icon-names";
import { button } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import type { ButtonAppearance } from "~/enabler/Button";
import { Icon } from "~/enabler/Icon";
import { scopeForReact } from "~/enabler/scope";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyDropDownButton = scopeForReact(button);

type DropDownButtonProps = {
    caret?: boolean;
    slot: string;
    message?: string;
    iconStyle?: string;
    iconName?: IconNames;
    styles?: string;
    isDisable?: boolean;
    appearance?: ButtonAppearance;
    iconSlot?: string;
    name?: string; // need this value passed in for the event name to show up for Click Analytics; otherwise, event name will show up as 'not_specified'
};

function DropDownButtonComponent(props: DropDownButtonProps): ReactElement {
    return (
        <HarmonyDropDownButton
            disabled={props.isDisable}
            className={props.styles}
            slot={props.slot}
            caret={props.caret}
            appearance={props.appearance}
            name={props.name}
        >
            {props.iconName !== undefined && (
                <Icon iconStyle={props.iconStyle} name={props.iconName} slot={props.iconSlot} />
            )}
            {props.message !== undefined && props.message}
        </HarmonyDropDownButton>
    );
}

export const DropDownButton = typedMemo(DropDownButtonComponent);
