import type { ReactElement } from "react";
import {
    //useMemo,
    useCallback,
    useContext,
} from "react";
import style from "./styles.module.scss";
import { REQUEST_ROW_SIZE, VALIDATION_MESSAGE } from "~/components/BodyTab/Constants";
//import { KeyValueTable } from "~/components/Common/KeyValueTable";
import type { KeyValue } from "~/components/Common/types";
//import { RadioGroup } from "~/enabler/RadioGroup";
import { TextArea } from "~/enabler/TextArea";
//import type { RequestBodyTypes } from "~/models/IRequest";
import {
    //BodyRadioEnum,
    RequestContext,
} from "~/models/IRequest";
import { isValidJsonString } from "~/utils/validateUtils";

export function BodyTab(): ReactElement {
    const { request, updateRequest } = useContext(RequestContext);

    /*const radioButtons = [
        {
            label: BodyRadioEnum.Raw,
            value: BodyRadioEnum.Raw,
        },
        {
            label: BodyRadioEnum.FormData,
            value: BodyRadioEnum.FormData,
        },
    ];

    const keyValueObj: KeyValue = useMemo(() => ({ key: "", value: "" }), []);

    const onRadioChange = useCallback(
        (value: string) => {
            // set to raw, convert value to string, convert to form-data, set with 3 empty KeyValue[]?
            const keyValueDefault: KeyValue[] = Array.from({ length: 3 }).map(() => ({ ...keyValueObj }));
            const convertedValue = value === BodyRadioEnum.Raw ? "" : keyValueDefault;
            const convertedFormat = value === BodyRadioEnum.Raw ? "JSON" : "";
            updateRequest((prev) => ({
                ...prev,
                body: {
                    ...prev.body,
                    type: value as RequestBodyTypes,
                    format: convertedFormat,
                    values: convertedValue,
                },
            }));
        },
        [keyValueObj, updateRequest]
    );*/

    const onKeyValueChange = useCallback(
        (newValue: KeyValue[] | string) => {
            updateRequest((prev) => ({
                ...prev,
                body: { ...prev.body!, values: newValue },
            }));
        },
        [updateRequest]
    );

    return (
        <div className={style.root}>
            {/*<RadioGroup
                value={request.body?.type as BodyRadioEnum}
                radioButtons={radioButtons}
                onRadioChange={onRadioChange}
                className={style.radioGroup}
    />*/}
            <TextArea
                className={style.textArea}
                label="Request"
                rows={REQUEST_ROW_SIZE}
                onChange={onKeyValueChange}
                validate={isValidJsonString}
                validationMessage={VALIDATION_MESSAGE}
                value={request.body?.values !== undefined ? (request.body.values as string) : ""}
                resize="none"
            />

            {/*request.body?.type === BodyRadioEnum.Raw ? (
                <TextArea
                    label="Request"
                    rows={REQUEST_ROW_SIZE}
                    onChange={onKeyValueChange}
                    validate={isValidJsonString}
                    validationMessage={VALIDATION_MESSAGE}
                />
            ) : (
                <KeyValueTable />
            )*/}
        </div>
    );
}
