import type { CalloutPlacement } from "@harmony/enablers/components/callout/callout";
import { hoverCard } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyHoverCard = scopeForReact(hoverCard);

type HoverCardProps = {
    heading?: string;
    children: ReactElement;
    placement?: CalloutPlacement;
    fixedPlacement?: boolean;
} & BaseEnablerAttributes;

function HoverCardComponent(props: HoverCardProps): ReactElement {
    const { heading, children, ...rest } = { ...props };
    return (
        <HarmonyHoverCard heading={heading} {...rest}>
            {children}
        </HarmonyHoverCard>
    );
}

export const HoverCard = typedMemo(HoverCardComponent);
