import type { ReactElement } from "react";
import { useState, useContext, useMemo, useEffect } from "react";
import styles from "./styles.module.scss";
import { RequestInfo } from "~/components/RequestInfo";
import { RequestTabs } from "~/components/RequestTabs";
import { Response } from "~/components/Response";
import { ProgressRing } from "~/enabler/ProgressRing";
import { CollectionContext } from "~/models/Collection";
import type { RequestStartTimes, CorrelationAttributes } from "~/models/IRequest";
import { RequestContext } from "~/models/IRequest";
import type { ResponseType } from "~/models/IResponse";

export function RequestPanel(): ReactElement {
    const startTimes = useMemo(
        () => ({
            sendRequestStartTime: -1,
            acquireTokenStartTime: -1,
            fetchStartTime: -1,
            parseResponseStartTime: -1,
            renderResponseStartTime: -1,
        }),
        []
    );

    const { request } = useContext(RequestContext);
    const { selectedCollectionIndex, selectedRequestIndex } = useContext(CollectionContext);

    const [showProgress, setShowProgress] = useState(false);
    const [response, setResponse] = useState<ResponseType | undefined>(undefined);
    const [requestStartTimes, setRequestStartTimes] = useState<RequestStartTimes>(startTimes);
    const [correlationAttributes, setCorrelationAttributes] = useState<CorrelationAttributes>({
        MSCV: "",
        correlationId: undefined,
    });
    const [targetRequestDispatched, setTargetRequestDispatched] = useState(false); // state to determine if target request has been dispatched. Used to properly log telemetry
    useEffect(() => {
        setResponse(undefined);
    }, [selectedCollectionIndex, selectedRequestIndex]);

    return (
        <>
            <div className={styles.headerContainer}>
                <RequestInfo
                    setResponse={setResponse}
                    setShowProgress={setShowProgress}
                    setCorrelationAttributes={setCorrelationAttributes}
                    setTargetRequestDispatched={setTargetRequestDispatched}
                    setRequestStartTimes={setRequestStartTimes}
                    showProgress={showProgress}
                    selectedRequestIndex={selectedRequestIndex}
                />
            </div>
            <RequestTabs />
            <div className={styles.rootLine}>
                <div className={styles.line} />
            </div>
            {/* <ResponseTabs /> */}
            <div className={styles.responseContainer}>
                {showProgress && <ProgressRing className={styles.progressRing} indeterminate={true} />}
                <Response
                    response={response}
                    request={request}
                    requestStartTimes={requestStartTimes}
                    correlationAttributes={correlationAttributes}
                    targetRequestDispatched={targetRequestDispatched}
                    setTargetRequestDispatched={setTargetRequestDispatched}
                />
            </div>
        </>
    );
}
