import type { DropdownPositionValues } from "@harmony/enablers/components/dropdown/dropdown";
import type { IconNames } from "@harmony/enablers/components/icon/icon-names";
import { dropdown, menu, menuItem } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { useCallback, Fragment } from "react";
import type { ButtonAppearance } from "~/enabler/Button";
import { DropDownButton } from "~/enabler/DropDownButton";
import { scopeForReact } from "~/enabler/scope";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyDropDown = scopeForReact(dropdown);
const HarmonyMenu = scopeForReact(menu);
const HarmonyMenuItem = scopeForReact(menuItem);

export type MenuItemType<Value extends string> = {
    value: Value;
};
export type DropDownProps<Value extends string> = {
    // props for harmony dropdown
    id?: string;
    dropdownStyles?: string;
    fixedPlacement?: boolean;
    position?: DropdownPositionValues;
    // props for button
    message?: string;
    caret?: boolean;
    dropdownButtonStyles?: string;
    iconStyle?: string;
    iconSlot?: string;
    iconName?: IconNames;
    isDisable?: boolean;
    name?: string; // need this value passed in for the event name to show up for Click Analytics; otherwise, event name will show up as 'not_specified'
    buttonAppearance?: ButtonAppearance;
    // props for menu
    items: MenuItemType<Value>[];
    menuItemOnClick?: (value: string) => void;
    menuStyles?: string;
    menuItemStyles?: string;
};

function DropDownComponent<Value extends string>(props: DropDownProps<Value>): ReactElement {
    const {
        id,
        dropdownStyles,
        fixedPlacement,
        position,
        dropdownButtonStyles,
        buttonAppearance,
        menuItemOnClick,
        items,
        menuStyles,
        menuItemStyles,
        ...rest
    } = props;
    const onHeChange = useCallback(
        (event: CustomEvent<unknown>) => {
            if (menuItemOnClick !== undefined) {
                const value = event.target as HTMLElement;
                menuItemOnClick(value.textContent!);
            }
        },
        [menuItemOnClick]
    );

    return (
        <HarmonyDropDown id={id} className={dropdownStyles} fixedPlacement={fixedPlacement} position={position}>
            <DropDownButton styles={dropdownButtonStyles} slot="trigger" appearance={buttonAppearance} {...rest} />

            <HarmonyMenu className={menuStyles}>
                {items.map((item) => (
                    <Fragment key={item.value}>
                        <HarmonyMenuItem className={menuItemStyles} key={item.value} onHeChange={onHeChange}>
                            {item.value}
                        </HarmonyMenuItem>
                    </Fragment>
                ))}
            </HarmonyMenu>
        </HarmonyDropDown>
    );
}

export const DropDown = typedMemo(DropDownComponent);
