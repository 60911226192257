import { TelemetryService } from "@empowerment/telemetry";
import { isDefined } from "~/utils/isDefined";
import { TelemetryEvents } from "~/utils/telemetryUtils";

class DbStorage {
    public setItem<T>(key: string, value: T): void {
        TelemetryService.trackEvent(TelemetryEvents.SetItemIntoRequestStorage.Initiated, { itemKey: key });
        const startTime = Date.now();
        try {
            // Add item to storage
            localStorage.setItem(key, JSON.stringify(value));
            const duration = Date.now() - startTime;
            TelemetryService.trackEvent(
                TelemetryEvents.SetItemIntoRequestStorage.Success,
                { itemKey: key },
                { Duration: duration }
            );
        } catch (error) {
            const duration = Date.now() - startTime;
            TelemetryService.trackEvent(
                TelemetryEvents.SetItemIntoRequestStorage.Error,
                { itemKey: key },
                { Duration: duration }
            );
            TelemetryService.trackException(error as Error, { itemKey: key }, { duration });
        }
    }

    public removeItem(key: string): void {
        TelemetryService.trackEvent(TelemetryEvents.RemoveItemFromRequestStorage.Initiated, { itemKey: key });
        const startTime = Date.now();
        try {
            localStorage.removeItem(key);
            const duration = Date.now() - startTime;
            TelemetryService.trackEvent(
                TelemetryEvents.RemoveItemFromRequestStorage.Success,
                { itemKey: key },
                { Duration: duration }
            );
        } catch (error) {
            const duration = Date.now() - startTime;
            TelemetryService.trackEvent(
                TelemetryEvents.RemoveItemFromRequestStorage.Error,
                { itemKey: key },
                { Duration: duration }
            );
            TelemetryService.trackException(error as Error, { itemKey: key }, { duration });
        }
    }

    public getItem<T>(key: string): T | null {
        TelemetryService.trackEvent(TelemetryEvents.GetItemFromRequestStorage.Initiated, { itemKey: key });
        const startTime = Date.now();
        try {
            // Return value if the key exists
            const valueString: string | null = localStorage.getItem(key);
            const duration = Date.now() - startTime;
            TelemetryService.trackEvent(
                TelemetryEvents.GetItemFromRequestStorage.Success,
                { itemKey: key },
                { Duration: duration }
            );
            if (isDefined(valueString)) {
                return JSON.parse(valueString) as T;
            }
            // Return null if item does not exist
            return null;
        } catch (error) {
            const duration = Date.now() - startTime;
            TelemetryService.trackEvent(
                TelemetryEvents.GetItemFromRequestStorage.Error,
                { itemKey: key },
                { Duration: duration }
            );
            TelemetryService.trackException(error as Error, { itemKey: key }, { duration });
            return null;
        }
    }
}

let dbStorage: DbStorage;

export const initializeStorage = (): void => {
    dbStorage = new DbStorage();
};

export { dbStorage };
