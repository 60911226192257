export function isValidJsonString(str: string | undefined | null): boolean {
    if (str === undefined || str === null) {
        return false;
    }
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}
