import type { ButtonAppearance as HarmonyButtonAppearance } from "@harmony/enablers/components/button/button";
import { button } from "@harmony/enablers/react";
import type { ReactElement, ReactNode } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyButton = scopeForReact(button);
export type ButtonAppearance = HarmonyButtonAppearance;

type ButtonProps = {
    children: ReactNode;
    /** @default "secondary" */
    appearance?: ButtonAppearance;
    onClick?: () => void | Promise<void>;
    disabled?: boolean;
    slot?: string;
    caret?: boolean;
    name?: string; // need this value passed in for the event name to show up for Click Analytics; otherwise, event name will show up as 'not_specified'
} & BaseEnablerAttributes;

function ButtonComponent(props: ButtonProps): ReactElement {
    const { appearance, children, ...rest } = props;
    return (
        <HarmonyButton appearance={appearance ?? "secondary"} {...rest}>
            {children}
        </HarmonyButton>
    );
}

export const Button = typedMemo(ButtonComponent);
