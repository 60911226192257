import { text } from "@harmony/enablers/react";
import type { ReactElement, ReactNode } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyText = scopeForReact(text);

type TextProps = {
    // Remove duplicated appearances to avoid confusion
    appearance: Exclude<text["appearance"], "small">;
    children: ReactNode;
} & (
    | {
          tag: "span";
          /** To use margin, you must use a tag other than span */
          margin?: never;
      }
    | {
          tag: Exclude<text["tag"], "span">;
          margin?: "sm" | "md" | "lg";
      }
) &
    BaseEnablerAttributes;

function TextComponent(props: TextProps): ReactElement {
    const { children, ...rest } = props;
    return <HarmonyText {...rest}>{children}</HarmonyText>;
}

export const Text = typedMemo(TextComponent);
