import type { IconNames } from "@harmony/enablers/components/icon/icon-names";
import { icon } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyIcon = scopeForReact(icon);
export type HarmonyIconNames = IconNames;

type IconProps = {
    name: IconNames;
    /** If omitted, aria-hidden will be set */
    label?: string;
    iconStyle?: string;
    slot?: string;
} & BaseEnablerAttributes;

function IconComponent(props: IconProps): ReactElement {
    const { label, iconStyle, slot, name, ...rest } = { ...props };
    return <HarmonyIcon className={iconStyle} slot={slot} name={name} label={label} {...rest}></HarmonyIcon>;
}

export const Icon = typedMemo(IconComponent);
