import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
//import { deleteFileInIndex } from "../blobUtils/blobUtils";
// eslint-disable-next-line  @typescript-eslint/no-restricted-imports
import { recursivelyFindItemById, splitItemIds } from "../DataUtils/DataUtils";
import { defaultCollection, defaultCollectionName } from "~/Constants";
import type { Collection } from "~/models/Collection";
import type { Request } from "~/models/IRequest";
import { dbStorage } from "~/services/DbRepository";
import { getIndexFromRepo } from "~/services/Repository/DataRepository";
import { isDefined } from "~/utils/isDefined";

const COLLECTION_LIST_KEY = "apix_collectionList";

//only in tests
export const renameCollection = (collectionIndex: string, newCollectionName: string): void => {
    try {
        const collectionList = fetchCollectionList();

        if (!isDefined(collectionList)) {
            throw new Error("Not able to fetch collection list");
        }

        //collectionList[collectionIndex].name = newCollectionName;
        //const selectedCollection = collectionList.find(item => item.id === collectionIndex);
        const selectedCollection = collectionList.find((item) => item.id === splitItemIds(collectionIndex).itemId);
        if (selectedCollection !== undefined) {
            selectedCollection.name = newCollectionName;
        }
        dbStorage.setItem<Collection[]>(COLLECTION_LIST_KEY, collectionList);
        TelemetryService.trackTrace("Rename collection name successfully", SeverityLevel.Information, {
            collectionIndex: collectionIndex,
            newCollectionName: newCollectionName,
        });
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to rename collection - CollectionIndex: ${collectionIndex}`,
            SeverityLevel.Information,
            {
                collectionIndex: collectionIndex,
                newCollectionName: newCollectionName,
            }
        );
        TelemetryService.trackException(error as Error, {
            collectionIndex: collectionIndex,
            newCollectionName: newCollectionName,
        });
    }
};

//only in test
export const deleteCollection = (collectionIndex: string): void => {
    try {
        const collectionList = fetchCollectionList();
        if (!isDefined(collectionList)) {
            throw new Error("Not able to fetch collection list");
        }
        //Todo: (sivad): manage delete
        //collectionList.splice(collectionIndex, 1);
        dbStorage.setItem<Collection[]>(COLLECTION_LIST_KEY, collectionList);
        TelemetryService.trackTrace("Delete collection successfully", SeverityLevel.Information, {
            collectionIndex: collectionIndex,
        });
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to delete collection - CollectionIndex: ${collectionIndex}`,
            SeverityLevel.Information,
            {
                collectionIndex: collectionIndex,
            }
        );
        TelemetryService.trackException(error as Error, {
            collectionIndex: collectionIndex,
        });
    }
};

/* //only in test
export const addNewCollection = (): void => {
    try {
        const collectionList = fetchCollectionList();
        console.log(`in colleciton utils addnew collection ; collectionLIst = ${  collectionList}`);
        if (!isDefined(collectionList)) {
            throw new Error("Not able to fetch collection list");
        }
        collectionList.push(structuredClone(defaultCollection));
        dbStorage.setItem<Collection[]>(COLLECTION_LIST_KEY, collectionList);
        TelemetryService.trackTrace("Add new collection to storage successfully", SeverityLevel.Information);
    } catch (error) {
        TelemetryService.trackTrace("Failed to add new collection", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
    }
};  */

/* export const addNewFolder = (): void => {
    try {
        const collectionList = fetchCollectionList();
        if (!isDefined(collectionList)) {
            throw new Error("Not able to fetch folder list");
        }
        collectionList[1].items.push(structuredClone(defaultFolder));
        //collectionList.push(structuredClone(defaultFolder));
        dbStorage.setItem<Collection[]>(COLLECTION_LIST_KEY, collectionList);
        TelemetryService.trackTrace("Add new folder to storage successfully", SeverityLevel.Information);
    } catch (error) {
        TelemetryService.trackTrace("Failed to add new folder", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
    }
}; */

/* export const fetchRequest = async (getBlobRequest: FileRequest): Promise<Request> => {
    let request: Request = {
        method: 'GET',        // Replace with your desired HTTP verb like 'GET', 'POST', etc.
        url: 'https://example.com/api/resource', // Replace with your desired URL
        headers: [            // Replace with any desired headers
          { key: 'Authorization', value: 'Bearer token123' },
          { key: 'Content-Type', value: 'application/json' },
        ],
        params: [             // Replace with any URL parameters if needed
          { key: 'page', value: '1' },
          { key: 'limit', value: '10' },
        ],
      };

    try {
        // Todo: (sivad)(sivad)
        //calling to Get blob -------------------------
        const cv = getCV();
        //const request : Request = structuredClone(Request)
        let correlationId: string | undefined = createGuid();
        const makeFetchResponse: MakeFetchResponse = await getBlobFetchRequest(
            cv,
            correlationId, 
            getBlobRequest,
            PageNameEnum.Collections
        );
        
        const jsonData = JSON.stringify(makeFetchResponse.responseBody);
       
        const jsonObject = JSON.parse(jsonData);
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        TelemetryService.trackTrace("jsonBodyObject", SeverityLevel.Information, {
            jsonBodyObject: JSON.stringify(jsonBodyObject),
        });
        //const request : Request  = Object.values(jsonBodyObject);

        const request: Request = {
            method: jsonBodyObject.method,
            url: jsonBodyObject.url,
            body: jsonBodyObject.body,        // Replace with the actual request body if needed
            headers: jsonBodyObject.headers,  // Replace with any desired headers
            params: jsonBodyObject.params,    // Replace with any URL parameters if needed
          };
        //request = requestList[0];
        TelemetryService.trackTrace("jsonBodyObject", SeverityLevel.Information, {
            jsonBodyObject: JSON.stringify(jsonBodyObject),
        });
        //--------------------------------------------

        TelemetryService.trackTrace("Get Request from Blob successfully", SeverityLevel.Information, {
            request: JSON.stringify(request),
        });
        return request;
    } catch (error) {
        // Return null if fetch list run into error
        TelemetryService.trackTrace("Failed to get Request from Blob storage", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
        return request;
    }
}; */

/* export const fetchCollectionList_new = async (): Promise<Collection[] | null> => {
    try {
        const response = await getBlobCollectionIndex();
        const jsonData = JSON.stringify(response.responseBody);
        const jsonObject = JSON.parse(jsonData);
        const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
        const collectionList: Collection[] = Object.values(jsonBodyObject);

        TelemetryService.trackTrace("Get collection list from Blob successfully", SeverityLevel.Information, {
            collectionList: JSON.stringify(collectionList),
        });

        return collectionList;
    } catch (error) {
        // Return null if fetch list run into error
        TelemetryService.trackTrace("Failed to get collection list from storage", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
        return null;
    }
}; */

export const fetchCollectionList = (): Collection[] | null => {
    try {
        const result = dbStorage.getItem<Collection[]>(COLLECTION_LIST_KEY) ?? [];
        TelemetryService.trackTrace("Get collection list from storage successfully", SeverityLevel.Information, {
            collectionList: result,
        });
        return result;
    } catch (error) {
        // Return null if fetch list run into error
        TelemetryService.trackTrace("Failed to get collection list from storage", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
        return null;
    }
};

/* export const deleteRequestFromCollection_Old = (requestIndex: string, collectionIndex: string): void => {
    try {
        const collectionList = fetchCollectionList();
        if (!isDefined(collectionList)) {
            throw new Error("Not able to fetch collection list");
        }
        //Todo: (sivad):
        //collectionList[collectionIndex].items?.splice(requestIndex, 1);
        dbStorage.setItem<Collection[]>(COLLECTION_LIST_KEY, collectionList);
        TelemetryService.trackTrace(
            `Delete requestIndex ${requestIndex} from collectionIndex ${collectionIndex} successfully`,
            SeverityLevel.Information,
            {
                requestIndex: requestIndex,
                collectionIndex: collectionIndex,
            }
        );
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to delete requestIndex ${requestIndex} from collectionIndex ${collectionIndex}`,
            SeverityLevel.Information,
            {
                requestIndex: requestIndex,
                collectionIndex: collectionIndex,
            }
        );
        TelemetryService.trackException(error as Error, {
            requestIndex: requestIndex,
            collectionIndex: collectionIndex,
        });
    }
}; */

//Used only in tests.
/* export const deleteRequestFromCollection = async (
    collectionId: string,
    treeItemId: string,
): Promise<Collection[] | undefined> => {
    try {
        //alert(treeItemId);
        const colList = (async () => {
            const fileName = `${treeItemId}.json`
            const response = await deleteFileInIndex(fileName);
            const jsonData = JSON.stringify(response.responseBody);
            // eslint-disable-next-line @typescript-eslint/ no-unsafe-assignment
            const jsonObject  = JSON.parse(jsonData);
            // eslint-disable-next-line @typescript-eslint/ no-unsafe-assignment
            const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
            // eslint-disable-next-line @typescript-eslint/ no-unsafe-argument
            const collectionList: Collection[] = Object.values(jsonBodyObject);

            return collectionList;
        })();

        TelemetryService.trackTrace(
            `delete request name for collectionId ${collectionId} successfully`,
            SeverityLevel.Information,
            {
                collectionId: collectionId,
                treeItemId: treeItemId,
            }
        );

        return colList;
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to delte request in collectionId ${collectionId}`,
            SeverityLevel.Information,
            {
                collectionId: collectionId,
                treeItemId: treeItemId,
            }
        );
        TelemetryService.trackException(error as Error, {
            collectionId: collectionId,
            treeItemId: treeItemId,
        });
    }
}; */

/* export const renameFileTreeItemInCollection = async (
    collectionId: string,
    treeItemId: string,
    newFileName: string
): Promise<Collection[] | undefined> => {
    try {
        const colList = (async () => {

            const response = await renameFileInIndex(treeItemId, newFileName);
            const jsonData = JSON.stringify(response.responseBody);
            const jsonObject = JSON.parse(jsonData);
            const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
            const collectionList: Collection[] = Object.values(jsonBodyObject);

            return collectionList;
        })();

        TelemetryService.trackTrace(
            `Rename request name for collectionId ${collectionId} successfully`,
            SeverityLevel.Information,
            {
                collectionId: collectionId,
                treeItemId: treeItemId,
                fileNameToSave: newFileName,
            }
        );

        return colList;
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to rename request in collectionId ${collectionId}`,
            SeverityLevel.Information,
            {
                collectionId: collectionId,
                treeItemId: treeItemId,
                fileNameToSave: newFileName,
            }
        );
        TelemetryService.trackException(error as Error, {
            collectionId: collectionId,
            treeItemId: treeItemId,
            fileNameToSave: newFileName,
        });
    }
}; */

// async function asyncGetIndexFromRepo(): Promise<Collection[] | undefined> {
//     const colList = await getIndexFromRepo();
//     return colList;
// }

export const modifyRequestInCollection = (
    collectionIndex: string,
    requestToSave: Request,
    requestNameToSave: string,
    action: "add" | "update",
    requestIndexToUpdate?: string,
    collectionNameToSave?: string
): { requestIndex: string; collectionIndex: string; collectionName: string } | null => {
    try {
        let requestIndex = "";
        const collectionName = ""; //Todo: (sivad): not set correctly

        /*         const collectionList: Promise<Collection[] | undefined> = asyncGetIndexFromRepo();
        
                collectionList.then(collectionList => {
                    if (!isDefined(collectionList)) {
                        throw new Error("Not able to fetch collection list");
                    } */
        getIndexFromRepo()
            .then((colList: Collection[] | undefined) => {
                if (colList !== undefined) {
                    //setCollectionList(colList);
                    //Todo: (sivad): unhappy

                    const selectedCollection = colList.find((item) => item.id === collectionIndex);

                    if (!isDefined(selectedCollection)) {
                        colList.push({
                            ...structuredClone(defaultCollection),
                            name: collectionNameToSave ?? defaultCollectionName,
                        });
                    }

                    if (action === "add") {
                        if (selectedCollection !== undefined) {
                            //selectedCollection?.items?.push({ id: "", name: requestNameToSave, request: requestToSave });
                        }
                        //requestIndex = selectedCollection.items?.length - 1;
                        requestIndex = "";
                    } else if (action === "update") {
                        //let req = findItemById2(selectedCollection?.items,requestIndexToUpdate!);
                        recursivelyFindItemById(splitItemIds(requestIndexToUpdate!).itemId, selectedCollection?.items); //! treat as non-null
                        //req = { id: requestIndexToUpdate!, name: requestNameToSave, request: requestToSave };

                        requestIndex = requestIndexToUpdate!;
                        // selectedCollection.items[requestIndex] = { name: requestNameToSave, request: requestToSave };
                    }

                    //dbStorage.setItem<Collection[]>(COLLECTION_LIST_KEY, collectionList);
                    TelemetryService.trackTrace(
                        "Add/Update request to collection to storage successfully",
                        SeverityLevel.Information,
                        {
                            collectionIndex: collectionIndex,
                            requestIndex: requestIndex,
                            requestName: requestNameToSave,
                            collectionName: selectedCollection?.name,
                            action: action,
                        }
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                return undefined;
            });
        return {
            requestIndex: requestIndex,
            collectionIndex: collectionIndex,
            collectionName: collectionName,
        };
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to add/update request to collection - CollectionIndex: ${collectionIndex}`,
            SeverityLevel.Information,
            {
                collectionIndex: collectionIndex,
                action: action,
            }
        );
        TelemetryService.trackException(error as Error, { collectionIndex: collectionIndex, action: action });
        return null;
    }
};
/* export const findItemById = (collectionItems: Items[] | undefined, idToFind: string): { treeItem: Items | undefined} => {
    let treeItem = undefined
    if(collectionItems !== undefined)    {
        for (const item of collectionItems) {
            if (item.id === idToFind) {
                treeItem = item; // Found the item with the desired ID
                return {treeItem: treeItem};
            }
        
            if (item.items) {
                const nestedItem = findItemById(item.items, idToFind);
                if (nestedItem) {
                return nestedItem; // Found the item in a nested array
                }
            }
        }
    }
    return {treeItem: treeItem};
  } */

/*   export function findItemById2(collectionItems: Items[] | undefined, idToFind: string): Items | undefined {
    if (collectionItems !== undefined){
        for (const item of collectionItems) {
            if (item.id === idToFind) {
                return item; // Found the item with the desired ID
            }
        
            if (item.items) {
                const nestedItem = findItemById(item.items, idToFind);
                if (nestedItem !== undefined) {
                    return nestedItem.treeItem; // Found the item in a nested array
                }
            }
        }
    }
  
    return undefined; // Item with the desired ID not found in the collection
  } */
