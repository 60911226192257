import type { ReactElement } from "react";
import { useMemo } from "react";
import styles from "./styles.module.scss";
import { BodyTab } from "~/components/BodyTab";
import { HeadersTab } from "~/components/HeadersTab";
import { ParamsTab } from "~/components/ParamsTab";
import type { TabSections } from "~/enabler/Tabs";
import { Tabs } from "~/enabler/Tabs";

export function RequestTabs(): ReactElement {
    const sections = useMemo((): TabSections[] => {
        const tabSections: TabSections[] = [];
        tabSections.push(
            {
                id: "Body",
                panelName: "Body",
                value: <BodyTab />,
            },
            {
                id: "Headers",
                panelName: "Headers",
                value: <HeadersTab />,
            },
            {
                id: "Params",
                panelName: "Params",
                value: <ParamsTab />,
            }
        );
        return tabSections;
    }, []);

    return (
        <div className={styles.root}>
            <Tabs tabs={sections} />
        </div>
    );
}
