import Button from "@harmony/enablers/components/button/button";
import HoverCard from "@harmony/enablers/components/hover-card/hover-card";
import { useState, type ReactElement } from "react";
import { useEffect } from "react";
import styles from "./styles.module.scss";
import { useTeamTaskAppContext } from "../TeamTaskAppContext";
import type { TeamTaskAppData } from "~/models/TeamTask/TeamTaskAppData";
import { setEditedItemToRepo } from "~/services/DbRepository/TeamTask/TeamTaskDBRespository";

export function EditPanel(): ReactElement {
    const { selectedItem, setSelectedItem, teamTaskAppDataList, setTeamTaskAppDataList } = useTeamTaskAppContext();
    const [editedItem, setEditedItem] = useState<TeamTaskAppData | null>(null);

    useEffect(() => {
        // Clone the selectedItem to avoid modifying it directly
        setEditedItem(selectedItem !== null ? { ...selectedItem } : null);
    }, [selectedItem]);

    const handleInputChange = (key: keyof TeamTaskAppData, value: string | string[]): void => {
        if (editedItem !== null) {
            setEditedItem((prev) => ({ ...prev!, [key]: value }));
        }
    };

    //todo: learning for some reason this doesn't work, above works
    /*   const handleInputChange = <K extends keyof AppData>(
      field: K,
      value: AppData[K] | null
    ) => {
      if (editedItem !== null) {
        setEditedItem((prev) => {
          if (field === 'Id' && typeof value === 'string') {
            // Handle 'Id' separately to ensure it is of type 'number'
            return { ...prev, Id: value };
          } 
        });
      }
    }; */

    const handleSave = async (): Promise<void> => {
        if (editedItem !== null) {
            const updatedAppDataList = teamTaskAppDataList.map((item: TeamTaskAppData) =>
                item.id === editedItem.id ? { ...item, ...editedItem } : item
            );
            editedItem.action = "update";
            await setEditedItemToRepo(JSON.stringify(editedItem));
            setTeamTaskAppDataList(updatedAppDataList);
            setSelectedItem(null);
            setEditedItem(null);
        }
    };

    return (
        <div className={styles.edit}>
            {editedItem !== null && (
                <div>
                    <h2>Team Task Id : {editedItem.id}</h2>
                    <div className={styles.panel}>
                        <div className={styles.group}>
                            <label htmlFor="title">Title:</label>
                            <input
                                name="title"
                                type="text"
                                value={editedItem.title}
                                onChange={(e): void => {
                                    handleInputChange("title", e.target.value);
                                }}
                            />
                        </div>

                        <label>
                            Assigned To:
                            <input
                                type="text"
                                value={editedItem.assignedto}
                                onChange={(e): void => {
                                    handleInputChange("assignedto", e.target.value);
                                }}
                            />
                        </label>
                        <br />
                        <label>
                            Due Date:
                            <input
                                type="text"
                                value={editedItem.duedate}
                                onChange={(e): void => {
                                    handleInputChange("duedate", e.target.value);
                                }}
                            />
                        </label>
                        <br />
                        <label>
                            Additiona Access To: (csv of alias, other than assigned and creator)
                            <input
                                type="text"
                                value={editedItem.accessto}
                                disabled={editedItem.publictag !== ""}
                                onChange={(e): void => {
                                    handleInputChange("accessto", e.target.value);
                                }}
                            />
                        </label>
                        {/*<HoverCard className={styles.hoverCard} fixedPlacement={true} placement="bottom">
                                    <>
                                        <Button slot="anchor" appearance="stealth" className={styles.keyValueInfoBtn}>
                                            <Icon name="info" label="Information" />
                                        </Button>
                                        <span>foo</span>
                                    </>
            </HoverCard>*/}
                        <br />
                        <label>
                            Public Tag:
                            <input
                                type="text"
                                value={editedItem.publictag}
                                disabled={editedItem.accessto !== ""}
                                onChange={(e): void => {
                                    handleInputChange("publictag", e.target.value);
                                }}
                            />
                        </label>
                        <br />
                        <div className={styles.group}>
                            <label htmlFor="notes"> Notes: </label>
                            <textarea
                                title=""
                                name="notes"
                                rows={10}
                                value={editedItem.notes}
                                onChange={(e): void => {
                                    handleInputChange("notes", e.target.value);
                                }}
                            />
                        </div>
                        <label>
                            Created {editedItem.createddate} by {editedItem.createdby}
                        </label>
                        <br />
                        {/* Add similar input fields for other properties */}
                        {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises,  @typescript-eslint/explicit-function-return-type */}
                        <button
                            className={styles.save}
                            // eslint-disable-next-line  @typescript-eslint/no-misused-promises
                            onClick={(e): Promise<void> => {
                                return (
                                    // eslint-disable-next-line  @typescript-eslint/no-misused-promises, @typescript-eslint/explicit-function-return-type
                                    (async () => {
                                        await handleSave();
                                    })()
                                );
                            }}
                        >
                            {" "}
                            Save
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EditPanel;
