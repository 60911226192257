import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { typedMemo } from "../../../Utils/typedMemo";
import { Button } from "../../Button";
import { Divider } from "../../Divider";
import { Persona } from "../../Persona";
import { Text } from "../../Text";
function ProfilePanelComponent(props) {
    const { user, logout } = props;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.personaCardRoot, children: [_jsx("div", { children: _jsx(Persona, { label: user?.name, initials: user?.initials, personaStyle: styles.personaPaneItem, image: user?.image }) }), _jsxs("div", { className: styles.labelRoot, children: [_jsx(Text, { appearance: "paragraph", tag: "span", children: user?.name }), _jsx(Text, { appearance: "body-text", tag: "span", children: user?.email })] })] }), _jsx(Divider, { className: styles.panelButton }), _jsx(Button, { appearance: "link", className: styles.panelButton, onClick: logout, children: "Sign Out" })] }));
}
export const ProfilePanel = typedMemo(ProfilePanelComponent);
