import { defaultFolder, defaultFolderName, defaultRequestName } from "~/Constants";
import type { Collection, Items } from "~/models/Collection";

//---------------------------------------------------
//Folder management utils
//---------------------------------------------------
export const getDefaultItem = (itemNo: number, parentItemId: string | undefined, type: string): Items => {
    let name = `${defaultFolderName} ${itemNo}`;
    if (type === "file") {
        name = `${defaultRequestName} ${itemNo}`;
    }
    const newDefaultFolderToAdd: Items = {
        ...structuredClone(defaultFolder),
        id: `${parentItemId !== undefined ? parentItemId : ""}_${name}`, //Todo: (sivad): sivad
        //id: `${parentItemId}_${name}`,//temporary one after repo update the actual id will be set in service,
        name: name,
        path: "", //Todo: (sivad): path undefined?
        type: type,
        action: "insert",
        parentId: parentItemId,
    };
    return newDefaultFolderToAdd;
};

export const addItemToSelectedItem = (items: Items[], parentItemId: string, type: string): Items | undefined => {
    let newItem = undefined;
    for (const item of items) {
        if (item.id === parentItemId) {
            const len = item.items?.length ?? 0;
            newItem = getDefaultItem(len, parentItemId, type);
            item.items?.push(newItem);
            return newItem;
        } else if (item.items !== undefined && item.items.length > 0) {
            const result = addItemToSelectedItem(item.items, parentItemId, type);
            if (result !== undefined) {
                return result;
            }
        }
    }
    return newItem;
};

//---------------------------------------------------
//General utils
//---------------------------------------------------
export const getCorrespondingDataItem = (
    selCollectionIndex: string,
    selRequestIndex: string,
    colList: Collection[]
): Items | undefined => {
    //visual tree item id is Type_Id format
    //the corresponding data item in collectionList is of format id
    let itemId = "";
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (selRequestIndex === undefined || selRequestIndex === "") {
        // that means its a collection
        itemId = splitItemIds(selCollectionIndex).itemId;
    } else {
        itemId = splitItemIds(selRequestIndex).itemId;
    }

    const selectedCollection = colList.find((item) => item.id === splitItemIds(selCollectionIndex).itemId);
    const selectedItem = recursivelyFindItemById(itemId, selectedCollection?.items);
    return selectedItem;
};
export const createItemId = (type: string, id: string, topParentId: string): string => {
    //Todo: (sivad) : to check code. during collection refresh sometimes the collectionid already has the key and calling this again messes the format
    //so checking to see if the passed in id already has '_' and do nothing if so. this works but for optimization need to relook why and where it happens
    const key = id.indexOf("_") > 0 ? id : `${type}_${id}_TP_${topParentId}`;
    return key;

    //const key = `${type}_${id}_TP_${topParentId}`
    /* if (key.startsWith('folder_folder'))
        alert(`key = ${key}, type=${type}, id=${id}, partid=${topParentId}`) */
};

/* export const getParentKey = (idToFind: string) => {
    //selectedRequestIndex
    //return `${type}_${id}_TP_${topParentId}`
    return "";
} */

export const getFormattedCollectionKey = (id: string): string => {
    return `collection_${id}_TP_`;
};
export const splitItemIds = (itemKey: string): { itemType: string; itemId: string; itemTopParentId: string } => {
    //key value format for root folder(collection) : collection_1f0102c0
    //key value format for child notes : "file_d7166081_TP_12345" or folder_

    const splitArray = itemKey.split("_");
    const itemType = splitArray[0];
    const itemId = splitArray[1];
    const itemTopParentId = splitArray.length > 3 ? splitArray[3] : ""; //collection will not have a top parent
    return {
        itemType: itemType,
        itemId: itemId,
        itemTopParentId: itemTopParentId,
    };
};

//Todo: (sivad): use splititems instead. (vnext)
//item Id is the last guid after _
export const getItemIdFromKey = (itemKey: string): string => {
    const splitArray = itemKey.split("_");
    return splitArray[splitArray.length - 1];
};

//recurrsively find the id in the collection
export const recursivelyFindItemById = (
    idToFind: string,
    items: Items[] | undefined,
    parentItemId?: string
): Items | undefined => {
    if (items !== undefined) {
        for (const item of items) {
            if (item.id === idToFind) {
                item.parentId = parentItemId; //store the items parent id
                return item; // Found the item with the specified id
            }

            if (item.items !== undefined && item.items.length > 0) {
                const foundInChildren = recursivelyFindItemById(idToFind, item.items, item.id);
                if (foundInChildren !== undefined) {
                    return foundInChildren; // Found in children
                }
            }
        }
    }

    return undefined; // Item with the specified id not found
};

//TODO
export const flattenItems = (items: Items[]): Items[] => {
    const flattenedItems: Items[] = [];

    function flatten(item: Items): void {
        if (item.type !== "file") {
            //if it has more items
            if (item.items !== undefined && item.items !== null) {
                //and they are folders then don't add current item and recurse the child
                if (item.items.find((i) => i.type === "folder") !== undefined) {
                    item.items.forEach(flatten);
                }
                //else they are files then add current one to list
                else if (item.items.find((i) => i.type === "file") !== undefined) {
                    flattenedItems.push(item);
                } else if (item.items.length === 0) {
                    //empty folder
                    flattenedItems.push(item);
                }
            } else {
                //if current is a folder and no other items then add it to list
                flattenedItems.push(item);
            }
        }
    }
    items.forEach(flatten);

    return flattenedItems;
};

export const removeItemById = (items: Items[], targetId: string): void => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === targetId) {
            items.splice(i, 1); // Remove the item from the array
            return;
        }
        if (item.items !== undefined) {
            removeItemById(item.items, targetId);
        }
    }
};

export const getCurrentState = (
    selectedCollectionIndex: string,
    selectedRequestIndex: string
): { isAnythingSelected: boolean; currentHighestSelectionType: string } => {
    let isAnythingSelected = true;
    let currentHighestSelectionType = "collection";

    if (selectedCollectionIndex === "" && selectedRequestIndex === "") {
        isAnythingSelected = false;
        currentHighestSelectionType = "none";
    } else if (selectedCollectionIndex !== "" && selectedRequestIndex === "") {
        //only collection is selected
        isAnythingSelected = true;
        currentHighestSelectionType = "collection";
    } else if (
        selectedCollectionIndex !== "" &&
        selectedRequestIndex !== "" &&
        splitItemIds(selectedRequestIndex).itemType === "folder"
    ) {
        //collection is selected and folder child is selected
        isAnythingSelected = true;
        currentHighestSelectionType = "folder";
    } else if (selectedRequestIndex !== "" && splitItemIds(selectedRequestIndex).itemType !== "folder") {
        // file/request level selected
        isAnythingSelected = true;
        currentHighestSelectionType = "file";
    }
    return {
        isAnythingSelected: isAnythingSelected,
        currentHighestSelectionType: currentHighestSelectionType,
    };
};
