import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { navHeader, taskItem, visuallyHidden } from "@harmony/enablers/react";
import clsx from "clsx";
import { useState, useCallback } from "react";
import { ProfilePanel } from "./Components/ProfilePanel";
import styles from "./styles.module.scss";
import { isDefined } from "../../Utils/isDefined";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Panel } from "../Panel";
import { PanelGroup } from "../PanelGroup";
import { Persona } from "../Persona";
const HarmonyHeader = scopeForReact(navHeader);
const TaskItem = scopeForReact(taskItem);
const VisuallyHidden = scopeForReact(visuallyHidden);
function NavHeaderComponent(props) {
    const { workspaces, disableSearch, farRightSettings, overrideDefaultPanelGroupRender } = props;
    const [panelVisible, setPanelVisible] = useState();
    const renderFarRightButton = useCallback((defaultIconName, defaultIconLabel, panel, buttonSettings) => {
        if (isDefined(buttonSettings)) {
            const { overrideDefaultRender, onClick, iconLabel, iconName, iconClassName, buttonAppearance, buttonSlot, buttonClassName, } = buttonSettings;
            if (isDefined(overrideDefaultRender)) {
                return overrideDefaultRender();
            }
            const buttonOnClick = () => {
                if (isDefined(onClick)) {
                    onClick();
                }
                setPanelVisible(panel);
            };
            return (_jsxs(Button, { slot: buttonSlot ?? "action", appearance: buttonAppearance ?? "stealth", className: buttonClassName, onClick: buttonOnClick, children: [_jsx(Icon, { name: iconName ?? defaultIconName, label: iconLabel ?? defaultIconLabel, className: iconClassName }), _jsx(VisuallyHidden, { children: defaultIconLabel })] }, `farRightButton-${iconLabel ?? defaultIconLabel}`));
        }
        return null;
    }, []);
    const renderProfileButton = useCallback((profileButtonSettings) => {
        if (isDefined(profileButtonSettings)) {
            const { overrideDefaultRender, onClick, buttonSlot, buttonAppearance, buttonClassName, overridePersonaDefaultRender, personaStyle, user, ...rest } = profileButtonSettings;
            if (isDefined(overrideDefaultRender)) {
                return overrideDefaultRender();
            }
            const buttonOnClick = () => {
                if (isDefined(onClick)) {
                    onClick();
                }
                setPanelVisible("Profile");
            };
            return (_jsx(Button, { slot: buttonSlot ?? "action", appearance: buttonAppearance ?? "stealth", className: buttonClassName, onClick: buttonOnClick, children: isDefined(overridePersonaDefaultRender) ? (overridePersonaDefaultRender()) : (_jsx(Persona, { className: clsx(styles.persona, personaStyle), image: user?.image, initials: user?.initials, label: user?.name, ...rest })) }, "farRightButton-profileButton"));
        }
        return null;
    }, []);
    const hidePanel = useCallback((onHeHide) => {
        if (isDefined(onHeHide)) {
            onHeHide();
        }
        setPanelVisible(null);
    }, []);
    const renderPanel = useCallback((panel, panelProps) => {
        if (isDefined(panelProps)) {
            const { renderPanelContent, panelStyles, onHeHide, ...rest } = panelProps;
            const panelContent = () => {
                if (panel === "Profile") {
                    return (_jsx(ProfilePanel, { user: panelProps.user, logout: panelProps.logout }));
                }
                else if (isDefined(renderPanelContent)) {
                    return renderPanelContent();
                }
                return null;
            };
            return (_jsx(Panel, { open: panelVisible === panel, onHeHide: () => {
                    hidePanel(onHeHide);
                }, className: clsx(styles.panel, panelStyles), ...rest, children: panelContent() }));
        }
        return null;
    }, [hidePanel, panelVisible]);
    const renderPanelGroup = () => {
        if (isDefined(overrideDefaultPanelGroupRender)) {
            return overrideDefaultPanelGroupRender();
        }
        return (_jsxs(PanelGroup, { children: [renderPanel("Help", farRightSettings?.helpSettings), renderPanel("Settings", farRightSettings?.settingsSettings), renderPanel("Profile", farRightSettings?.profileSettings)] }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(HarmonyHeader, { navTitle: props.navTitle, disableSearch: disableSearch, children: [isDefined(farRightSettings) && (_jsxs(_Fragment, { children: [isDefined(farRightSettings.additionalItems) &&
                                farRightSettings.additionalItems.map((additionalItem) => renderFarRightButton("edit", "Edit", undefined, additionalItem)), renderFarRightButton("help", "Help", "Help", farRightSettings.helpSettings), renderFarRightButton("settings", "Settings", "Settings", farRightSettings.settingsSettings), renderProfileButton(farRightSettings.profileSettings)] })), _jsx("div", { slot: "task-items", children: workspaces.map((workspace) => (_jsxs(TaskItem, { href: workspace.url, onClick: workspace.onClick, target: workspace.target, selected: workspace.selected, children: [_jsx(Icon, { name: workspace.iconName, slot: workspace.slot ?? "start", label: workspace.name }), workspace.title] }, workspace.name))) })] }), isDefined(farRightSettings) && renderPanelGroup()] }));
}
export const NavHeader = typedMemo(NavHeaderComponent);
