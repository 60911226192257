import { createContext } from "react";
import { defaultCollectionName } from "~/Constants";
import type { Request } from "~/models/IRequest";

export type CollectionContextType = {
    selectedRequestIndex: string;
    selectedCollectionIndex: string;
    collectionName: string;
    collectionList: Collection[];
    setSelectedCollectionIndex: (value: React.SetStateAction<string>) => void;
    setSelectedRequestIndex: (value: React.SetStateAction<string>) => void;
    setCollectionList: (value: React.SetStateAction<Collection[]>) => void;
    setCollectionName: (value: React.SetStateAction<string>) => void;
};

export const CollectionContext = createContext<CollectionContextType>({
    selectedCollectionIndex: "",
    selectedRequestIndex: "",
    collectionName: defaultCollectionName,
    collectionList: [],
    setSelectedCollectionIndex: null!,
    setSelectedRequestIndex: null!,
    setCollectionList: null!,
    setCollectionName: null!,
});

export type Collection = {
    id: string;
    name: string;
    type: string;
    path?: string;
    pathId: string;
    pathName: string;
    collectionId?: number;
    environmentId?: number;
    isSystemTemplate?: boolean;
    category: string;
    items: Items[];
};

export type Items = {
    id: string;
    name: string;
    index?: number;
    type: string;
    path?: string;
    pathId: string;
    pathName: string;
    category: string;
    collectionId?: number;
    environmentId?: number;
    isSystemTemplate?: boolean;
    request?: Request;
    items?: Items[];
    //for API request body only. Todo: (sivad) : may be a different object latter
    action?: string;
    parentId?: string;
    contentJson?: string;
};

export type Collection1 = {
    // Todo: (sivad) since we are using array index instead of id to find collection now, mark id as optional for now
    id?: number;
    name: string;
    type?: string;
    path?: string;
    // requestList: DbRequest[];
    isActive?: boolean;
    isSystemDefined?: boolean;
} & Audit;

// export type DbRequest_old = {
//     id?: number;
//     name: string;
//     type? : string;
//     path?: string;
//     collectionId?: number;
//     environmentId?: number;
//     isSystemTemplate?: boolean;
//     value: Request;
//     requestList?: DbRequest_old[];
//};
// export type Collection_old = {
//     // Todo: (sivad) since we are using array index instead of id to find collection now, mark id as optional for now
//     id?: number;
//     name: string;
//     type? : string;

//     requestList: DbRequest_old[];
//     isActive?: boolean;
//     isSystemDefined?: boolean;
// } & Audit;

// Todo: (sivad) mark everything as optional for now
export type Audit = {
    createdBy?: string;
    createdOn?: number;
    updatedBy?: string;
    updatedOn?: number;
};

export type ServiceResponse = {
    action: string;
    id: string;
    result: string;
    error?: string;
    collection?: Collection[];
};
