import type { ReactElement } from "react";
import { isValidElement } from "react";
import style from "./styles.module.scss";
import { Icon } from "~/enabler/Icon";

type ErrorPageProps = {
    messageContent?: JSX.Element | string;
};

export function ErrorPage(props: ErrorPageProps): ReactElement {
    const { messageContent } = props;
    return (
        <div className={style.root}>
            <Icon name="incidenttriangle" className={style.incidentTriangle} />
            {isValidElement(messageContent) ? (
                messageContent
            ) : (
                <div className={style.messageContentDiv}>{messageContent}</div>
            )}
        </div>
    );
}
