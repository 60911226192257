import type { SelectOptionConfig } from "~/enabler/Select/Select";
import { stringConstants } from "~/locale/stringConstants";
import type { Environment } from "~/models/Environment";
import type {
    Request,
    HeaderItem,
    TargetRequest,
    RequestStartTimes,
    CorrelationAttributes,
    WebInvokeRequest,
} from "~/models/IRequest";
import type { ResponseType, HTTPResponse } from "~/models/IResponse";
import type { PageNameEnum } from "~/models/Page";
import type { ITelemetryContext } from "~/models/TelemetryContext";
import { EventState } from "~/models/TelemetryContext";
import { webRequestInvoke } from "~/services/Repository/DataRepository";
import { compileEnvironment, replaceStringPlaceholders, replaceKeyValuePlaceholders } from "~/utils/environmentUtils";
import { createGuid, getCV, getExtendedCV } from "~/utils/headerUtils";
import { isDefined } from "~/utils/isDefined";
//import { MakeFetchRequest } from "~/utils/responseUtils";
import { TelemetryEvents, TelemetryServiceTrackEvent } from "~/utils/telemetryUtils";
import { addRecentlyUsedUrl } from "~/utils/userPreferencesUtils";
import { TelemetryServiceTrackTraceInformation } from "../telemetryUtils/telemetryUtils";
import { SeverityLevel } from "@empowerment/telemetry";

export const sendTargetRequest = async (
    request: Request,
    updateRequest: React.Dispatch<React.SetStateAction<Request>>,
    setResponse: React.Dispatch<React.SetStateAction<ResponseType | undefined>>,
    setShowProgress: React.Dispatch<React.SetStateAction<boolean>>,
    setRecentlyUsedUrls: React.Dispatch<React.SetStateAction<SelectOptionConfig[]>>,
    setCorrelationAttributes: React.Dispatch<React.SetStateAction<CorrelationAttributes>>,
    setTargetRequestDispatched: React.Dispatch<React.SetStateAction<boolean>>,
    setRequestStartTimes: React.Dispatch<React.SetStateAction<RequestStartTimes>>,
    sendRequestStartTime: number,
    pageName: PageNameEnum,
    environmentList: Environment[],
    environmentName: string
): Promise<void> => {
    const page = "requestUtils";
    // body should only have value, which is a json string
    // for now, just wrap value in an array, will need to determine array value later
    // validation -> send headers/params with get and body with post
    // update cv and correlation-id

    // create environment variable map based on precedence
    const environmentVariablesMap: Map<string, string> = compileEnvironment(environmentList, environmentName);

    // replace placeholders in request
    //disabling the replacement in ux layer
    //const replacedRequest = replaceRequestPlaceholders(request, environmentVariablesMap);
    const replacedRequest = request;
    const updatedHeaders = replacedRequest.headers;

    const cv = getCV();
    const cvExt = getExtendedCV();
    let correlationId: string | undefined = createGuid();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (updatedHeaders !== null && updatedHeaders !== undefined) {
        updatedHeaders.forEach((header) => {
            if (header.key.toUpperCase() === "MS-CV" && header.value.toLocaleUpperCase() === "{{$CV}}") {
                header.value = cvExt;
            }
        });
    }
    if (updatedHeaders?.[1]?.["key"] === stringConstants.components.App.RequestPanel.correlationId) {
        updatedHeaders[1]["value"] = correlationId;
    } else {
        correlationId = undefined;
    }

    updateRequest((prev) => ({
        ...prev,
        headers: updatedHeaders,
    }));

    const convertedHeaders: HeaderItem[] | undefined = replacedRequest.headers
        ?.filter((val) => val.key !== "")
        .map((val) => {
            return { key: val.key, value: [val.value] }; // wrap value in array
        });

    let flattenedParams = "?";
    replacedRequest.params
        ?.filter((val) => val.key !== "")
        .forEach((val) => {
            if (flattenedParams === "?") {
                flattenedParams += `${val.key}=${val.value}`;
            } else {
                flattenedParams += `&${val.key}=${val.value}`;
            }
        });

    const convertURL: string =
        replacedRequest.method === "GET" ? replacedRequest.url + flattenedParams : replacedRequest.url; // May need to allow it for other request methods

    const fetchTelemetryContext: ITelemetryContext = {
        page: pageName,
        url: replacedRequest.url, // don't use convertURL as it contains headers + params
        MSCV: cv,
        correlationId: correlationId,
    };

    TelemetryServiceTrackEvent(TelemetryEvents.DispatchRequest.Initiated, {
        ...fetchTelemetryContext,
        eventState: EventState.Initiated,
    });

    const targetRequest: TargetRequest = {
        url: convertURL,
        method: replacedRequest.method,
    };

    // May need to restrict for more replacedRequest methods
    if (
        replacedRequest.body?.values !== undefined &&
        replacedRequest.body.type === "raw" &&
        replacedRequest.body.values !== "" &&
        replacedRequest.method !== "GET"
    ) {
        targetRequest.body = replacedRequest.body.values as string;
    }
    if (convertedHeaders !== undefined && convertedHeaders.length !== 0) {
        targetRequest.headers = [...convertedHeaders];
    }

    const env: Environment | undefined = environmentList.find((environment) => environment.name === environmentName);
    const envId: number = env !== undefined && env.id !== undefined ? Number(env.id) : 0; //default to 0
    if (envId === 0 && targetRequest.url.search("}}") === 0) {
        //TODO:(Sivad) check beyond url and differenciate with gloabls
        alert("Request contains variables, please select a env");
    }
    const webInvokeRequest: WebInvokeRequest = {
        workspaceId: 1, //TODO:(sivad) workspace hardcoded for now
        environmentId: envId,
        targetRequest: targetRequest,
    };
    TelemetryServiceTrackTraceInformation(
        `${page}.sendTargetRequest.callWebrequestInvoke ${cv}`,
        SeverityLevel.Information,
        { webInvokeRequest: `${JSON.stringify(webInvokeRequest)}` }
    );
    const httpResponse: HTTPResponse = await webRequestInvoke(webInvokeRequest, cv);
    /* const makeFetchResponse: MakeFetchResponse = await MakeFetchRequest(
        targetRequest,
        replacedRequest.url,
        cv,
        correlationId,
        pageName
    ); */

    const response: ResponseType = {
        statusCode: httpResponse.statusCode,
        errorType: httpResponse.errorType ?? undefined,
        responseBody: httpResponse.responseBody,
    };

    setTargetRequestDispatched(true);
    setShowProgress(false); // hide progress bar
    setCorrelationAttributes({ MSCV: cv, correlationId: correlationId }); // update MSCV & correlationId in RequestPanel

    const renderResponseStartTime = Date.now();
    setRequestStartTimes({
        ...httpResponse.requestStartTimes!,
        sendRequestStartTime: sendRequestStartTime,
        renderResponseStartTime: renderResponseStartTime,
    });
    /* if (response.responseBody !== undefined)
    {
        try {
            const jsonString = JSON.stringify(response.responseBody);
            const parsedJson = JSON.parse(jsonString);
            const indentedString = JSON.stringify(parsedJson, null, 2); // 2 spaces for indentation
            response.responseBody  = indentedString;
          } catch (error) {
            console.log ("unable to format");
          }
    } */
    setResponse(response);
    if (isDefined(replacedRequest.url) && replacedRequest.url !== "") {
        const urls = addRecentlyUsedUrl(replacedRequest.url);
        const parsedSelectOptions: SelectOptionConfig[] = urls.map((url) => ({ value: url }));
        setRecentlyUsedUrls(parsedSelectOptions);
    }
};

const replaceRequestPlaceholders = (request: Request, placeholdersMap: Map<string, string>): Request => {
    const replacedUrl = replaceStringPlaceholders(request.url, placeholdersMap);
    let replacedBody = undefined;
    if (request.body !== undefined && request.body.values !== undefined && request.body.values !== null) {
        replacedBody = {
            ...request.body,
            values:
                typeof request.body.values === "string"
                    ? replaceStringPlaceholders(request.body.values, placeholdersMap)
                    : replaceKeyValuePlaceholders(request.body.values, placeholdersMap),
        };
    }
    const replacedHeaders =
        request.headers !== undefined ? replaceKeyValuePlaceholders(request.headers, placeholdersMap) : undefined;
    const replacedParams =
        request.params !== undefined ? replaceKeyValuePlaceholders(request.params, placeholdersMap) : undefined;
    return {
        ...request,
        url: replacedUrl,
        body: replacedBody,
        headers: replacedHeaders,
        params: replacedParams,
    };
};
