import { jsx as _jsx } from "react/jsx-runtime";
import { text } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyText = scopeForReact(text);
function TextComponent(props) {
    const { children, ...rest } = props;
    return _jsx(HarmonyText, { ...rest, children: children });
}
export const Text = typedMemo(TextComponent);
