import { jsx as _jsx } from "react/jsx-runtime";
import { paneGroup } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyPanelGroup = scopeForReact(paneGroup);
function PanelGroupComponent(props) {
    const { children, panelGroupStyles, ...rest } = props;
    return (_jsx(HarmonyPanelGroup, { className: panelGroupStyles, ...rest, children: children }));
}
export const PanelGroup = typedMemo(PanelGroupComponent);
