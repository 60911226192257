import type { AppConfig } from "~/types/AppConfig";

const localAppConfig: AppConfig = {
    aiKey: "1d421558-b8a9-468b-9346-1bd716a4e485",
    authClientId: "ea3782b1-eb9f-4a25-ace7-0983fd28e81c",
    authAuthority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    apixApiBase: "https://apix.int.microsoft.com", //https://localhost:44393 //https://apix.int.microsoft.com
    apixApiScope: "https://apix.int.l2o.microsoft.com/user_impersonation",
    graphApiBase: "https://graph.microsoft.com/v1.0/",
    graphApiScope: "https://graph.microsoft.com/User.Read",
    isAppEnabled: "true",
    agreementWorkspaceLink: "https://agreementcenterv2.azureedge.net/",
    approvalWorkspaceLink: "https://www.microsoft.com/", //Todo: (sivad) update it with actual approval workspace link
    empowermentWorkspaceLink: "https://empowermentcenter-stamp.azureedge.net/",
    isEnvironmentsPanelEnabled: "false",
};

export function appConfig(): AppConfig {
    if (process.env.NODE_ENV === "development") {
        return localAppConfig;
    }
    return window.apixAppConfig;
}
