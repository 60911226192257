//restore 6:47
//import { TelemetryService, SeverityLevel } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useContext, useCallback } from "react";
import styles from "./styles.module.scss";
import {
    addItemToSelectedItem,
    createItemId,
    getCurrentState,
    recursivelyFindItemById,
    splitItemIds,
} from "../../../../utils/DataUtils/DataUtils";
import { defaultFolderRequestType, defaultRequest, defaultFileRequestType } from "~/Constants";
import { Button } from "~/enabler/Button";
import { Icon } from "~/enabler/Icon";
import { stringConstants } from "~/locale/stringConstants";
import type { ServiceResponse } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
//import { RequestContext } from "~/models/IRequest";
import { updateIndexToRepo2 } from "~/services/Repository/DataRepository";
import { TelemetryServiceTrackEvent, TelemetryEvents } from "~/utils/telemetryUtils";

type CollectionsPanelHeaderProps = {
    enableCollectionAction: boolean;
    enableFolderAction: boolean;
    enableAddRequestAction: boolean;
    renameCollectionOnClick: () => void;
    deleteCollectionOnClick: () => void;
};

export function CollectionsPanelHeader(props: CollectionsPanelHeaderProps): ReactElement {
    const {
        enableCollectionAction: enableCollectionActions,
        enableFolderAction,
        renameCollectionOnClick,
        deleteCollectionOnClick,
        enableAddRequestAction,
    } = props;

    const {
        selectedCollectionIndex,
        //setSelectedCollectionIndex,
        collectionList,
        selectedRequestIndex,
        setSelectedRequestIndex,
        //setCollectionName,
        setCollectionList,
    } = useContext(CollectionContext);

    // const { updateRequest, updateInitialRequest, updateRequestName, request, initialRequest, requestName } =        useContext(RequestContext);

    /*     const addCollectionOnClick = useCallback(() => {
        console.log("in collectionsPanelHeader start of add collection click selectedIndex is" + selectedCollectionIndex + " selectedRequestIndex=" + selectedRequestIndex);
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionPanelHeader.AddNewCollectionButtonClicked);
        addNewCollection();
        //old:
        //const result = fetchCollectionList() ?? [];
        //setCollectionList(result);
        //new :  reload collectionList
        //setCollectionList(fetchCollectionList_new() ?? []);

        //(async () => {
        //    const collectionList = await getIndexFromRepo()
        //    setCollectionList(collectionList ?? []);
        //})();

        //setSelectedCollectionIndex("");
        //setSelectedRequestIndex("");
        //setCollectionName(defaultCollectionName);
        console.log("in collectionsPanelHeader end of add collection click selectedIndexId is" + selectedCollectionIndex + " selectedRequestIndex=" + selectedRequestIndex);
    }, [setCollectionList, setSelectedCollectionIndex, setSelectedRequestIndex, setCollectionName]);
 */
    const addFolderOnClick = useCallback(() => {
        //alert(` CPH-current keys =${selectedCollectionIndex},${selectedRequestIndex}`)
        const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex);
        let currentSelectionKey = "";
        if (currentState.currentHighestSelectionType === "collection") {
            currentSelectionKey = selectedCollectionIndex;
        } else if (currentState.currentHighestSelectionType === "folder") {
            //add folder will not be enabled if its a file so that option is not needed.
            currentSelectionKey = selectedRequestIndex;
        }
        //alert(`CPH-currentSelectionKey=${currentSelectionKey}`)
        const selectedTree = splitItemIds(currentSelectionKey);
        const selectedItem = recursivelyFindItemById(selectedTree.itemId, collectionList);
        if (selectedItem !== undefined) {
            const newItem = addItemToSelectedItem(collectionList, selectedTree.itemId, defaultFolderRequestType);
            //const temporaryId = newItem?.id; //id will be "ParentId_New folder X" at this point
            //alert(`in CPH-tempId=${temporaryId}`)
            if (newItem !== undefined) {
                //update repo
                updateIndexToRepo2(newItem)
                    .then((serviceResponse: ServiceResponse | undefined) => {
                        if (serviceResponse !== undefined) {
                            newItem.id = createItemId(newItem.type, serviceResponse.id, selectedTree.itemId);
                            //alert(`in CPH: newItem.id - ${newItem.id}`);
                            setCollectionList(collectionList); //refresh local
                            setSelectedRequestIndex(newItem.id);
                        } else {
                            alert("Alpha Mode in CPH : serviceResponse not found!");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        } else {
            alert("Alpha Mode in CPH : selected item not found!");
        }
    }, [selectedCollectionIndex, selectedRequestIndex, setCollectionList, setSelectedRequestIndex, collectionList]);

    const addRequestOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.AddNewRequestButtonClicked);
        console.log(
            `in collectionsPanelHeader add request click selectedIndexId is ${selectedCollectionIndex} selectedRequestIndex=${selectedRequestIndex}`
        );

        const selectedTree = splitItemIds(selectedRequestIndex);
        const newRequest = structuredClone(defaultRequest);
        //change2 : if its a file then selectedRequestIndex should be available
        if (selectedTree.itemType === "folder") {
            //then request file is being created
            //if (selectedRequestIndex !== undefined) {
            // call api to create a new file entry in index and create the file with the default request contents
            //const selectedTree = splitItemIds(selectedRequestIndex);
            const newItem = addItemToSelectedItem(collectionList, selectedTree.itemId, defaultFileRequestType); //requestFile

            if (newItem !== undefined) {
                newItem.contentJson = JSON.stringify(newRequest);

                //item id will be generated by service and ignore the temporary current one. so need to get the id back.
                updateIndexToRepo2(newItem)
                    .then((serviceResponse: ServiceResponse | undefined) => {
                        if (serviceResponse !== undefined) {
                            newItem.id = serviceResponse.id;
                            const newRequestId = createItemId(newItem.type, newItem.id, selectedTree.itemTopParentId);
                            setSelectedRequestIndex(newRequestId);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            //const serviceResponse = asyncUpdateIndexToRepo2(newItem);
            /*       (async () => {
                      const collectionList = await getIndexFromRepo()
                      setCollectionList(collectionList ?? []);
                  })(); */

            //old
            /* const result = modifyRequestInCollection(
                selectedCollectionIndex,
                newRequest,
                defaultRequestName,
                "add"
            );

            if (isDefined(result)) {


                updateRequest(newRequest);
                updateInitialRequest(newRequest);
                updateRequestName(defaultRequestName);
                setCollectionName(result.collectionName);
                setSelectedRequestIndex(result.requestIndex);

                //old:
                //setCollectionList(fetchCollectionList() ?? []);
                //new :  reload collectionList
                //setCollectionList(fetchCollectionList_new() ?? []);

            } */
        } else {
            alert("Request files can only be created at folder level!");
        }
    }, [selectedCollectionIndex, selectedRequestIndex, setSelectedRequestIndex, collectionList]);

    return (
        <div id="CollectionsPanelHeader" className={styles.root}>
            <div className={styles.collectionButtonsDiv}>
                {/*                 <Button
                    id={stringConstants.components.Common.Collection.AddCollection}
                    appearance="command"
                    onClick={addCollectionOnClick}
                    title={stringConstants.components.Common.Collection.AddCollection}
                >
                    <Icon
                        name="newBoard"
                        slot="start"
                        label={stringConstants.components.Common.Collection.AddCollection}
                    />
                </Button> */}
                <Button //for folder create
                    id={stringConstants.components.Common.Collection.AddFolder}
                    appearance="command"
                    disabled={!enableFolderAction} // Only enable it when user selects a valid folder
                    onClick={addFolderOnClick}
                    title={stringConstants.components.Common.Collection.AddFolder}
                >
                    <Icon
                        name="newfolder"
                        slot="start"
                        label={stringConstants.components.Common.Collection.AddFolder}
                    />
                </Button>

                <Button //for folder Rename
                    id={stringConstants.components.Common.Collection.RenameCollection}
                    appearance="command"
                    disabled={!enableCollectionActions} // Only enable it when user selects a collection
                    onClick={renameCollectionOnClick}
                    title={stringConstants.components.Common.Collection.RenameCollection}
                >
                    <Icon
                        name="edit"
                        slot="start"
                        label={stringConstants.components.Common.Collection.RenameCollection}
                    />
                </Button>
                <Button //for folder Delete
                    id={stringConstants.components.Common.Collection.DeleteCollection}
                    appearance="command"
                    disabled={!enableCollectionActions} // Only enable it when user selects a collection
                    onClick={deleteCollectionOnClick}
                    title={stringConstants.components.Common.Collection.DeleteCollection}
                >
                    <Icon
                        name="delete"
                        slot="start"
                        label={stringConstants.components.Common.Collection.DeleteCollection}
                    />
                </Button>
            </div>
            <Button
                id={stringConstants.components.Common.Request.AddRequest}
                appearance="command"
                disabled={!enableAddRequestAction}
                onClick={addRequestOnClick}
                title={stringConstants.components.Common.Request.AddRequest}
            >
                <Icon name="add" slot="start" label={stringConstants.components.Common.Request.AddRequest} />
            </Button>
        </div>
    );
}

/*     const addFolderOnClick_old = useCallback(() => {
          TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.AddNewCollectionButtonClicked);
          //mine : setSelectedCollectionIndex("Collection__1f0102c0-49f4-4cb7-8541-c707d9bfcb19")
          console.log("in collectionPanelHeader add folder click selectedIndexId is" + selectedCollectionIndex + " treeItemKey is " + " selectedRequestIndex=" + selectedRequestIndex);
          //alert(collectionKey);
          const selectedTree = splitItemIds(selectedCollectionIndex);
  
          if (selectedTree.itemType === "folder") {
              //create empty file
              const cv = getCV();
              let correlationId: string = createGuid();
              const createFolderRequestObj: CreateBlobRequest = {
                  fileName: `${selectedTree.itemPath}/empty.txt`,
                  content: "This is empty file to create a folder.",
              };
  
              (async () => {
                  const makeFetchResponse: MakeFetchResponse = await createBlobFetchRequest(
                      createFolderRequestObj,
                      cv,
                      correlationId,
                      PageNameEnum.Collections
                  );
  
                  const jsonData = JSON.stringify(makeFetchResponse.responseBody);
                  const jsonObject = JSON.parse(jsonData);
                  const jsonBodyObject = JSON.parse(JSON.stringify(jsonObject.body));
              })();
              //setCollection Todo: (sivad): sivad
              //const collectionList: Collection[] = Object.values(jsonBodyObject);
              //const collectionList: Collection[] = Object.values(jsonBodyObject);
              //TelemetryService.trackTrace("Created the folder successfully", SeverityLevel.Information, {                responseObject: jsonBodyObject,    });
          }
  
          //const result =  (async () => {
          //const collectionList = await fetchCollectionList_new()
  
          //setCollectionList(collectionList ?? []);              
          //})();
  
          //() ?? [];
          //setCollectionList(result);
          //setSelectedCollectionIndex("");
          //setSelectedRequestIndex("");
          //setCollectionName(defaultCollectionName);
      }, [setCollectionList, setSelectedCollectionIndex, setSelectedRequestIndex, setCollectionName]); */
