import type { TextField as TextFieldClass } from "@harmony/enablers/components/text-field/text-field";
import type { ReactElement } from "react";
import styles from "./styles.module.scss";
import type { KeyValue } from "~/components/Common/types";
import { Button } from "~/enabler/Button";
import { HoverCard } from "~/enabler/HoverCard";
import { Icon } from "~/enabler/Icon";
import { TextField } from "~/enabler/TextField";

type KeyValueTableProps = {
    panelName: string | undefined;
    keyValueList: KeyValue[] | undefined;
    keyTextFieldLabel: string;
    valueTextFieldLabel: string;
    onAdd: () => void;
    onRemove: (index: number) => void;
    onTextFieldUpdate: (newText: string, index: number, field: "key" | "value") => void;
    keyTextFieldValidation?: (target: TextFieldClass) => void;
    valueTextFieldValidation?: (target: TextFieldClass) => void;
};

export function KeyValueTable(props: KeyValueTableProps): ReactElement {
    const {
        panelName,
        keyValueList,
        keyTextFieldLabel,
        valueTextFieldLabel,
        onAdd,
        onRemove,
        onTextFieldUpdate,
        keyTextFieldValidation,
        valueTextFieldValidation,
    } = props;

    return (
        <>
            <div className={styles.addDiv}>
                <Button appearance="stealth" onClick={onAdd} tabIndex={0}>
                    <Icon name="add" slot="start" />
                    Add{` ${panelName ?? ""}`}
                </Button>
            </div>
            <div className={styles.keyValueTable}>
                {keyValueList?.map((keyValuePair, index) => {
                    const key = keyValuePair.key;
                    const value = keyValuePair.value;
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={styles.keyValueRow} key={`keyValueRow-${index}`}>
                            <TextField
                                className={styles.keyValueTextFields}
                                label={keyTextFieldLabel}
                                value={key}
                                onChange={(newText: string): void => {
                                    onTextFieldUpdate(newText, index, "key");
                                }}
                                tabIndex={0}
                                disabled={keyValuePair.disabled}
                                validate={keyTextFieldValidation}
                            />
                            {keyValuePair.disabled! && (
                                <HoverCard className={styles.hoverCard} fixedPlacement={true} placement="bottom">
                                    <>
                                        <Button slot="anchor" appearance="stealth" className={styles.keyValueInfoBtn}>
                                            <Icon name="info" label="Information" />
                                        </Button>
                                        <span>{keyValuePair.informationText}</span>
                                    </>
                                </HoverCard>
                            )}
                            <TextField
                                className={styles.keyValueTextFields}
                                label={valueTextFieldLabel}
                                value={value}
                                onChange={(newText: string): void => {
                                    onTextFieldUpdate(newText, index, "value");
                                }}
                                tabIndex={0}
                                disabled={keyValuePair.disabled}
                                validate={valueTextFieldValidation}
                            />
                            <Button
                                className={styles.keyValueClearBtn}
                                appearance="stealth"
                                onClick={(): void | Promise<void> => {
                                    onRemove(index);
                                }}
                                tabIndex={0}
                                disabled={keyValuePair.required}
                                data-testid="DeleteButton"
                            >
                                <Icon name="clear" label="Clear" />
                            </Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
