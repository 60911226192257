import type { SeverityLevel } from "@empowerment/telemetry";
import type { ReactElement } from "react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import React from "react";
import styles from "./styles.module.scss";
import { ErrorPage } from "~/components/ErrorPage";
import { stringConstants } from "~/locale/stringConstants";

type ErrorBoundaryProps = {
    onException: (
        exception: Error,
        properties?: { [key: string]: unknown },
        measurements?: { [key: string]: number },
        severityLevel?: SeverityLevel
    ) => void;
    telemetryContext?: { [key: string]: unknown };
    debugMode?: boolean;
};

type ErrorBoundaryState = {
    hasError?: boolean;
    error?: Error;
};

export class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
        };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ hasError: true, error: error });
        let telemetryContext = {};
        if (this.props.telemetryContext !== undefined) {
            telemetryContext = { ...this.props.telemetryContext };
        }
        telemetryContext = {
            ...telemetryContext,
            handledAt: errorInfo.componentStack,
        };
        this.props.onException(error, telemetryContext);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    render(): ReactElement {
        const child = React.Children.only(this.props.children) as ReactElement;
        if (this.state.hasError === true) {
            return <ErrorPage messageContent={this.getErrorMessage()} />;
        }
        return child;
    }

    private getErrorMessage(): ReactElement | string {
        if (this.props.debugMode === true && this.state.error !== undefined && this.state.error.message !== "") {
            return this.state.error.message;
        }
        return (
            <div className={styles.defaultMessageDiv}>{stringConstants.components.App.ErrorBoundary.ErrorMessage}</div>
        );
    }
}
