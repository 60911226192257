import type { ReactElement } from "react";
import styles from "./styles.module.scss";
import { Text } from "~/enabler/Text";
import { stringConstants } from "~/locale/stringConstants";

export type UnAuthorizedProps = {
    userAlias: string;
};

export function UnAuthorized(props: UnAuthorizedProps): ReactElement {
    const { userAlias } = props;
    return (
        <>
            <div />
            <div className={styles.root}>
                <Text tag="h4" appearance="heading-4" className={styles.heading}>
                    {`Hello, ${userAlias}! ${stringConstants.components.UnAuthorized.Message}`}
                </Text>
            </div>
        </>
    );
}
