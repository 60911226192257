import { jsx as _jsx } from "react/jsx-runtime";
import { button } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyButton = scopeForReact(button);
function ButtonComponent(props) {
    const { appearance, children, ...rest } = props;
    return (_jsx(HarmonyButton, { appearance: appearance ?? "secondary", ...rest, children: children }));
}
export const Button = typedMemo(ButtonComponent);
