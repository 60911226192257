import { TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useContext, useCallback, useState, useMemo } from "react";
import { Button } from "~/enabler/Button";
import { Dialog } from "~/enabler/Dialog";
import { Text } from "~/enabler/Text";
import { TextField } from "~/enabler/TextField";
import { stringConstants } from "~/locale/stringConstants";
import { EnvironmentContext, GlobalEnvironment } from "~/models/Environment";
import { PageContext } from "~/models/Page";
import { EventState, EventStatus } from "~/models/TelemetryContext";
import { deleteEnvironment, fetchEnvironmentList, renameEnvironment } from "~/utils/environmentUtils";
import { TelemetryEvents, TelemetryServiceTrackEvent, TelemetryTaskFlows } from "~/utils/telemetryUtils";
// import { TelemetryServiceTrackEvent, TelemetryEvents } from "~/utils/telemetryUtils";

type EnvironmentsPanelDialogProps = {
    actionType: "rename" | "delete";
    closeDialog: () => void;
    setActionType: React.Dispatch<React.SetStateAction<"rename" | "delete" | undefined>>;
};

export function EnvironmentsPanelDialog(props: EnvironmentsPanelDialogProps): ReactElement {
    const { actionType, closeDialog, setActionType } = props;
    const {
        environmentName,
        selectedEnvironmentIndex,
        environmentList,
        setSelectedEnvironmentIndex,
        setEnvironmentList,
    } = useContext(EnvironmentContext);
    const { pageName } = useContext(PageContext);

    const selectedEnvironmentName = useMemo(
        () => environmentList[selectedEnvironmentIndex].name,
        [environmentList, selectedEnvironmentIndex]
    );
    const exceptionTelemetryContext = useMemo(
        () => ({
            page: pageName,
            environment: environmentName,
        }),
        [pageName, environmentName]
    );

    const [newEnvironmentName, setNewEnvironmentName] = useState<string>(selectedEnvironmentName);

    const deleteOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.EnvironmentsPanelHeader.DeleteEnvironmentButtonClicked, {
            page: pageName,
            environment: environmentName,
            eventState: EventState.Initiated,
        });
        const deleteEnvironmentStartTime = Date.now();
        let eventStatus;
        try {
            deleteEnvironment(selectedEnvironmentIndex);
            setSelectedEnvironmentIndex(0);
            setEnvironmentList(fetchEnvironmentList() ?? [GlobalEnvironment]);
            setActionType(undefined);
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - deleteEnvironmentStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.EnvironmentsPanelHeader.DeleteEnvironmentButtonClicked,
            { page: pageName, eventState: EventState.Completed, eventStatus: eventStatus },
            { Duration: Date.now() - deleteEnvironmentStartTime },
            TelemetryTaskFlows.DeleteEnvironment.LastStep
        );
    }, [
        setSelectedEnvironmentIndex,
        setEnvironmentList,
        setActionType,
        selectedEnvironmentIndex,
        environmentName,
        exceptionTelemetryContext,
        pageName,
    ]);

    const renameOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.EnvironmentsPanelHeader.RenameEnvironmentButtonClicked, {
            page: pageName,
            environment: environmentName,
            eventState: EventState.Initiated,
        });
        const renameEnvironmentStartTime = Date.now();
        let eventStatus;
        try {
            renameEnvironment(selectedEnvironmentIndex, newEnvironmentName);
            setEnvironmentList(fetchEnvironmentList() ?? [GlobalEnvironment]);
            setActionType(undefined);
            eventStatus = EventStatus.Success;
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - renameEnvironmentStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.EnvironmentsPanelHeader.RenameEnvironmentButtonClicked,
            { page: pageName, eventState: EventState.Completed, eventStatus: eventStatus },
            { Duration: Date.now() - renameEnvironmentStartTime },
            TelemetryTaskFlows.RenameEnvironment.LastStep
        );
    }, [
        newEnvironmentName,
        selectedEnvironmentIndex,
        setActionType,
        setEnvironmentList,
        environmentName,
        exceptionTelemetryContext,
        pageName,
    ]);

    const textFieldOnChange = useCallback((value: string) => {
        setNewEnvironmentName(value);
    }, []);

    let heading = stringConstants.components.Common.Environment.RenameEnvironment;
    let content = (
        <TextField
            label={stringConstants.components.Common.Environment.EnvironmentName}
            value={newEnvironmentName}
            required={true}
            onChange={textFieldOnChange}
        />
    );
    let primaryButtonOnClick = renameOnClick;
    let primaryButtonText = stringConstants.components.Common.Save;

    if (actionType === "delete") {
        heading = `Delete "${selectedEnvironmentName}"?`;
        content = (
            <Text
                tag="p"
                appearance="paragraph"
            >{`${stringConstants.components.App.CollectionPanel.DeleteDialogMessage}  ${selectedEnvironmentName}?`}</Text>
        );
        primaryButtonOnClick = deleteOnClick;
        primaryButtonText = stringConstants.components.Common.Delete;
    }

    return (
        <Dialog heading={heading} open={true} onHide={closeDialog}>
            <>
                {content}
                <Button slot="footer" appearance="primary" onClick={primaryButtonOnClick}>
                    {primaryButtonText}
                </Button>
                <Button slot="footer" onClick={closeDialog}>
                    {stringConstants.components.Common.Cancel}
                </Button>
            </>
        </Dialog>
    );
}
