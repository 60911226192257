import { jsx as _jsx } from "react/jsx-runtime";
import { flyInPanel } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyPanel = scopeForReact(flyInPanel);
function PanelComponent(props) {
    const { children, size, heading, panelStyles, open, onHeShow, onHeHide, ...rest } = props;
    return (_jsx(HarmonyPanel, { size: size, heading: heading, className: panelStyles, open: open, onHeShow: onHeShow, onHeHide: onHeHide, ...rest, children: children }));
}
export const Panel = typedMemo(PanelComponent);
