import type { ReactElement } from "react";
import styles from "./styles.module.scss";
import { EnvironmentsPanel } from "~/components/EnvironmentsPanel";
import { VariablePanel } from "~/components/VariablePanel";

export function EnvironmentsContent(): ReactElement {
    return (
        <>
            <EnvironmentsPanel />
            <div className={styles.content}>
                <VariablePanel />
            </div>
        </>
    );
}
