import type { Option as OptionClass } from "@harmony/enablers/components/option/option";
import type { Select as SelectClass } from "@harmony/enablers/components/select/select";
import { select, option } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { useCallback } from "react";
import { eventOnlyOnCurrentTarget } from "~/enabler/eventOnlyOnCurrentTarget";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { isDefined } from "~/utils/isDefined";
import { typedMemo } from "~/utils/typedMemo";

const HarmonySelect = scopeForReact(select);
const HarmonyOption = scopeForReact(option);

export type SelectOptionConfig = {
    disabled?: boolean;
    value: string | number | undefined;
} & BaseEnablerAttributes;

type SelectProps = {
    label?: string;
    hideLabel?: boolean;
    freeform?: boolean;
    placeholder?: string;
    position?: "top" | "bottom";
    optionValues: SelectOptionConfig[];
    autocomplete?: select["autocomplete"];
    value?: string | number | undefined;
    onChange?: (value: unknown) => void;
    onBlur?: (value: unknown) => void;
    onSelected?: (value: unknown, index: number) => void;
    hideEmptyState?: boolean;
} & BaseEnablerAttributes;

function SelectComponent(props: SelectProps): ReactElement {
    const { optionValues, onChange, onBlur, onSelected, hideEmptyState, ...rest } = props;

    const onHeChange = useCallback(
        (e: CustomEvent<unknown>) => {
            if (isDefined(onChange)) {
                const target = e.target as SelectClass;
                onChange(target.value);
            }
        },
        [onChange]
    );

    const onHeBlur: React.FocusEventHandler<SelectClass> = useCallback(
        (e) => {
            if (isDefined(onBlur)) {
                const target = e.target as unknown as SelectClass;
                onBlur(target.value);
            }
        },
        [onBlur]
    );

    const onHeSelected = useCallback(
        (e: CustomEvent<unknown>, index: number) => {
            if (isDefined(onSelected)) {
                const target = e.target as OptionClass;
                onSelected(target.value, index);
            }
        },
        [onSelected]
    );

    return (
        <HarmonySelect
            {...rest}
            onBlur={eventOnlyOnCurrentTarget(onHeBlur)}
            onHeChange={eventOnlyOnCurrentTarget(onHeChange)}
            hideEmptyState={hideEmptyState}
        >
            {optionValues.map((item, optionIndex) => {
                const { value, ...optionRest } = item;
                return (
                    <HarmonyOption
                        key={value}
                        value={value}
                        onHeSelected={eventOnlyOnCurrentTarget((e: CustomEvent<unknown>): void => {
                            onHeSelected(e, optionIndex);
                        })}
                        {...optionRest}
                    >
                        {value}
                    </HarmonyOption>
                );
            })}
        </HarmonySelect>
    );
}

export const Select = typedMemo(SelectComponent);
