import type { SyntheticEvent } from "react";

/**
 * Harmony custom events bubble. This means something like a Dialog which has a `he-hide` event will be triggered by a child such as a MessageBar which also emits an `he-hide` event. This is "by design / won't fix" according to Harmony.
 *
 * This method is used to wrap any event handler in a Harmony component so that it doesn't get triggered by children that emit the same event.
 */
// Bug that causes this to be necessary - https://microsoft.visualstudio.com/OSGS/_workitems/edit/41422944
export function eventOnlyOnCurrentTarget<TEvent extends Event | SyntheticEvent>(
    handler: ((ev: TEvent) => void | Promise<void>) | undefined
): ((ev: TEvent) => void) | undefined {
    if (handler === undefined) {
        return undefined;
    }
    return (ev: TEvent): void => {
        if (ev.target === ev.currentTarget) {
            void handler(ev);
        }
    };
}
