import type { ITelemetryConfig } from "@empowerment/telemetry";
import { appConfig } from "~/config/appConfig";
import type { IServiceTreeContext } from "~/models/TelemetryContext";

export const telemetryConfig = (): ITelemetryConfig => {
    return {
        AIKey: appConfig().aiKey,
    };
};

export const serviceTreeContext: IServiceTreeContext = {
    Organization: "Commerce + Ecosystems",
    ServiceGroup: "Commerce Partner & Seller Experience",
    TeamGroup: "Proposal Management Operations Blade",
    Service: "Proposal Management Operations Workspace",
    EnvironmentName: process.env.ENVIRONMENT!,
};
