import type { TelemetryEvent } from "@empowerment/telemetry";
import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type { ITelemetryItem } from "@microsoft/applicationinsights-web";
import { serviceTreeContext, telemetryConfig } from "~/config/telemetryConfig";
import type { ITelemetryContext } from "~/models/TelemetryContext";

/**
 * Update app insights to include Service Tree data
 * @param envelope
 * @returns
 */
export function serviceTreeDataTelemetryInitializer(envelope: ITelemetryItem): boolean {
    const envelopeData: Record<string, unknown> = {
        ...serviceTreeContext,
    };

    if (envelope.data === undefined) {
        return false;
    }
    for (const key in envelopeData) {
        envelope.data[`${key}`] = envelopeData[`${key}`];
    }

    return true;
}

export function bootTelemetry(): void {
    TelemetryService.initialize(telemetryConfig(), false);
    //TelemetryService.addTelemetryInitializer(serviceTreeDataTelemetryInitializer);
}

// Workaround for logging HEART metrics w/out enabling ClickAnalytics
function addHEARTProperty(taskName: string): { [key: string]: unknown } {
    const heartProps = {
        actionType: "CL",
        parentId: taskName,
    };
    return heartProps;
}

export function TelemetryServiceTrackEvent(
    event: TelemetryEvent,
    properties?: ITelemetryContext,
    measurements?: { [key: string]: number },
    taskName?: string // if this value is passed it, this task will be used to track Task Success HEART metric on Grafana
): void {
    if (taskName !== undefined) {
        //TelemetryService.trackEvent(event, { ...properties, ...addHEARTProperty(taskName) }, measurements);
    } else {
        //TelemetryService.trackEvent(event, properties, measurements);
    }
}

export function TelemetryServiceTrackTraceInformation(
    message: string,
    sevLevel?: SeverityLevel,
    properties?: { [key: string]: unknown }
): void {
    if (sevLevel === undefined) {
        sevLevel = SeverityLevel.Information;
    }
    message = `${getFormatDateTimeWithMilliseconds()} - ${message}`;
    TelemetryService.trackTrace(message, sevLevel, properties);
}

export function getFormatDateTimeWithMilliseconds(): string {
    const now = new Date();

    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
    };

    const formattedDateTime = now.toLocaleString("en-US", options);
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    return `${formattedDateTime}.${milliseconds}`;
}
