export const stringConstants = {
    components: {
        App: {
            CollectionPage: {
                AddARequest: "Add a request",
                EmptyCollectionMessage: "This folder is empty.",
                AddARequestSuffix: "to start working.",
            },
            CollectionPanel: {
                DeleteDialogMessage: "Are you sure you want to delete",
                UnsavedChanges: "Unsaved Changes",
                SaveAndContinue: "Save and Continue",
                ContinueWithoutSaving: "Continue without Saving",
                DialogMessage:
                    "You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.",
            },
            RequestInfo: {
                UrlPlaceholderText: "Please enter a url.",
                UrlLabel: "Url Select TextField",
            },
            RequestPanel: {
                MSCV: "MS-CV",
                correlationId: "x-ms-correlation-id",
                autoPopulatedMessage: "<auto generated when request is sent>",
                mscvInfoText:
                    "This is the correlation vector to track requests across service boundaries. This is a mandatory header parameter while accessing any service api. This value is auto generated when request is sent. You should check the MS-CV value after request is being sent. System assigns extended value for the CV as initial value is used for internal purpose.",
                correlationIdInfoText:
                    "This is the correlation id to track requests across service boundaries. This is a non-mandatory header parameter while accessing any service api. You can delete it if you don't want to be send this with header. This is passed to support legacy correlation id. This value is auto generated when request is sent. You should check the x-ms-correlation-id value after request is being sent.",
            },
            RequestPanelHeaderDialog: {
                DeleteDialogMessage: "Are you sure you want to delete",
                ResetDialogMessage: "Are you sure you want to reset? Unsaved changes will be lost.",
                RequestNamePlaceholderText: "Please enter a request name.",
                CollectionNameTextFieldPlaceholderText: "Please enter a collection name.",
                CollectionNameSelectPlaceholderText: "Please select a collection name.",
                ParentFolder: "Please select the parent folder for the request to be saved in.",
            },
            Header: {
                HelpDocs: "Help Documentation",
                RequestAccess: "Request Access",
                ContactSupport: "Contact Support",
                PrivacyPolicy: "Privacy Policy",
                Language: "Language",
                SignOut: "Sign Out",
                Help: "Help",
                Settings: "Settings",
                Profile: "Profile",
                ApixTitle: "API eXplorer V1 (Alpha)",
                TeamTaskTitle: "Team Task (Beta)",
                EmpowermentTitle: "Empowerment Workspace",
                ApprovalTitle: "Approvals Management Workspace",
                AgreementTitle: "Agreement Workspace",
            },
            ErrorBoundary: {
                ErrorMessage:
                    "Something went wrong. Please try refreshing the page. If that doesn't help, contact the Support Team.",
            },
            ErrorMessage: {
                UnknownError:
                    "Something went wrong. Please retry after sometime. If the issue persists, contact Support Team.",
            },
        },
        NoApp: {
            Message: "APIX Workspace is not available. Please contact admin to enable it.",
        },
        UnAuthorized: {
            Message: " you not a member of ApiX Alpha User Group. Please contact admin to join.",
        },
        Common: {
            Save: "Save",
            Delete: "Delete",
            Reset: "Reset",
            Cancel: "Cancel",
            Request: {
                RequestName: "Request name",
                AddRequest: "Add Request",
                SaveRequest: "Save Request",
                SaveAsRequest: "Save As",
                RenameRequest: "Rename Request",
                DeleteRequest: "Delete Request",
                ResetRequest: "Reset Request",
                ParentFolder: "Parent Folder",
            },
            Collection: {
                CollectionName: "Collection name",
                AddCollection: "Add Collection",
                RenameCollection: "Rename Collection",
                DeleteCollection: "Delete Collection",
                AddFolder: "Add Folder",
            },
            Environment: {
                EnvironmentName: "Environment name",
                NewEnvironment: "New Environment",
                RenameEnvironment: "Rename Environment",
                DeleteEnvironment: "Delete Environment",
            },
        },
    },
};
