import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import type { ButtonActionType } from "~/components/RequestPanelHeader/RequestPanelHeader";
import { defaultFileRequestType, defaultRequest, defaultSystemData } from "~/Constants";
import { Button } from "~/enabler/Button";
import { Dialog } from "~/enabler/Dialog";
import { Select } from "~/enabler/Select";
import { Text } from "~/enabler/Text";
import { TextField } from "~/enabler/TextField";
import { stringConstants } from "~/locale/stringConstants";
import type { Collection, Items, ServiceResponse } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
import { RequestContext } from "~/models/IRequest";
import { PageContext } from "~/models/Page";
import { EventState, EventStatus } from "~/models/TelemetryContext";
import { getIndexFromRepo, updateIndexToRepo1, updateIndexToRepo2 } from "~/services/Repository/DataRepository";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";
//TODO:(sivad) refactor : modifyRequestInCollection
import { modifyRequestInCollection } from "~/utils/collectionUtils";
import {
    addItemToSelectedItem,
    createItemId,
    flattenItems,
    getCurrentState,
    getFormattedCollectionKey,
    recursivelyFindItemById,
    removeItemById,
    splitItemIds,
} from "~/utils/DataUtils/DataUtils";
import { isDefined } from "~/utils/isDefined";
import { TelemetryEvents, TelemetryServiceTrackEvent, TelemetryTaskFlows } from "~/utils/telemetryUtils";

type RequestPanelHeaderDialogProps = {
    actionType: ButtonActionType;
    closeDialog: () => void;
    exceptionTelemetryContext: { [key: string]: unknown };
    setActionType: React.Dispatch<React.SetStateAction<ButtonActionType | undefined>>;
};

export function RequestPanelHeaderDialog(props: RequestPanelHeaderDialogProps): ReactElement {
    const { actionType, closeDialog, exceptionTelemetryContext, setActionType } = props;
    const { user } = useAuthenticationContext();
    const {
        selectedCollectionIndex,
        selectedRequestIndex,
        setCollectionList,
        setSelectedCollectionIndex,
        setSelectedRequestIndex,
        collectionList,
        collectionName,
        setCollectionName,
    } = useContext(CollectionContext);
    const { requestName, updateRequest, updateRequestName, updateInitialRequest } = useContext(RequestContext);
    const { pageName } = useContext(PageContext);
    const { request } = useContext(RequestContext);
    const [requestNameToSave, setRequestNameToSave] = useState<string>(requestName === "" ? request.url : requestName);
    const [collectionNameToSave, setCollectionNameToSave] = useState<string>(collectionName);
    const [selectedCollectionIndexToSave, setSelectedCollectionIndexToSave] = useState<string>(selectedCollectionIndex);
    const [selectedParentFolderId, setSelectedParentFolderId] = useState<string>("");
    const [selectedParentFolderName, setSelectedParentFolderName] = useState<string>("");
    const [saveAsRequestName, setSaveAsRequestName] = useState<string>("");
    const onSaveAsRequestNameChange = useCallback((newValue: string) => {
        TelemetryService.trackTrace("User changes request name", SeverityLevel.Information, {
            newValue: newValue,
        });
        setSaveAsRequestName(newValue);
    }, []);

    const onRequestNameChange = useCallback((newValue: string) => {
        TelemetryService.trackTrace("User changes request name", SeverityLevel.Information, {
            newValue: newValue,
        });
        setRequestNameToSave(newValue);
    }, []);

    const onCollectionNameChange = useCallback((newValue: unknown) => {
        TelemetryService.trackTrace("User changes collection name", SeverityLevel.Information, {
            newValue: newValue,
        });
        setCollectionNameToSave(newValue as string);
    }, []);

    /*     const onCollectionOptionSelected = useCallback(
        (newValue: unknown, index: string) => {
            onCollectionNameChange(newValue);
            setSelectedCollectionIndexToSave(index);
        },
        [onCollectionNameChange]
    ); */

    const deleteRequest = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestPanelHeader.DeleteRequestButtonClicked, {
            collectionIndex: selectedCollectionIndex,
            requestIndex: selectedRequestIndex,
            page: pageName,
            eventState: EventState.Initiated,
        });
        const deleteRequestStartTime = Date.now();
        let eventStatus;
        try {
            //new code
            const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex);
            let currentSelectionKey = selectedCollectionIndex;
            if (currentState.currentHighestSelectionType === "collection") {
                currentSelectionKey = selectedCollectionIndex;
            } else if (
                currentState.currentHighestSelectionType === "folder" ||
                currentState.currentHighestSelectionType === "file"
            ) {
                currentSelectionKey = selectedRequestIndex;
            }
            const selectedItem = recursivelyFindItemById(splitItemIds(currentSelectionKey).itemId, collectionList);

            if (selectedItem !== undefined) {
                selectedItem.action = "delete"; //api will determine the needed action.
                selectedItem.contentJson = JSON.stringify(selectedItem);

                //Todo: (sivad) : request id may change after refresh process. so need to get the id back.
                updateIndexToRepo2(selectedItem)
                    .then((serviceResponse: ServiceResponse | undefined) => {
                        if (serviceResponse !== undefined) {
                            //Todo: (sivad): unhappy
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            //remove locally
            const currentCollection = collectionList.find(
                (item) => item.id === splitItemIds(selectedCollectionIndex).itemId
            );
            //alert(`currentCollection = ${selectedCollectionIndex}, ${currentCollection}`)
            if (currentCollection !== undefined) {
                removeItemById(currentCollection.items, splitItemIds(selectedRequestIndex).itemId);
                setSelectedCollectionIndex(
                    getFormattedCollectionKey(splitItemIds(selectedRequestIndex).itemTopParentId)
                );
                setSelectedRequestIndex("");
                setCollectionList(collectionList); //Todo: (sivad): bug WHEN REFRESHING THE SELECTION STATE IS NOT MAINTAINED
            }

            //deleteCollection(selectedCollectionIndex);
            //let selectedItem = getSelectedItem(selectedCollectionIndex,selectedRequestIndex,collectionList);
            //selectedItem.items?.i
            //selectedItem = undefined;

            //update the repo and context
            //asyncUpdateIndexToRepo(collectionList);
            //Todo: (sivad) : remove from local
            //asyncUpdateIndexToRepo1(selectedItem);
            //setSelectedCollectionIndex("");
            //setSelectedRequestIndex("");
            //setCollectionList(colList ?? [])
            //Todo: (sivad) : this will get only indexes and loose all items in draft
            /*         (async () => {
                        const colList = await getIndexFromRepo()
                        setCollectionList(colList ?? []);
                    })(); */
            setCollectionName("");
            updateRequest(structuredClone(defaultRequest));
            updateInitialRequest(structuredClone(defaultRequest));
            updateRequestName("");
            setActionType(undefined);

            //old code
            /*  const splitArray = selectedRequestIndex.split("_");
            const treeItemId =  splitArray[2];
            
            deleteRequestFromCollection(selectedCollectionIndex, treeItemId).then
            (colList => {
                updateRequest(structuredClone(defaultRequest));
                updateInitialRequest(structuredClone(defaultRequest));
                updateRequestName("");
                setSelectedCollectionIndex("");
                setSelectedRequestIndex("");
                setCollectionName("");
                //Todo: (sivad): take from response
                (async () => {
                    const colList = await getIndexFromRepo()
                    setCollectionList(colList ?? []);
                })();
                //setCollectionList(fetchCollectionList() ?? []);
                setCollectionList(colList ?? []);
                eventStatus = EventStatus.Success;
                setActionType(undefined);
            }); */
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - deleteRequestStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.RequestPanelHeader.DeleteRequestButtonClicked,
            {
                collectionIndex: selectedCollectionIndex,
                requestIndex: selectedRequestIndex,
                page: pageName,
                eventState: EventState.Completed,
                eventStatus: eventStatus,
            },
            { Duration: Date.now() - deleteRequestStartTime },
            TelemetryTaskFlows.DeleteRequest.LastStep
        );
    }, [
        exceptionTelemetryContext,
        pageName,
        selectedCollectionIndex,
        selectedRequestIndex,
        setActionType,
        setCollectionList,
        setCollectionName,
        setSelectedCollectionIndex,
        setSelectedRequestIndex,
        updateInitialRequest,
        updateRequest,
        updateRequestName,
        collectionList,
    ]);

    const resetRequest = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestPanelHeader.ResetRequestButtonClicked, {
            collectionIndex: selectedCollectionIndex,
            requestIndex: selectedRequestIndex,
            page: pageName,
            eventState: EventState.Initiated,
        });
        const resetRequestStartTime = Date.now();
        let eventStatus;
        try {
            updateRequest(structuredClone(defaultRequest));
            updateInitialRequest(structuredClone(defaultRequest));
            if (selectedRequestIndex > "") {
                modifyRequestInCollection(
                    selectedCollectionIndex,
                    structuredClone(defaultRequest),
                    requestName,
                    "update",
                    selectedRequestIndex
                );
                //setCollectionList(fetchCollectionList() ?? []);

                getIndexFromRepo()
                    .then((colList: Collection[] | undefined) => {
                        if (colList !== undefined) {
                            setCollectionList(colList);
                            //Todo: (sivad): unhappy
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        return undefined;
                    });

                /*                 (async () => {
                    const colList = await getIndexFromRepo()
                    setCollectionList(colList ?? []);
                })(); */
            }
            setActionType(undefined);
            eventStatus = EventStatus.Success;
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - resetRequestStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.RequestPanelHeader.ResetRequestButtonClicked,
            {
                collectionIndex: selectedCollectionIndex,
                requestIndex: selectedRequestIndex,
                page: pageName,
                eventState: EventState.Completed,
                eventStatus: eventStatus,
            },
            { Duration: Date.now() - resetRequestStartTime },
            TelemetryTaskFlows.ResetRequest.LastStep
        );
    }, [
        exceptionTelemetryContext,
        pageName,
        requestName,
        selectedCollectionIndex,
        selectedRequestIndex,
        setActionType,
        setCollectionList,
        updateInitialRequest,
        updateRequest,
    ]);

    const renameRequest = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestPanelHeader.RenameRequestButtonClicked, {
            collectionIndex: selectedCollectionIndex,
            requestIndex: selectedRequestIndex,
            page: pageName,
            eventState: EventState.Initiated,
        });
        const renameRequestStartTime = Date.now();
        let eventStatus;
        try {
            //new code
            const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex);
            let currentSelectionKey = selectedCollectionIndex;
            if (currentState.currentHighestSelectionType === "collection") {
                currentSelectionKey = selectedCollectionIndex;
            } else if (
                currentState.currentHighestSelectionType === "folder" ||
                currentState.currentHighestSelectionType === "file"
            ) {
                currentSelectionKey = selectedRequestIndex;
            }
            const selectedItem = recursivelyFindItemById(splitItemIds(currentSelectionKey).itemId, collectionList);

            if (selectedItem !== undefined) {
                selectedItem.name = requestNameToSave;
                selectedItem.action = "update"; //api will determine the needed action. parentId is already should be set by now
            }

            //update the repo and context
            //asyncUpdateIndexToRepo1(selectedItem);
            updateIndexToRepo1(selectedItem)
                .then((collection: Collection[] | undefined) => {
                    if (collection !== undefined) {
                        //Todo: (sivad): unhappy
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            //asyncUpdateIndexToRepo(collectionList);
            setCollectionList(collectionList);
            updateRequestName(requestNameToSave);
            setActionType(undefined);

            /* old code
            const splitArray = selectedRequestIndex.split("_");
            const treeItemId =  splitArray[2];

            renameFileTreeItemInCollection(selectedCollectionIndex, treeItemId, requestNameToSave).then
            (colList => {
                setCollectionList(colList ?? []);
            });
            updateRequestName(requestNameToSave);
            setActionType(undefined);
            eventStatus = EventStatus.Success;*/
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - renameRequestStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.RequestPanelHeader.RenameRequestButtonClicked,
            {
                collectionIndex: selectedCollectionIndex,
                requestIndex: selectedRequestIndex,
                page: pageName,
                eventState: EventState.Completed,
                eventStatus: eventStatus,
            },
            { Duration: Date.now() - renameRequestStartTime },
            TelemetryTaskFlows.RenameRequest.LastStep
        );
    }, [
        exceptionTelemetryContext,
        pageName,
        requestNameToSave,
        selectedCollectionIndex,
        selectedRequestIndex,
        setActionType,
        setCollectionList,
        updateRequestName,
        collectionList,
    ]);

    const saveRequest = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.RequestPanelHeader.SaveRequestButtonClicked, {
            collectionIndex: selectedCollectionIndexToSave,
            page: pageName,
            eventState: EventState.Initiated,
        });
        const saveRequestStartTime = Date.now();
        if (request.systemdata === undefined) {
            request.systemdata = defaultSystemData;
        }
        let alias = user?.email;
        alias = user?.email.substring(0, user.email.indexOf("@"));
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        alias = alias === undefined ? "" : alias;
        request.systemdata.lastSavedBy = alias === null ? "" : alias;
        const now: Date = new Date();
        request.systemdata.lastSavedOn = now.toISOString();

        let eventStatus;
        try {
            let result;
            if (collectionList.length === 0) {
                result = modifyRequestInCollection(
                    "",
                    structuredClone(request),
                    requestNameToSave,
                    "add",
                    undefined,
                    collectionNameToSave
                );
            } else {
                result = modifyRequestInCollection(
                    selectedCollectionIndexToSave,
                    structuredClone(request),
                    requestNameToSave,
                    "add"
                );
            }
            if (isDefined(result)) {
                updateRequestName(requestNameToSave);
                updateInitialRequest(structuredClone(request));
                setSelectedCollectionIndex(result.collectionIndex);
                setSelectedRequestIndex(result.requestIndex);
                setCollectionName(result.collectionName);

                getIndexFromRepo()
                    .then((colList: Collection[] | undefined) => {
                        if (colList !== undefined) {
                            setCollectionList(colList);
                            //Todo: (sivad): unhappy
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        return undefined;
                    });

                /*                 (async () => {
                    const colList = await getIndexFromRepo()
                    setCollectionList(colList ?? []);
                })(); */
                //setCollectionList(fetchCollectionList() ?? []);
                setActionType(undefined);
                eventStatus = EventStatus.Success;
            } else {
                setActionType(undefined);
                throw new Error("Failed to save request");
            }
        } catch (e) {
            const error = e as Error;
            TelemetryService.trackException(error, exceptionTelemetryContext, {
                Duration: Date.now() - saveRequestStartTime,
            });
            eventStatus = EventStatus.Failed;
        }
        TelemetryServiceTrackEvent(
            TelemetryEvents.RequestPanelHeader.SaveRequestButtonClicked,
            {
                page: pageName,
                eventState: EventState.Completed,
                eventStatus: eventStatus,
            },
            { Duration: Date.now() - saveRequestStartTime },
            TelemetryTaskFlows.SaveRequest.LastStep // only log parentId if this is invoked from SaveRequestDialog
        );
    }, [
        collectionList.length,
        collectionNameToSave,
        exceptionTelemetryContext,
        pageName,
        request,
        requestNameToSave,
        selectedCollectionIndexToSave,
        setActionType,
        setCollectionList,
        setCollectionName,
        setSelectedCollectionIndex,
        setSelectedRequestIndex,
        updateInitialRequest,
        updateRequestName,
        user,
    ]);

    // saveAsRequest - create request under specific parent with this request obecjt insted of default request object.
    // TODO: same as collection panel add request (close enough)
    const saveAsRequest = useCallback(() => {
        if (request.systemdata === undefined) {
            request.systemdata = defaultSystemData;
        }
        let alias = user?.email;
        alias = user?.email.substring(0, user.email.indexOf("@"));
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        alias = alias === undefined ? "" : alias;
        request.systemdata.lastSavedBy = alias === null ? "" : alias;
        const now: Date = new Date();
        request.systemdata.lastSavedOn = now.toISOString();
        const newRequest = structuredClone(request);
        const newItem = addItemToSelectedItem(collectionList, selectedParentFolderId, defaultFileRequestType);
        if (newItem !== undefined) {
            newItem.name = saveAsRequestName;
            newItem.contentJson = JSON.stringify(newRequest);

            //item id will be generated by service and ignore the temporary current one. so need to get the id back.
            updateIndexToRepo2(newItem)
                .then((serviceResponse: ServiceResponse | undefined) => {
                    if (serviceResponse !== undefined) {
                        newItem.id = serviceResponse.id;
                        const newRequestId = createItemId(newItem.type, newItem.id, selectedParentFolderId);
                        //setSelectedRequestIndex(newRequestId);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

            // updateIndexToRepo1(newItem)
            // .then((collection: Collection[] | undefined) => {
            //     if (collection !== undefined) {
            //         //Todo: (sivad): unhappy
            //     }
            // })
            // .catch((e) => {
            //     console.error(e);
            // });
            // setCollectionList(collectionList);
            // updateRequestName(saveAsRequestName);
            setActionType(undefined);
            //TODO: (sivad) - navigate to the newly created one?
        }
    }, [
        selectedParentFolderId,
        //selectedParentFolderName,
        saveAsRequestName,
        //selectedCollectionIndex,
        //selectedRequestIndex,
        setActionType,
        //setCollectionList,
        //updateRequestName,
        collectionList,
        request,
        user,
    ]);

    const selectedTree = splitItemIds(selectedRequestIndex);
    const currentCollection = collectionList.find((item) => item.id === selectedTree.itemTopParentId);
    const selectedItem = recursivelyFindItemById(selectedTree.itemId, currentCollection?.items); // optimization to find it within the selected collection
    const parentFoldersList = flattenItems(collectionList);

    useEffect(() => {
        const name = selectedItem?.name ?? "";
        const foldId = selectedItem?.parentId ?? "";
        const folName = selectedItem?.pathName ?? "";
        setSaveAsRequestName(saveAsRequestName === "" ? name : saveAsRequestName);
        setSelectedParentFolderId(selectedParentFolderId === "" ? foldId : selectedParentFolderId);
        setSelectedParentFolderName(selectedParentFolderName === "" ? folName : selectedParentFolderName);
    }, [
        selectedCollectionIndex,
        selectedRequestIndex,
        selectedParentFolderId,
        selectedParentFolderName,
        saveAsRequestName,
        selectedItem?.name,
        selectedItem?.parentId,
        selectedItem?.pathName,
    ]);

    // Default content = reset request dialog
    let heading = stringConstants.components.Common.Request.ResetRequest;
    let content = (
        <Text tag="p" appearance="paragraph">
            {stringConstants.components.App.RequestPanelHeaderDialog.ResetDialogMessage}
        </Text>
    );
    let primaryButtonOnClick = resetRequest;
    let primaryButtonText = stringConstants.components.Common.Reset;
    let primaryButtonEnabled = true;

    const onParentFolderOptionSelected = useCallback(
        (newValue: unknown, index: number) => {
            setSelectedParentFolderId(parentFoldersList[index].id);
            setSelectedParentFolderName(parentFoldersList[index].pathName);
        },
        [parentFoldersList]
    );

    if (actionType === "delete") {
        // delete request dialog
        heading = `Delete "${requestName}"?`;
        content = (
            <Text tag="p" appearance="paragraph">
                {`${stringConstants.components.App.RequestPanelHeaderDialog.DeleteDialogMessage}  ${requestName}?`}
            </Text>
        );
        primaryButtonOnClick = deleteRequest;
        primaryButtonText = stringConstants.components.Common.Delete;
    } else if (actionType === "rename") {
        // rename request dialog
        heading = stringConstants.components.Common.Request.RenameRequest;
        content = (
            <TextField
                placeHolder={stringConstants.components.App.RequestPanelHeaderDialog.RequestNamePlaceholderText}
                label={stringConstants.components.Common.Request.RequestName}
                value={requestNameToSave}
                required={true}
                onChange={onRequestNameChange}
            />
        );
        primaryButtonOnClick = renameRequest;
        primaryButtonText = stringConstants.components.Common.Save;
    } else if (actionType === "save") {
        const collectionNameValues = collectionList.map((val, index) => ({
            value: val.name,
            index: index,
        }));
        // save request dialog
        heading = stringConstants.components.Common.Request.SaveRequest;
        content = (
            <>
                <TextField
                    placeHolder={stringConstants.components.App.RequestPanelHeaderDialog.RequestNamePlaceholderText}
                    label={stringConstants.components.Common.Request.RequestName}
                    value={requestNameToSave}
                    onChange={onRequestNameChange}
                    required={true}
                />
                {/* Todo: (sivad) allow user manually add collection name */}
                {collectionList.length === 0 ? (
                    <TextField
                        placeHolder={
                            stringConstants.components.App.RequestPanelHeaderDialog
                                .CollectionNameTextFieldPlaceholderText
                        }
                        label={stringConstants.components.Common.Collection.CollectionName}
                        value={collectionNameToSave}
                        onChange={onCollectionNameChange}
                        required={true}
                    />
                ) : (
                    <Select
                        label={stringConstants.components.Common.Collection.CollectionName}
                        optionValues={collectionNameValues}
                        placeholder={
                            stringConstants.components.App.RequestPanelHeaderDialog.CollectionNameSelectPlaceholderText
                        }
                        value={collectionNameToSave}
                        required={true}
                        //Todo: (sivad):
                        //onSelected={onCollectionOptionSelected}
                    />
                )}
            </>
        );
        primaryButtonOnClick = saveRequest;
        primaryButtonText = stringConstants.components.Common.Save;
        primaryButtonEnabled = requestNameToSave !== "" && collectionNameToSave !== "";
    } else if (actionType === "saveas") {
        const collectionNameValues = parentFoldersList.map((val: Items, index) => ({
            value: val.pathName,
            index: index,
        }));
        heading = stringConstants.components.Common.Request.SaveAsRequest;
        content = (
            <>
                <TextField
                    placeHolder={stringConstants.components.App.RequestPanelHeaderDialog.RequestNamePlaceholderText}
                    label={stringConstants.components.Common.Request.RequestName}
                    value={saveAsRequestName}
                    onChange={onSaveAsRequestNameChange}
                    required={true}
                />
                {collectionList.length === 0 ? (
                    <TextField
                        placeHolder={
                            stringConstants.components.App.RequestPanelHeaderDialog
                                .CollectionNameTextFieldPlaceholderText
                        }
                        label={stringConstants.components.Common.Collection.CollectionName}
                        value={collectionNameToSave}
                        onChange={onCollectionNameChange}
                        required={true}
                    />
                ) : (
                    <Select
                        label={stringConstants.components.Common.Request.ParentFolder}
                        optionValues={collectionNameValues}
                        placeholder={stringConstants.components.App.RequestPanelHeaderDialog.ParentFolder}
                        value={selectedParentFolderName}
                        required={true}
                        onSelected={onParentFolderOptionSelected}
                    />
                )}
            </>
        );
        primaryButtonOnClick = saveAsRequest;
        primaryButtonText = stringConstants.components.Common.Save;
    }

    return (
        <Dialog heading={heading} open={true} onHide={closeDialog}>
            <>
                {content}
                <Button
                    slot="footer"
                    appearance="primary"
                    onClick={primaryButtonOnClick}
                    disabled={!primaryButtonEnabled}
                >
                    {primaryButtonText}
                </Button>
                <Button slot="footer" onClick={closeDialog}>
                    {stringConstants.components.Common.Cancel}
                </Button>
            </>
        </Dialog>
    );
}
