import { AuthenticationService } from "@empowerment/authenticationservice";
import { TelemetryService } from "@empowerment/telemetry";
import { NavHeader } from "@pmow/components";
import type { ReactElement } from "react";
import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { appConfig } from "~/config/appConfig";
import { Button } from "~/enabler/Button";
import { Divider } from "~/enabler/Divider";
import { stringConstants } from "~/locale/stringConstants";
import { PageNameEnum } from "~/models/Page";
import { useAuthenticationContext } from "~/services/UseAuthenticationContext";
import { getUserPhoto } from "~/services/UserPhoto";

export function TopNavHeader(): ReactElement {
    const page =
        window.location.pathname === "/environments"
            ? PageNameEnum.Environments
            : window.location.pathname === "/teamtask"
            ? PageNameEnum.TeamTask
            : PageNameEnum.Collections;
    const { state, user } = useAuthenticationContext();
    const [userImage, setUserImage] = useState("");

    useEffect(() => {
        async function fetchUserPhoto(): Promise<void> {
            const userImageURL = await getUserPhoto();
            setUserImage(userImageURL);
        }
        if (state === "Loaded") {
            void fetchUserPhoto();
        }
    }, [state]);

    return (
        <NavHeader
            disableSearch={true}
            navTitle={
                page === PageNameEnum.TeamTask
                    ? stringConstants.components.App.Header.TeamTaskTitle
                    : stringConstants.components.App.Header.ApixTitle
            }
            farRightSettings={{
                helpSettings: {
                    iconName: "help",
                    iconLabel: stringConstants.components.App.Header.Help,
                    heading: stringConstants.components.App.Header.Help,
                    size: "small",
                    renderPanelContent: renderHelpPanelContent,
                },
                settingsSettings: {
                    iconName: "settings",
                    iconLabel: stringConstants.components.App.Header.Settings,
                    heading: stringConstants.components.App.Header.Settings,
                    size: "small",
                    renderPanelContent: renderSettingsPanelContent,
                },
                profileSettings: {
                    user: { ...user!, ...{ image: userImage } },
                    logout: AuthenticationService.logout,
                },
            }}
            workspaces={[
                {
                    name: "APIX",
                    title: stringConstants.components.App.Header.ApixTitle,
                    iconName: "website",
                    selected: true,
                    url: `${appConfig().apixApiBase}/collections`,
                },
                {
                    name: "Team Task",
                    title: stringConstants.components.App.Header.TeamTaskTitle,
                    iconName: "website",
                    selected: false,
                    url: `${appConfig().apixApiBase}/teamtask`,
                },
            ]}
        />
    );
}
const openPage = (): void => {
    window.open(
        "https://eng.ms/cid/640a685c-e642-41d9-825d-11f65f8ea80e/fid/57f0554acbdad32f0b184ec7126a4f70a0bb4011ce4ad3d8c488c840d26d55d7",
        "_blank"
    );
};

const renderHelpPanelContent = (): ReactElement => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Button appearance="link" className={styles.panelButton} onClick={openPage}>
                {stringConstants.components.App.Header.HelpDocs}
            </Button>
            <Button appearance="link" className={styles.panelButton} onClick={openPage}>
                {stringConstants.components.App.Header.RequestAccess}
            </Button>
            <Button appearance="link" className={styles.panelButton} onClick={openPage}>
                {stringConstants.components.App.Header.ContactSupport}
            </Button>
            <Divider className={styles.panelButton} />
            <Button appearance="link" className={styles.panelButton}>
                {stringConstants.components.App.Header.PrivacyPolicy}
            </Button>
        </div>
    );
};

const renderSettingsPanelContent = (): ReactElement => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Button appearance="link" className={styles.panelButton}>
                {stringConstants.components.App.Header.Language}
            </Button>
        </div>
    );
};
