import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type { ReactElement } from "react";
import { useContext, useCallback, useState } from "react";
import { defaultRequest } from "~/Constants";
import { Button } from "~/enabler/Button";
import { Dialog } from "~/enabler/Dialog";
import { Text } from "~/enabler/Text";
import { TextField } from "~/enabler/TextField";
import { stringConstants } from "~/locale/stringConstants";
import type { ServiceResponse, Collection } from "~/models/Collection";
import { CollectionContext } from "~/models/Collection";
import { RequestContext } from "~/models/IRequest";
import { updateIndexToRepo1, updateIndexToRepo2 } from "~/services/Repository/DataRepository";
import { getCurrentState, removeItemById, splitItemIds, recursivelyFindItemById } from "~/utils/DataUtils/DataUtils";
import { TelemetryServiceTrackEvent, TelemetryEvents } from "~/utils/telemetryUtils";

type CollectionsPanelDialogProps = {
    actionType: "rename" | "delete";
    closeDialog: () => void;
    setActionType: React.Dispatch<React.SetStateAction<"rename" | "delete" | undefined>>;
};

export function CollectionsPanelDialog(props: CollectionsPanelDialogProps): ReactElement {
    const { actionType, closeDialog, setActionType } = props;
    const {
        selectedCollectionIndex,
        selectedRequestIndex,
        collectionList,
        setCollectionList,
        setSelectedRequestIndex,
        setSelectedCollectionIndex,
        setCollectionName,
    } = useContext(CollectionContext);
    const { updateRequest, updateInitialRequest, updateRequestName } = useContext(RequestContext);

    // Section Note : selection can be at root or child level but current root(collection) and its child(folder) context to be set correctly
    // set the context correctly and determine the selected item name to be displayed
    const currentCollectionItemId = splitItemIds(selectedCollectionIndex).itemId;
    const currentCollection = collectionList.find((item) => item.id === currentCollectionItemId);

    const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex);
    const currentSelectionCollectionKey = selectedCollectionIndex;

    let currentSelectionKey = "";
    if (currentState.currentHighestSelectionType === "collection") {
        currentSelectionKey = selectedCollectionIndex;
    } else if (currentState.currentHighestSelectionType === "folder") {
        //file delete will be handled in request header component
        currentSelectionKey = selectedRequestIndex;
    }
    const currentSelectionItemId = splitItemIds(currentSelectionKey).itemId;
    const selectedItem = recursivelyFindItemById(currentSelectionItemId, collectionList);

    /*Both collection and folder are treated as collections. collection is just a root folder*/
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const selectedItemName = selectedItem === undefined || selectedItem.name === undefined ? "" : selectedItem.name;
    const [newCollectionName, setNewCollectionName] = useState<string>(selectedItemName);
    // end of context setting

    const deleteOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.DeleteButtonOnDeleteCollectionDialogClicked);

        if (selectedItem !== undefined) {
            selectedItem.action = "delete"; //api will determine the needed action.
            selectedItem.contentJson = JSON.stringify(selectedItem);

            updateIndexToRepo2(selectedItem)
                .then((serviceResponse: ServiceResponse | undefined) => {
                    if (serviceResponse !== undefined) {
                        //Todo: (sivad): unhappy
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        //remove locally
        if (currentCollection !== undefined) {
            removeItemById(currentCollection.items, currentSelectionItemId);
            setCollectionList(collectionList);
            setSelectedCollectionIndex(currentSelectionCollectionKey); //keep the selection at collection level since child is deleted
            setSelectedRequestIndex(""); //Todo: (sivad): may be it will good to find the deleted ones parent and set the selection pointer to it
        }
        setCollectionName("");
        updateRequest(structuredClone(defaultRequest));
        updateInitialRequest(structuredClone(defaultRequest));
        updateRequestName("");
        setActionType(undefined);
    }, [
        setActionType,
        setCollectionList,
        setCollectionName,
        setSelectedCollectionIndex,
        setSelectedRequestIndex,
        updateInitialRequest,
        updateRequest,
        updateRequestName,
        collectionList,
        currentCollection,
        currentSelectionCollectionKey,
        selectedItem,
        currentSelectionItemId,
    ]);

    const renameOnClick = useCallback(() => {
        TelemetryServiceTrackEvent(TelemetryEvents.CollectionsPanelHeader.SaveButtonOnRenameCollectionDialogClicked);
        /* const currentState = getCurrentState(selectedCollectionIndex, selectedRequestIndex)
        let currentSelectionKey = selectedCollectionIndex
        if (currentState.currentHighestSelectionType === "collection")
            {currentSelectionKey = selectedCollectionIndex}
        else if (currentState.currentHighestSelectionType === "folder") //add folder will not be enabled if its a file so that option is not needed.
            {currentSelectionKey = selectedRequestIndex}
        const selectedItem = recursivelyFindItemById(splitItemIds(currentSelectionKey).itemId, collectionList)
 */
        if (selectedItem !== undefined) {
            selectedItem.name = newCollectionName;
            selectedItem.action = "update"; //api will determine the needed action. parentId is already should be set by now
        }

        updateIndexToRepo1(selectedItem)
            .then((collection: Collection[] | undefined) => {
                if (collection !== undefined) {
                    //Todo: (sivad): unhappy
                }
            })
            .catch((e) => {
                console.error(e);
            });

        setCollectionList(collectionList);
        setCollectionName(newCollectionName);
        setActionType(undefined);
    }, [newCollectionName, setActionType, setCollectionList, setCollectionName, collectionList, selectedItem]);

    const onCollectionNameChange = useCallback((value: string) => {
        TelemetryService.trackTrace("User changes collection name in TextField", SeverityLevel.Information, {
            newValue: value,
        });
        setNewCollectionName(value);
    }, []);

    let heading = stringConstants.components.Common.Collection.RenameCollection;
    let content = (
        <TextField
            label={stringConstants.components.Common.Collection.CollectionName}
            value={newCollectionName}
            required={true}
            onChange={onCollectionNameChange}
        />
    );
    let primaryButtonOnClick = renameOnClick;
    let primaryButtonText = stringConstants.components.Common.Save;

    if (actionType === "delete") {
        heading = `Delete "${selectedItemName}"?`;
        content = (
            <Text
                tag="p"
                appearance="paragraph"
            >{`${stringConstants.components.App.CollectionPanel.DeleteDialogMessage}  ${selectedItemName}?`}</Text>
        );
        primaryButtonOnClick = deleteOnClick;
        primaryButtonText = stringConstants.components.Common.Delete;
    }

    return (
        <Dialog heading={heading} open={true} onHide={closeDialog}>
            <>
                {content}
                <Button slot="footer" appearance="primary" onClick={primaryButtonOnClick}>
                    {primaryButtonText}
                </Button>
                <Button slot="footer" onClick={closeDialog}>
                    {stringConstants.components.Common.Cancel}
                </Button>
            </>
        </Dialog>
    );
}
