import type { ReactNode, Dispatch, SetStateAction } from "react";
import React, { createContext, useContext, useState, useEffect } from "react";
import type { TeamTaskAppData } from "~/models/TeamTask/TeamTaskAppData";
import { getTeamTaskAppDataFromRepo } from "~/services/DbRepository/TeamTask/TeamTaskDBRespository";

type QueryStringParams = {
    id: string | null;
};

type TeamTaskAppContextProps = {
    teamTaskAppDataList: TeamTaskAppData[];
    selectedItem: TeamTaskAppData | null;
    setSelectedItem: Dispatch<SetStateAction<TeamTaskAppData | null>>;
    setTeamTaskAppDataList: Dispatch<SetStateAction<TeamTaskAppData[]>>;
};

type TeamTaskAppProviderProps = {
    children: ReactNode;
};

export const TeamTaskAppContext = createContext<TeamTaskAppContextProps | undefined>(undefined);

export const TeamTaskAppProvider: React.FC<TeamTaskAppProviderProps> = ({ children }) => {
    const [teamTaskAppDataList, setTeamTaskAppDataList] = useState<TeamTaskAppData[]>([]);
    const [selectedItem, setSelectedItem] = useState<TeamTaskAppData | null>(null);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [getTagValue] = useState("");

    const today: Date = new Date();
    const nextWeek: Date = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    const params = new URLSearchParams(window.location.search);
    const par: QueryStringParams = {
        id: params.get("id"),
    };
    const querystringId = par.id;

    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    useEffect(() => {
        //eslint-disable-next-line   @typescript-eslint/no-confusing-void-expression
        const fetchData = async (): Promise<void> => {
            try {
                let body = "";
                const tagBody: TeamTaskAppData = {
                    id: 0,
                    title: "",
                    notes: "",
                    assignedto: "",
                    accessto: "",
                    duedate: nextWeek.toDateString(),
                    publictag: getTagValue.trim(),
                    action: "",
                    createdby: "",
                    iscomplete: true,
                    modifiedby: "",
                    createddate: undefined,
                    modifieddate: undefined,
                };
                getTagValue.trim() === "" ? (body = "") : (body = JSON.stringify(tagBody));
                const teamTaskAppDataListFromRepo = await getTeamTaskAppDataFromRepo(body);
                if (teamTaskAppDataListFromRepo !== undefined) {
                    setTeamTaskAppDataList(teamTaskAppDataListFromRepo);
                }
                /* TelemetryService.trackTrace("App Data Fetched", SeverityLevel.Information, {
            appData: JSON.stringify(appDataList),
        }); */
                console.log("App data fetched:", teamTaskAppDataListFromRepo);
                const queryStringItem = teamTaskAppDataListFromRepo?.find(
                    (item) => item.id.toString() === querystringId
                );
                if (queryStringItem !== undefined) {
                    setSelectedItem(queryStringItem);
                }
            } catch (e) {
                const error = e as Error;
                console.error("Error fetching app data:", error.message);
                // Handle the error as needed
            }
        };

        if (teamTaskAppDataList === undefined || teamTaskAppDataList.length === 0) {
            fetchData().catch((e) => {
                console.error("Error fetching app data:", e);
                // Handle the error as needed
            });
        }
    }),
        [teamTaskAppDataList];

    const contextValue: TeamTaskAppContextProps = {
        teamTaskAppDataList: teamTaskAppDataList,
        selectedItem,
        setSelectedItem,
        setTeamTaskAppDataList: setTeamTaskAppDataList,
    };

    return <TeamTaskAppContext.Provider value={contextValue}>{children}</TeamTaskAppContext.Provider>;
};

/*export const useTeamTaskAppContext = () : TeamTaskAppContextProps | undefined => {
  const teamTaskAppcontext = useContext(TeamTaskAppContext);
  if (teamTaskAppcontext === null) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return teamTaskAppcontext;
};*/

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,  @typescript-eslint/explicit-function-return-type
export const useTeamTaskAppContext = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const teamTaskAppcontext = useContext(TeamTaskAppContext);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!teamTaskAppcontext) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return teamTaskAppcontext;
};
