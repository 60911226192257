import type { TextField as TextFieldClass } from "@harmony/enablers/components/text-field/text-field";
import { textField } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { useCallback } from "react";
import { eventOnlyOnCurrentTarget } from "~/enabler/eventOnlyOnCurrentTarget";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { isDefined } from "~/utils/isDefined";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyTextField = scopeForReact(textField);
export type TextFieldType = textField["type"];

type TextFieldProps = {
    label?: string;
    onChange?: (value: string) => void; // Emitted when an alteration to the control's value is committed by the user.
    onInput?: (value: string) => void; // Emitted when the control receives input and its value changes.
    onBlur?: (value: string) => void; // Emitted when the control loses focus.
    placeHolder?: string;
    value?: string;
    type?: TextFieldType;
    disabled?: boolean;
    validate?: (target: TextFieldClass) => void;
} & BaseEnablerAttributes;

function TextFieldComponent(props: TextFieldProps): ReactElement {
    const { onChange, onInput, validate, onBlur, value, ...rest } = props;

    const onHeChange = useCallback(
        (e: CustomEvent<unknown>) => {
            if (isDefined(onChange)) {
                const target = e.target as TextFieldClass;
                onChange(target.value);
                if (isDefined(validate)) {
                    validate(target);
                }
            }
        },
        [onChange, validate]
    );

    const onHeInput = useCallback(
        (e: CustomEvent<unknown>) => {
            if (isDefined(onInput)) {
                const target = e.target as TextFieldClass;
                onInput(target.value);
                if (isDefined(validate)) {
                    validate(target);
                }
            }
        },
        [onInput, validate]
    );

    const onHeBlur = useCallback(
        (e: CustomEvent<unknown>) => {
            if (isDefined(onBlur)) {
                const target = e.target as TextFieldClass;
                onBlur(target.value);
            }
        },
        [onBlur]
    );

    return (
        <HarmonyTextField
            onHeChange={eventOnlyOnCurrentTarget(onHeChange)}
            onHeInput={eventOnlyOnCurrentTarget(onHeInput)}
            onHeBlur={eventOnlyOnCurrentTarget(onHeBlur)}
            value={value ?? ""}
            {...rest}
        ></HarmonyTextField>
    );
}

export const TextField = typedMemo(TextFieldComponent);
