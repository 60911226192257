import type { IconNames } from "@harmony/enablers/components/icon/icon-names";
import { taskItem, taskMenu } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import styles from "./styles.module.scss";
import { Icon } from "~/enabler/Icon";
import { scopeForReact } from "~/enabler/scope";
import { Text } from "~/enabler/Text";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

export const HarmonyTaskMenu = scopeForReact(taskMenu);
export const HarmonyTaskItem = scopeForReact(taskItem);

export type TaskItemType = {
    value: string;
    key?: string;
    iconName?: IconNames;
    iconStyle?: string;
    selected?: boolean;
    slot?: string;
    onClick?: () => void;
    className?: string;
};

type MenuProps = {
    items: TaskItemType[];
    className?: string;
} & BaseEnablerAttributes;

function TaskMenuComponent(props: MenuProps): ReactElement {
    const { items, className } = props;

    return (
        <HarmonyTaskMenu className={className}>
            {items.map((item) => (
                <HarmonyTaskItem
                    className={item.className}
                    key={item.key ?? item.value}
                    selected={item.selected}
                    onClick={item.onClick}
                    title={item.value}
                >
                    <div className={styles.itemDiv}>
                        {item.iconName !== undefined && (
                            <Icon
                                className={styles.icon}
                                iconStyle={item.iconStyle}
                                name={item.iconName}
                                slot={item.slot ?? "start"}
                            />
                        )}
                        <Text className={styles.text} tag="span" appearance="paragraph">
                            {item.value}
                        </Text>
                    </div>
                </HarmonyTaskItem>
            ))}
        </HarmonyTaskMenu>
    );
}

export const TaskMenu = typedMemo(TaskMenuComponent);
