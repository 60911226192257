import { jsx as _jsx } from "react/jsx-runtime";
import { divider } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyDivider = scopeForReact(divider);
function DividerComponent(props) {
    const { orientation, presentation, dividerStyles, ...rest } = { ...props };
    return _jsx(HarmonyDivider, { className: dividerStyles, presentation: presentation, orientation: orientation, ...rest });
}
export const Divider = typedMemo(DividerComponent);
