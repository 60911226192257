import { jsx as _jsx } from "react/jsx-runtime";
import { icon } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyIcon = scopeForReact(icon);
function IconComponent(props) {
    const { label, iconStyle, slot, name, ...rest } = { ...props };
    return _jsx(HarmonyIcon, { className: iconStyle, slot: slot, name: name, label: label, ...rest });
}
export const Icon = typedMemo(IconComponent);
