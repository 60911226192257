import { SeverityLevel, TelemetryService } from "@empowerment/telemetry";
import type { KeyValue } from "~/components/Common/types";
import { defaultEnvironment } from "~/Constants";
import type { Environment } from "~/models/Environment";
import { globalEnvironmentName, GlobalEnvironment } from "~/models/Environment";
import { dbStorage } from "~/services/DbRepository";
import { isDefined } from "~/utils/isDefined";

const ENVIRONMENT_LIST_KEY = "apix_environmentList";

export const renameEnvironment = (environmentIndex: number, newEnvironmentName: string): void => {
    try {
        const environmentList = fetchEnvironmentList();
        if (!isDefined(environmentList)) {
            throw new Error("Not able to fetch environment list");
        }
        environmentList[environmentIndex].name = newEnvironmentName;
        dbStorage.setItem<Environment[]>(ENVIRONMENT_LIST_KEY, environmentList);
        TelemetryService.trackTrace("Rename environment name successfully", SeverityLevel.Information, {
            environmentIndex: environmentIndex,
            newEnvironmentName: newEnvironmentName,
        });
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to rename environment - EnvironmentIndex: ${environmentIndex}`,
            SeverityLevel.Information,
            {
                environmentIndex: environmentIndex,
                newEnvironmentName: newEnvironmentName,
            }
        );
        TelemetryService.trackException(error as Error, {
            environmentIndex: environmentIndex,
            newEnvironmentName: newEnvironmentName,
        });
    }
};

export const deleteEnvironment = (environmentIndex: number): void => {
    try {
        const environmentList = fetchEnvironmentList();
        if (!isDefined(environmentList)) {
            throw new Error("Not able to fetch environment list");
        }

        environmentList.splice(environmentIndex, 1);
        dbStorage.setItem<Environment[]>(ENVIRONMENT_LIST_KEY, environmentList);
        TelemetryService.trackTrace("Delete environment successfully", SeverityLevel.Information, {
            environmentIndex: environmentIndex,
        });
    } catch (error) {
        TelemetryService.trackTrace(
            `Failed to delete environment - EnvironmentIndex: ${environmentIndex}`,
            SeverityLevel.Information,
            {
                environmentIndex: environmentIndex,
            }
        );
        TelemetryService.trackException(error as Error, {
            environmentIndex: environmentIndex,
        });
    }
};

export const addNewEnvironment = (): void => {
    try {
        const environmentList = fetchEnvironmentList();
        if (!isDefined(environmentList)) {
            throw new Error("Not able to fetch environment list");
        }
        environmentList.push(defaultEnvironment);
        dbStorage.setItem<Environment[]>(ENVIRONMENT_LIST_KEY, environmentList);
        TelemetryService.trackTrace("Add new environment to storage successfully", SeverityLevel.Information);
    } catch (error) {
        TelemetryService.trackTrace("Failed to add new environment", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
    }
};

export const updateEnvironmentList = (environmentList: Environment[]): void => {
    try {
        const environmentListCopy = [...environmentList];
        dbStorage.setItem<Environment[]>(ENVIRONMENT_LIST_KEY, environmentListCopy);
        TelemetryService.trackTrace("Update environment list to storage successfully", SeverityLevel.Information);
    } catch (error) {
        TelemetryService.trackTrace("Failed to update environment list", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
    }
};

export const fetchEnvironmentList = (): Environment[] | null => {
    try {
        const result = dbStorage.getItem<Environment[]>(ENVIRONMENT_LIST_KEY) ?? [GlobalEnvironment];
        TelemetryService.trackTrace("Get environment list from storage successfully", SeverityLevel.Information, {
            environmentList: result,
        });
        return result;
    } catch (error) {
        // Return null if fetch list run into error
        TelemetryService.trackTrace("Failed to get environment list from storage", SeverityLevel.Information);
        TelemetryService.trackException(error as Error);
        return null;
    }
};

/**
 * Compiles Global environment and user selected environment (if any) in order of precedence
 * Use this util function to replace the variable placeholders before sending the request
 * @returns Map whose key is the variable name in placeholder notation (e.g., {{variable}}) & value is the variable value
 */
export const compileEnvironment = (
    environmentList: Environment[],
    selectedEnvironment: string
): Map<string, string> => {
    const environmentMap = new Map<string, string>();
    const globalEnvVariableList = environmentList.find((env) => env.name === globalEnvironmentName)?.variableList ?? [];
    globalEnvVariableList.forEach((keyValuePair: KeyValue) => {
        const key = keyValuePair.key;
        if (key !== "") {
            const value = keyValuePair.value;
            environmentMap.set(`{{${key}}}`, value);
        }
    });
    const selectedEnvVariableList = environmentList.find((env) => env.name === selectedEnvironment)?.variableList;

    // Selected local environment has higher precedence over global environment
    if (selectedEnvVariableList !== undefined) {
        selectedEnvVariableList.forEach((keyValuePair: KeyValue) => {
            const key = keyValuePair.key;
            if (key !== "") {
                const value = keyValuePair.value;
                environmentMap.set(`{{${key}}}`, value);
            }
        });
    }
    return environmentMap;
};

/**
 * Replace the placeholders with the values from placeholdersMap
 * @param content string to replace
 * @param placeholdersMap map with placeholder values
 * @returns string with replaced values
 */
export const replaceStringPlaceholders = (content: string, placeholdersMap: Map<string, string>): string => {
    let replacedString = content;
    placeholdersMap.forEach((value, key) => {
        replacedString = replacedString.replaceAll(key, value);
    });
    return replacedString;
};

/**
 * Replace the placeholders with the values from placeholdersMap
 * @param keyValueList KeyValue list to replace
 * @param placeholdersMap map with placeholder values
 * @returns KeyValue list with replaced values
 */
export const replaceKeyValuePlaceholders = (
    keyValueList: KeyValue[],
    placeholdersMap: Map<string, string>
): KeyValue[] => {
    const replacedKeyValueList = [...keyValueList];
    for (const keyValuePair of keyValueList) {
        const newKey = replaceStringPlaceholders(keyValuePair.key, placeholdersMap);
        const newValue = replaceStringPlaceholders(keyValuePair.value, placeholdersMap);
        keyValuePair.key = newKey;
        keyValuePair.value = newValue;
    }
    return replacedKeyValueList;
};
