import { createScope, partnerCenterTheme } from "@harmony/enablers/react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- Harmony needs a reference to the full React module
import React from "react";
const scope = createScope({
    theme: partnerCenterTheme,
    reactInstance: React,
});
// The cache types don't really matter since everything is being cast below anyway
const scopedComponentsCache = new WeakMap();
// There are complex generics in-use here which are an "implementation detail" that we will likely break over time if we duplicate. So create an untyped wrapper and cast it to whatever their type is.
function scopeForReactUntyped(enablerComponent) {
    const cacheValue = scopedComponentsCache.get(enablerComponent);
    if (cacheValue !== undefined) {
        return cacheValue;
    }
    const newValue = scope.forReact(enablerComponent);
    scopedComponentsCache.set(enablerComponent, newValue);
    return newValue;
}
export const scopeForReact = scopeForReactUntyped;
