import type { TextArea as TextAreaClass } from "@harmony/enablers/components/text-area/text-area";
import { textArea } from "@harmony/enablers/react";
import type { ReactElement } from "react";
import { useCallback } from "react";
import { eventOnlyOnCurrentTarget } from "~/enabler/eventOnlyOnCurrentTarget";
import { scopeForReact } from "~/enabler/scope";
import type { BaseEnablerAttributes } from "~/enabler/types/BaseEnablerAttributes";
import { typedMemo } from "~/utils/typedMemo";

const HarmonyTextArea = scopeForReact(textArea);

type TextAreaProps = {
    placeHolder?: string;
    label?: string;
    rows?: number;
    resize?: TextAreaClass["resize"];
    maxLength?: number;
    readonly?: boolean;
    value?: string;
    onChange?: (value: string) => void;
    validate?: (value: string) => boolean;
    validationMessage?: string;
    helpText?: string;
} & BaseEnablerAttributes;

function TextAreaComponent(props: TextAreaProps): ReactElement {
    const { onChange, validate, validationMessage, ...rest } = { ...props };

    const onInput = useCallback(
        (event: CustomEvent<unknown>) => {
            if (onChange !== undefined) {
                const target = event.target as TextAreaClass;
                let message = "";
                if (validate !== undefined) {
                    if (!validate(target.value)) {
                        message = validationMessage ?? "Invalid input";
                    }
                }
                target.setCustomValidity(message);
                onChange(target.value);
            }
        },
        [onChange, validate, validationMessage]
    );
    return (
        <HarmonyTextArea onHeInput={eventOnlyOnCurrentTarget(onInput)} spellCheck={true} {...rest}></HarmonyTextArea>
    );
}

export const TextArea = typedMemo(TextAreaComponent);
