import { jsx as _jsx } from "react/jsx-runtime";
import { persona } from "@harmony/enablers/react";
import { scopeForReact } from "../../Utils/scope";
import { typedMemo } from "../../Utils/typedMemo";
const HarmonyPersona = scopeForReact(persona);
function PersonaComponent(props) {
    const { label, personaStyle, initials, ...rest } = { ...props };
    return _jsx(HarmonyPersona, { className: personaStyle, initials: initials, label: label, ...rest });
}
export const Persona = typedMemo(PersonaComponent);
