import { appConfig } from "./appConfig";
import type { MSALConfigType } from "~/types/MSALConfig";

export const msalConfig = (): MSALConfigType["msalConfig"] => {
    return {
        auth: {
            clientId: appConfig().authClientId,
            authority: appConfig().authAuthority,
            navigateToLoginRequestUrl: false,
        },
        system: {
            loadFrameTimeout: 30000,
            navigateFrameWait: 500,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false,
        },
    };
};
